<template>
  <div class="about-page">
    <!-- 页面标题 -->
    <div class="page-header bg-light py-4">
      <div class="container">
        <h1 class="text-center mb-0">关于我们</h1>
      </div>
    </div>

    <!-- 学校简介 -->
    <section class="school-intro py-5">
      <div class="container">
        <h2 class="section-title text-center mb-4">学校简介</h2>
        <div class="row align-items-center">
          <div class="col-md-6">
            <img src="../../public/img/school/微信图片_20241220055830.jpg" alt="学校外观" class="img-fluid rounded shadow-sm mb-3">
          </div>
          <div class="col-md-6">
            <p class="lead">郴州中智软件学校是一所专注于IT教育的专业院校，致力于培养高素质的软件开发人才。我们拥有先进的教学设施、专业的师资团队，为学生提供优质的学习环境和实践机会。</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 教学环境 -->
    <section class="facilities bg-light py-5">
      <div class="container">
        <h2 class="section-title text-center mb-4">教学环境</h2>
        <div class="row g-4">
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154750.jpg" class="card-img-top" alt="教室环境">
              <div class="card-body">
                <h5 class="card-title">现代化教室</h5>
                <p class="card-text">配备先进的教学设备，为学生提供舒适的学习环境</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154815.jpg" class="card-img-top" alt="机房">
              <div class="card-body">
                <h5 class="card-title">专业机房</h5>
                <p class="card-text">配置高性能电脑，满足专业软件开发需求</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154820.jpg" class="card-img-top" alt="图书馆">
              <div class="card-body">
                <h5 class="card-title">图书资料室</h5>
                <p class="card-text">丰富的专业书籍资源，助力学生自主学习</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 校园生活 -->
    <section class="campus-life py-5">
      <div class="container">
        <h2 class="section-title text-center mb-4">校园生活</h2>
        <div class="row g-4">
          <div class="col-md-3">
            <img src="../../public/img/school/微信图片_20241227154824.jpg" alt="学习场景" class="img-fluid rounded">
          </div>
          <div class="col-md-3">
            <img src="../../public/img/school/微信图片_20241227154833.jpg" alt="团队协作" class="img-fluid rounded">
          </div>
          <div class="col-md-3">
            <img src="../../public/img/school/微信图片_20241227154838.jpg" alt="课堂互动" class="img-fluid rounded">
          </div>
          <div class="col-md-3">
            <img src="../../public/img/school/微信图片_20241227154841.jpg" alt="学生活动" class="img-fluid rounded">
          </div>
        </div>
      </div>
    </section>

    <!-- 学生活动 -->
    <section class="student-activities bg-light py-5">
      <div class="container">
        <h2 class="section-title text-center mb-4">学生活动</h2>
        <div class="row g-4">
          <div class="col-md-6">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154931.jpg" class="card-img-top" alt="文体活动">
              <div class="card-body">
                <h5 class="card-title">文体活动</h5>
                <p class="card-text">丰富多彩的课外活动，促进学生全面发展</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154939.jpg" class="card-img-top" alt="技能竞赛">
              <div class="card-body">
                <h5 class="card-title">技能竞赛</h5>
                <p class="card-text">参与各类技能大赛，展示专业实力</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 生活设施 -->
    <section class="facilities py-5">
      <div class="container">
        <h2 class="section-title text-center mb-4">生活设施</h2>
        <div class="row g-4">
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154943.jpg" class="card-img-top" alt="宿舍环境">
              <div class="card-body">
                <h5 class="card-title">学生宿舍</h5>
                <p class="card-text">温馨舒适的住宿环境</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241227154952.jpg" class="card-img-top" alt="食堂">
              <div class="card-body">
                <h5 class="card-title">学生食堂</h5>
                <p class="card-text">干净卫生的就餐环境</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <img src="../../public/img/school/微信图片_20241220060251.jpg" class="card-img-top" alt="运动设施">
              <div class="card-body">
                <h5 class="card-title">运动设施</h5>
                <p class="card-text">完善的体育活动场地</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 添加页脚组件 -->
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'About',
  components: {
    SiteFooter
  }
}
</script>

<style scoped>
.about-page {
  background-color: #fff;
}

.page-header {
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../public/img/school/微信图片_20241220055830.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 0;
}

.section-title {
  color: #333;
  font-weight: bold;
  margin-bottom: 2rem;
  position: relative;
}

.section-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: #007bff;
  margin: 10px auto;
}

.card {
  border: none;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.lead {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

img.rounded {
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.facilities .card, .campus-life img {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .card-img-top {
    height: 150px;
  }
  
  .page-header {
    padding: 50px 0;
  }
}
</style> 