<template>
  <div id="app">
    <site-navbar v-if="!isAdminRoute"></site-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
// import axios from '@/utils/axios'
import SiteNavbar from '@/components/SiteNavbar.vue'

export default {
  name: 'App',
  components: {
    SiteNavbar
  },
  setup() {
    // const metaData = ref({ keywords: '', description: '' });
    // const { setMeta } = useMeta()
    //
    // onMounted(async () => {
    //   try {
    //     const response = await axios.get('/api/seo/meta');
    //     if (response.data) {
    //       metaData.value.keywords= response.data.keywords;
    //       metaData.value.description = response.data.description
    //       setMeta({
    //         meta: [
    //           {
    //             name: 'keywords',
    //             content: metaData.value.keywords,
    //           },
    //           {
    //             name: 'description',
    //             content: metaData.value.description,
    //           },
    //         ],
    //         title: '郴州中智软件学校',
    //       });
    //     }
    //   } catch (error) {
    //     console.error('获取 SEO 数据失败:', error);
    //   }
    // });

    const route = useRoute()

    const isAdminRoute = computed(() => {
      return route.path.startsWith('/admin')
    })

    return {
      isAdminRoute
    }





  }




}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
}

.dropdown-menu {
  min-width: 8rem;
}
</style>