<template>
  <div class="admin-layout">
    <!-- 左侧菜单 -->
    <div class="sidebar" :class="{ 'sidebar-collapsed': isCollapsed }">
      <div class="sidebar-header">
        <img src="/logo.png" alt="Logo" class="logo">
      </div>
      <div class="sidebar-content">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link to="/admin/dashboard" class="nav-link" active-class="active">
              <i class="fas fa-tachometer-alt"></i>
              <span v-show="!isCollapsed">仪表盘</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/courses" class="nav-link" active-class="active">
              <i class="fas fa-book"></i>
              <span v-show="!isCollapsed">课程管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/teachers" class="nav-link" active-class="active">
              <i class="fas fa-chalkboard-teacher"></i>
              <span v-show="!isCollapsed">教师管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/students" class="nav-link" active-class="active">
              <i class="fas fa-user-graduate"></i>
              <span v-show="!isCollapsed">学生管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/categories" class="nav-link" active-class="active">
              <i class="fas fa-tags"></i>
              <span v-show="!isCollapsed">分类管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/resources" class="nav-link" active-class="active">
              <i class="fas fa-file-alt"></i>
              <span v-show="!isCollapsed">资源管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/settings" class="nav-link" active-class="active">
              <i class="fas fa-cog"></i>
              <span v-show="!isCollapsed">系统设置</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/news" class="nav-link" active-class="active">
              <i class="fas fa-newspaper"></i>
              <span v-show="!isCollapsed">新闻管理</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/admin/users" class="nav-link" active-class="active">
              <i class="fas fa-users"></i>
              <span v-show="!isCollapsed">用户管理</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-footer">
        <button class="btn btn-link text-white w-100 text-center" @click="toggleSidebar">
          <i class="fas" :class="isCollapsed ? 'fa-chevron-right' : 'fa-chevron-left'"></i>
        </button>
      </div>
    </div>

    <!-- 右侧内容区 -->
    <div class="main-content" :class="{ 'content-expanded': isCollapsed }">
      <!-- 顶部导航 -->
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="d-flex align-items-center">
            <button class="btn btn-link text-dark border-0 p-0 me-3 d-lg-none" @click="toggleSidebar">
              <i class="fas fa-bars"></i>
            </button>
            <h5 class="mb-0">郴州中智软件学校后台管理系统</h5>
          </div>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
                <i class="fas fa-user-circle me-1"></i>
                {{ username }}
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="#"><i class="fas fa-user me-2"></i>个人信息</a></li>
                <li><a class="dropdown-item" href="#"><i class="fas fa-key me-2"></i>修改密码</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#" @click="handleLogout"><i class="fas fa-sign-out-alt me-2"></i>退出登录</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>

      <!-- 页面内容 -->
      <div class="content">
        <router-view  v-if="isRouterAlive"  />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, provide, watch, nextTick } from 'vue'
  import { useRouter, useRoute } from 'vue-router'



  const router = useRouter()
  const route = useRoute()
  const isCollapsed = ref(false)
  const username = ref(localStorage.getItem('username') || '管理员')
  const isRouterAlive = ref(true)

  const toggleSidebar = () => {
    isCollapsed.value = !isCollapsed.value
  }
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userRole')
    localStorage.removeItem('username')
    router.push('/auth/login')
  }
  // 使用 watch 监听路由变化
  watch(()=> route.path, (newPath, oldPath) => {
    console.log('Router changed', { newPath, oldPath });
    if(newPath.startsWith('/admin/')){
      const token = localStorage.getItem('token')
      console.log('Authorization Before ChangeRoute  token:', token ?  token.substring(0, 20) + '...' : '未设置')
      //     if (token &&  token !=="" ) {  //删除
      //  const authHeader =  `Bearer ${token}` //删除
      //    console.log('Set Authorization Before ChangeRoute',authHeader); //删除
      //  axios.defaults.headers.common['Authorization'] = authHeader  //删除
      //   }else{ // 删除
      //  delete axios.defaults.headers.common['Authorization'] //删除
      console.log('Delete Authorization Before ChangeRoute')
      //} //删除
    }
  }, { immediate: true })
  provide('reload', () => {
    isRouterAlive.value = false
    nextTick(() => {
      isRouterAlive.value = true;
    })
  })


</script>

<style scoped>
  .admin-layout {
    display: flex;
    min-height: 100vh;
    background-color: #f5f6fa;
  }

  .sidebar {
    width: 250px;
    background: #1a1c33;
    color: white;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1030;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
  }

  .sidebar-collapsed {
    width: 70px;
  }

  .sidebar-header {
    padding: 1.2rem;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    background: rgba(255,255,255,0.05);
  }

  .logo {
    height: 35px;
    width: auto;
  }

  .sidebar-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 0;
  }

  .sidebar-footer {
    padding: 0.8rem;
    border-top: 1px solid rgba(255,255,255,0.1);
    background: rgba(255,255,255,0.05);
  }

  .nav-link {
    color: rgba(255,255,255,0.7);
    padding: 0.8rem 1.2rem;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s;
    border-left: 3px solid transparent;
  }

  .nav-link:hover {
    color: white;
    background: rgba(255,255,255,0.1);
    text-decoration: none;
    border-left-color: #4c84ff;
  }

  .nav-link.active {
    color: white;
    background: #4c84ff;
    text-decoration: none;
    border-left-color: white;
  }

  .nav-link i {
    width: 20px;
    text-align: center;
    font-size: 1.1rem;
  }

  .main-content {
    flex: 1;
    margin-left: 250px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    min-height: 100vh;
    background-color: #f5f6fa;
    width: calc(100% - 250px);
  }

  .content-expanded {
    margin-left: 70px;
    width: calc(100% - 70px);
  }

  .navbar {
    height: 70px;
    padding: 0 1.5rem;
    background: white;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
  }

  .navbar .nav-link {
    color: #1a1c33;
    padding: 0.5rem 1rem;
    border-left: none;
  }

  .navbar .nav-link:hover {
    color: #4c84ff;
    background: transparent;
  }

  .content {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #f5f6fa;
  }

  :deep(.content > *) {
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
  }

  :deep(.content-card) {
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
    margin-bottom: 1.5rem;
    width: 100%;
  }

  :deep(.container-fluid) {
    padding: 0 1.5rem;
    width: 100%;
  }

  :deep(.table-responsive) {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
  }

  :deep(.content-header) {
    padding: 1.2rem 1.5rem;
    border-bottom: 1px solid #f5f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :deep(.content-header h2) {
    font-size: 1.25rem;
    font-weight: 500;
    color: #1a1c33;
    margin: 0;
  }

  :deep(.content-body) {
    padding: 1.5rem;
  }

  :deep(.content-footer) {
    padding: 1rem 1.5rem;
    border-top: 1px solid #f5f6fa;
    background: #fafbfe;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  /* 表格样式优化 */
  :deep(.table) {
    margin-bottom: 0;
  }

  :deep(.table th) {
    font-weight: 500;
    color: #1a1c33;
    background: #fafbfe;
    border-bottom: 2px solid #f5f6fa;
    padding: 1rem;
  }

  :deep(.table td) {
    padding: 1rem;
    vertical-align: middle;
    color: #495057;
    border-bottom: 1px solid #f5f6fa;
  }

  :deep(.table tr:last-child td) {
    border-bottom: none;
  }

  /* 按钮样式优化 */
  :deep(.btn-primary) {
    background: #4c84ff;
    border-color: #4c84ff;
    padding: 0.5rem 1rem;
    font-weight: 500;
  }

  :deep(.btn-primary:hover) {
    background: #3b76ff;
    border-color: #3b76ff;
  }

  :deep(.btn-outline-primary) {
    color: #4c84ff;
    border-color: #4c84ff;
  }

  :deep(.btn-outline-primary:hover) {
    background: #4c84ff;
    border-color: #4c84ff;
  }

  /* 表单样式优化 */
  :deep(.form-control) {
    border-color: #e9ecef;
    padding: 0.5rem 0.8rem;
  }

  :deep(.form-control:focus) {
    border-color: #4c84ff;
    box-shadow: 0 0 0 0.2rem rgba(76, 132, 255, 0.15);
  }

  :deep(.form-label) {
    font-weight: 500;
    color: #1a1c33;
    margin-bottom: 0.5rem;
  }

  /* 搜索框样式 */
  :deep(.search-box) {
    max-width: 300px;
    position: relative;
  }

  :deep(.search-box .form-control) {
    padding-left: 2.5rem;
  }

  :deep(.search-box i) {
    position: absolute;
    left: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    color: #adb5bd;
  }

  /* 分页样式 */
  :deep(.pagination) {
    margin: 0;
  }

  :deep(.page-link) {
    color: #1a1c33;
    padding: 0.5rem 0.8rem;
    min-width: 36px;
    text-align: center;
  }

  :deep(.page-item.active .page-link) {
    background: #4c84ff;
    border-color: #4c84ff;
  }

  /* 状态标签样式 */
  :deep(.badge) {
    padding: 0.4rem 0.8rem;
    font-weight: 500;
    border-radius: 4px;
  }

  /* 响应式调整 */
  @media (max-width: 1200px) {
    .content {
      padding: 1rem;
    }

    :deep(.content-header),
    :deep(.content-body),
    :deep(.content-footer) {
      padding: 1rem;
    }
  }

  /* 滚动条样式 */
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.2);
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255,255,255,0.3);
  }

  /* 下拉菜单样式 */
  .dropdown-menu {
    min-width: 200px;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
    border: none;
    border-radius: 4px;
  }

  .dropdown-item {
    padding: 0.7rem 1.2rem;
    display: flex;
    align-items: center;
    color: #1a1c33;
    transition: all 0.3s;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
    color: #4c84ff;
  }

  .dropdown-divider {
    margin: 0.5rem 0;
    border-top-color: #f5f6fa;
  }

  /* 响应式处理 */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-100%);
    }

    .sidebar.show {
      transform: translateX(0);
    }

    .main-content {
      margin-left: 0 !important;
      width: 100% !important;
    }

    .content {
      padding: 1rem;
    }

    :deep(.container-fluid) {
      padding: 0 1rem;
    }
  }
</style>