<template>
  <div class="student-center d-flex flex-column min-vh-100">
    <div class="container py-5 flex-grow-1">

      <!-- Personal Info Card -->
      <div class="card mb-4">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="avatar me-4">
              <img :src="getFullAvatarUrl(student.avatar)" class="rounded-circle" width="100" height="100" alt="头像">
            </div>
            <div class="user-info flex-grow-1">
              <h3 class="mb-2">{{ student.name }}</h3>
              <div class="text-muted mb-2"><i class="bi bi-envelope me-2"></i>{{ student.email }}</div>
              <div class="text-muted mb-2"><i class="bi bi-phone me-2"></i>{{ student.phone || '未设置' }}</div>
              <div class="text-muted"><i class="bi bi-calendar-check me-2"></i>注册时间：{{ formatDate(student.createTime) }}</div>
            </div>
            <div class="ms-auto text-end">
              <button class="btn btn-outline-primary mb-2" @click="showEditProfile"><i class="bi bi-pencil me-2"></i>编辑资料</button>
              <div class="text-muted small">
                <div>学习总时长：{{ formatDuration(studentStats.totalStudyTime) }}</div>
                <div>完成课程：{{ studentStats.completedCourses || 0 }}个</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Learning Stats -->
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="card bg-primary text-white">
            <div class="card-body"><h6 class="card-title">今日学习时长</h6><h3 class="mb-0">{{ studentStats.todayStudyTime }}</h3></div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card bg-success text-white">
            <div class="card-body"><h6 class="card-title">总学习时长</h6><h3 class="mb-0">{{ formatDuration(studentStats.totalStudyTime) }}</h3></div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card bg-info text-white">
            <div class="card-body"><h6 class="card-title">已完成课程</h6><h3 class="mb-0">{{ studentStats.completedCourses }}</h3></div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card bg-warning text-white">
            <div class="card-body"><h6 class="card-title">学习天数</h6><h3 class="mb-0">{{ student.studyDays }}</h3></div>
          </div>
        </div>
      </div>

      <!-- Course Progress & Materials -->
      <div class="row">

        <!-- Course Progress -->
        <div class="col-md-8">
          <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">我的课程</h5>
              <div class="btn-group">
                <button class="btn btn-outline-secondary btn-sm" :class="{ active: courseFilter === 'all' }" @click="setCourseFilter('all')">全部</button>
                <button class="btn btn-outline-secondary btn-sm" :class="{ active: courseFilter === 'in_progress' }" @click="setCourseFilter('in_progress')">进行中</button>
                <button class="btn btn-outline-secondary btn-sm" :class="{ active: courseFilter === 'completed' }" @click="setCourseFilter('completed')">已完成</button>
              </div>
            </div>
            <div class="card-body">
              <div v-if="filteredCourses.length === 0" class="text-center py-4 text-muted">暂无课程，快去选择心仪的课程吧！</div>
              <div v-else>
                <div v-for="course in filteredCourses" :key="course.courseId" class="course-item mb-4" @click="goToCoursePlayer(course.courseId)">
                  <div class="d-flex align-items-start mb-2">
                    <img :src="course.coverImageUrl" class="course-image me-3" alt="课程封面">
                    <div class="flex-grow-1">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="mb-0">{{ course.title }}</h6>
                        <span :class="['badge', getStatusBadgeClass(course.status,course.isCompleted)]">{{ getStatusText(course.status,course.isCompleted) }}</span>
                      </div>
                      <div class="text-muted small mb-2">
                        <span class="me-3"><i class="bi bi-clock me-1"></i>最近学习：{{ formatDate(course.lastStudyTime) }}</span>
                        <span><i class="bi bi-play-circle me-1"></i>总课时：{{ course.totalCreditHours }}</span>
                        <span><i class="bi bi-play-circle me-1"></i>已学习：{{ course.completedLessons }}/{{ course.totalLessons }}课时 进度: {{ course.courseProgress.toFixed(0) }}%</span>
                      </div>

                      <div class="progress" style="height: 6px;">
                        <div class="progress-bar" role="progressbar" :style="{ width: course.courseProgress + '%' }" :aria-valuenow="course.courseProgress" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>

                      <div class="lesson-list">
                        <div v-for="lesson in course.lessons" :key="lesson.id" class="lesson-item mb-2">
                          <div class="d-flex justify-content-between align-items-center">
                            <span class="lesson-title">{{ lesson.title }}</span>
                            <span class="lesson-progress">{{ lesson.progress ? lesson.progress.toFixed(0) : 0 }}%</span>
                          </div>
                          <div class="progress" style="height: 4px;">
                            <div class="progress-bar" role="progressbar" :style="{ width: (lesson.progress ? lesson.progress : 0) + '%' }" :aria-valuenow="lesson.progress" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Learning Materials & Reminders -->
        <div class="col-md-4">

          <!-- Learning Materials -->
          <div class="card mb-4">
            <div class="card-header"><h5 class="card-title mb-0">学习资料</h5></div>
            <div class="card-body">
              <div v-if="learningMaterials.length === 0" class="text-center py-4 text-muted">暂无学习资料</div>
              <div v-else>
                <div v-for="material in learningMaterials" :key="material.id" class="material-item mb-2">
                  <a :href="material.url" class="text-decoration-none d-flex align-items-center">
                    <i :class="getMaterialIcon(material.type)" class="me-2"></i>
                    <div class="flex-grow-1">
                      <div>{{ material.name }}</div>
                      <small class="text-muted">{{ formatFileSize(material.size) }}</small>
                    </div>
                    <i class="bi bi-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Learning Reminders -->
          <div class="card">
            <div class="card-header"><h5 class="card-title mb-0">学习提醒</h5></div>
            <div class="card-body">
              <div class="reminder-item mb-3">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <strong>每日学习目标</strong>
                  <span :class="['badge', learningGoals.daily.progress >= 100 ? 'bg-success' : 'bg-warning']">{{ learningGoals.daily.progress >= 100 ? '已达成' : '进行中' }}</span>
                </div>
                <div class="progress" style="height: 6px;">
                  <div class="progress-bar" :class="learningGoals.daily.progress >= 100 ? 'bg-success' : 'bg-warning'" :style="{ width: Math.min(learningGoals.daily.progress, 100) + '%' }"></div>
                </div>
                <div class="text-muted small mt-2">
                  <span>目标：{{ formatDuration(learningGoals.daily.target) }}</span>
                  <span class="float-end">已完成：{{ formatDuration(learningGoals.daily.completed) }}</span>
                </div>
              </div>

              <div class="reminder-item">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <strong>本周学习目标</strong>
                  <span :class="['badge', learningGoals.weekly.progress >= 100 ? 'bg-success' : 'bg-warning']">{{ learningGoals.weekly.progress >= 100 ? '已达成' : '进行中' }}</span>
                </div>
                <div class="progress" style="height: 6px;">
                  <div class="progress-bar" :class="learningGoals.weekly.progress >= 100 ? 'bg-success' : 'bg-warning'" :style="{ width: Math.min(learningGoals.weekly.progress, 100) + '%' }"></div>
                </div>
                <div class="text-muted small mt-2">
                  <span>目标：{{ formatDuration(learningGoals.weekly.target) }}</span>
                  <span class="float-end">已完成：{{ formatDuration(learningGoals.weekly.completed) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Profile Modal -->
    <div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editProfileModalLabel">编辑个人资料</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateProfile">
              <div class="mb-3">
                <label for="profileAvatar" class="form-label">头像</label>
                <div class="d-flex align-items-center">
                  <img :src="getFullAvatarUrl(editForm.avatar)" class="rounded-circle me-3" width="64" height="64" alt="Profile Avatar">
                  <div>
                    <input type="file" class="d-none" ref="fileInput" accept="image/*" @change="handleFileChange" id="profileAvatar">
                    <button type="button" class="btn btn-outline-primary btn-sm" @click="$refs.fileInput.click()">更换头像</button>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="profileName" class="form-label">姓名</label>
                <input type="text" class="form-control" v-model="editForm.name" id="profileName">
              </div>
              <div class="mb-3">
                <label for="profileUsername" class="form-label">用户名</label>
                <input type="text" class="form-control" v-model="editForm.username" id="profileUsername">
              </div>
              <div class="mb-3">
                <label for="profilePhone" class="form-label">手机号</label>
                <input type="tel" class="form-control" v-model="editForm.phone" id="profilePhone">
              </div>
              <div class="mb-3">
                <label for="profileEmail" class="form-label">邮箱</label>
                <input type="email" class="form-control" v-model="editForm.email" id="profileEmail">
              </div>
              <div class="text-end">
                <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">取消</button>
                <button type="submit" class="btn btn-primary">保存</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SiteFooter class="mt-auto" />
  </div>
</template>

<script>
  import { ref, onMounted, computed ,reactive } from 'vue'
  import axios from '@/utils/axios'
  import { ElMessage } from 'element-plus'
  import { Modal } from 'bootstrap'
  import SiteFooter from '@/components/SiteFooter.vue'
  import { updateUser } from '@/api/user'
  import request from '@/utils/axios'
  import { sumBy } from 'lodash'
  import { useRouter } from 'vue-router';

  export default {
    name: 'StudentCenter',
    components: {
      SiteFooter
    },
    setup() {
      const router = useRouter();
      const student = ref({})
      const enrolledCourses = ref([])
      const learningMaterials = ref([])
      const loading = ref(false)
      const error = ref(null)
      const learningGoals = ref({
        daily: {
          target: 0,
          completed: 0,
          progress: 0
        },
        weekly: {
          target: 0,
          completed: 0,
          progress: 0
        }
      })
      const courseFilter = ref('all') // 'all' | 'in_progress' | 'completed'
      const editProfileModal = ref(null);  // To handle the modal instance

      // 筛选后的课程列表
      const filteredCourses = computed(() => {
        console.log('Current courseFilter:', courseFilter.value);
        if (courseFilter.value === 'all') {
          return enrolledCourses.value;
        }
        let filtered;
        if (courseFilter.value === 'completed') {
          filtered = enrolledCourses.value.filter(course => course.isCompleted === true);
        } else {
          filtered = enrolledCourses.value.filter(course => course.isCompleted === false);
        }

        console.log('Filtered Courses:', filtered);
        return filtered;
      });
      // 切换课程筛选
      const setCourseFilter = (filter) => {
        courseFilter.value = filter
      }


      const goToCoursePlayer = (courseId) => {
        router.push(`/courses/${courseId}`); // 假设你的路由是 /course-player/:courseId
      };
      // 获取学员信息
      const fetchStudentData = async () => {
        loading.value = true
        error.value = null;
        try {
          const userInfoStr = localStorage.getItem('userInfo');
          if (!userInfoStr) return

          const userInfo = JSON.parse(userInfoStr);
          const userId = userInfo.id;
          if (!userId) {
            ElMessage.error('用户 ID 未找到');
            return;
          }
          const token = localStorage.getItem('token');
          ///api/student/learning/{userId}/profile
          const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/api/student/learning/${userId}/profile`,{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          student.value = data;
          console.log('获取用户信息成功：', data);
        } catch (error) {
          console.error('获取用户信息失败:', error);

        } finally {
          loading.value = false;
        }
      }
      // 获取课程信息
      const fetchEnrolledCourses = async () => {
        try {
          const userInfoStr = localStorage.getItem('userInfo')
          if (!userInfoStr) return;

          const userInfo = JSON.parse(userInfoStr)
          const userId = userInfo.id;
          if (!userId) return;

          const [coursesRes, freeCoursesRes] = await Promise.all([
            axios.get(`/api/student/learning/${userId}/courses/statistics`),
            axios.get(`/api/student/learning/${userId}/free-courses/records`)
          ])

          enrolledCourses.value = [
            ...(coursesRes.data?.content || []).map(course => {
              const totalCreditHours = sumBy(course.lessons, 'creditHours')
              return {
                ...course,
                totalCreditHours: totalCreditHours || 0,
              }
            }),
            ...(freeCoursesRes.data?.content || []).map(course => {
              const totalCreditHours = sumBy(course.lessons, 'creditHours')
              return {
                ...course,
                totalCreditHours: totalCreditHours || 0,
              }
            }),
          ]
        } catch (error) {
          console.error('获取课程信息失败:', error);
          ElMessage.error('获取课程信息失败');
        }
      }
      // 获取学习资料
      const fetchLearningMaterials = async () => {
        try {
          const userInfoStr = localStorage.getItem('userInfo')
          if (!userInfoStr) return

          const userInfo = JSON.parse(userInfoStr)
          const userId = userInfo.id
          if (!userId) return

          const {data} = await axios.get(`/api/student/learning/${userId}/materials`);
          learningMaterials.value = data;
        } catch (error) {
          console.error('获取学习资料失败:', error);
          ElMessage.error('获取学习资料失败');
        }
      }

      // 格式化日期
      const formatDate = (date) => {
        if (!date) return '暂无数据'
        return new Date(date).toLocaleDateString('zh-CN', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      }

      // 格式化时长
      const formatDuration = (totalSeconds) => {
        if (isNaN(totalSeconds) || totalSeconds === null || totalSeconds === undefined) {
          return "0小时0分钟";
        }

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        return `${hours}小时${minutes}分钟`;
      };

      // 格式化文件大小
      const formatFileSize = (bytes) => {
        if (!bytes) return '0 B'
        const units = ['B', 'KB', 'MB', 'GB']
        let size = bytes
        let unitIndex = 0
        while (size >= 1024 && unitIndex < units.length - 1) {
          size /= 1024
          unitIndex++
        }
        return `${size.toFixed(1)} ${units[unitIndex]}`
      }

      // 获取课程状态样式
      const getStatusBadgeClass = (status,isCompleted) => {
        if(isCompleted)
        {
          return 'bg-success';
        }
        const statusMap = {
          'in_progress': 'bg-primary',
          'completed': 'bg-success',
          'not_started': 'bg-secondary',
          'expired': 'bg-danger'
        }
        return statusMap[status] || 'bg-secondary'
      }

      // 获取课程状态文本
      // 获取课程状态文本
      const getStatusText = (status, isCompleted) => {
        if (isCompleted) {
          return '已完成';
        } else {
          return '进行中'; // 或者 '未开始'，根据你的需求选择
        }
      };

      const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
          const formData = new FormData();
          formData.append('file', file);

          const response = await request({
            url: '/api/upload/image',
            method: 'post',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.code === 200) {
            editForm.value.avatar = response.data.data.url;
            ElMessage.success('头像上传成功');
          } else {
            ElMessage.error(response.data.message || '头像上传失败');
          }
        } catch (error) {
          console.error('上传头像失败:', error);
          ElMessage.error(error.response?.data?.message || '头像上传失败');
        }
      };

      const updateProfile = async () => {
        try {
          const userInfoStr = localStorage.getItem('userInfo');
          if (!userInfoStr) return;

          const userInfo = JSON.parse(userInfoStr);
          const userId = userInfo.id;
          if (!userId) return;

          const updateData = {
            name: editForm.value.name,
            username: editForm.value.username,
            phone: editForm.value.phone,
            email: editForm.value.email,
            avatar: editForm.value.avatar
          };

          const response = await updateUser(userId, updateData);
          // Check if the request is successful
          if (response.status === 200) {
            console.log("更新是否成功？", response.data)

            // Refresh the student data
            await fetchStudentData();
            ElMessage.success('个人资料更新成功');
            // 关闭模态框
            const modalElement = document.getElementById('editProfileModal');
            const modal = Modal.getInstance(modalElement);
            modal.hide();
          } else {
            ElMessage.error(`更新个人资料失败：${response.statusText}`);
          }


        } catch (error) {
          console.error('更新个人资料失败:', error);
          ElMessage.error(error.response?.data?.message || '更新个人资料失败');
        }
      };


      // 获取资料类型图标
      const getMaterialIcon = (type) => {
        const iconMap = {
          'pdf': 'bi bi-file-pdf',
          'doc': 'bi bi-file-word',
          'video': 'bi bi-file-play',
          'image': 'bi bi-file-image'
        }
        return iconMap[type] || 'bi bi-file'
      }
      // 编辑表单数据
      const editForm = ref({
        name: '',
        username: '',
        phone: '',
        email: '',
        avatar: ''
      })

      const showEditProfile = () => {
        editForm.value = {
          name: student.value.name,
          username: student.value.username,
          phone: student.value.phone || '',
          email: student.value.email || '',
          avatar: student.value.avatar || ''
        };
        const modalElement = document.getElementById('editProfileModal');
        if (modalElement) {
          editProfileModal.value = new Modal(modalElement);
          editProfileModal.value.show();
        }
      };
      // 获取学习目标
      const fetchLearningGoals = async () => {
        try {
          const userInfoStr = localStorage.getItem('userInfo')
          if (!userInfoStr) return

          const userInfo = JSON.parse(userInfoStr)
          const userId = userInfo.id
          if (!userId) return
          const response1 = await  axios.get(`/api/student/learning/${userId}/daily-goal`) // 使用正确的接口
          const response2 =  await axios.get(`/api/student/learning/${userId}/weekly-goal`)  // 使用正确的接口

          learningGoals.value = {
            daily: {
              ...response1.data,
              progress: Math.floor((response1.data.completed / response1.data.target) * 100)
            },
            weekly: {
              ...response2.data,
              progress: Math.floor((response2.data.completed / response2.data.target) * 100)
            }
          }
        } catch (error) {
          console.error('获取学习目标失败:', error)
          ElMessage.error('获取学习目标失败')
        }
      }

      const studentStats = reactive({  // 使用 reactive 创建响应式对象
        todayStudyTime: 0,
        totalStudyTime: 0,
        studyDays: 0,
        completedCourses: 0
      })
      //获取学员统计数据

      const fetchStudentStats = async () => {
        try {
          const userInfoStr = localStorage.getItem('userInfo');
          if (!userInfoStr) return;
          const userInfo = JSON.parse(userInfoStr);
          const userId = userInfo.id;
          if (!userId) {
            ElMessage.error('用户 ID 未找到');
            return;
          }
          const token = localStorage.getItem('token');

          const progressResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/student/learning/${userId}/progress`, { // 获取学习进度的接口
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          // 使用 totalStudyTime
          studentStats.totalStudyTime = progressResponse.data.totalWatchTime;

          console.log("总学时常 ："+studentStats.totalStudyTime);
          // 使用  completedCourseCount
          studentStats.completedCourses = progressResponse.data.completedCourseCount;

          // 获取今日学习时长
          const dailyGoalResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/student/learning/${userId}/daily-goal`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          console.log("----------ailyGoalResponse.data:----------"+dailyGoalResponse.data);

          let totalSeconds = 0; // 默认值为 0

          if(dailyGoalResponse.data != null && dailyGoalResponse.data != undefined){
            // 确保 dailyGoalResponse.data 是一个数字
            const parsedData = parseInt(dailyGoalResponse.data, 10);
            if(!isNaN(parsedData)){
              totalSeconds = parsedData
            }

          }


          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          //格式化
          const formattedTime = `${hours}小时${minutes}分钟`;

          console.log(formattedTime);

          // 将格式化后的时长字符串存储到 studentStats.todayStudyTime 中
          studentStats.todayStudyTime = formattedTime;

          console.log( "输出:"+studentStats.todayStudyTime);

          //TODO 获取学习天数的接口  需要后端提供接口
          // 例如：
          const studyDaysResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/student/learning/${userId}/study-days`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          // 使用 学习天数字段
          studentStats.studyDays = studyDaysResponse.data;

        } catch (error) {
          console.error("获取学习统计信息失败", error);
          ElMessage.error("获取学习统计信息失败")
        }
      };

      // 获取完整的头像URL
      const getFullAvatarUrl = (url) => {
        if (!url) return '/default-avatar.png'
        if (url.startsWith('http')) return url
        return `http://localhost:8888/api${url}`
      }

      onMounted(async () => {
        await fetchStudentData()
        await fetchEnrolledCourses()
        await fetchLearningMaterials()
        await fetchLearningGoals()
        await  fetchStudentStats()
      })

      return {
        student,
        enrolledCourses,
        learningMaterials,
        learningGoals,
        formatDate,
        formatDuration,
        formatFileSize,
        getStatusBadgeClass,
        getStatusText,
        getMaterialIcon,
        showEditProfile,
        updateProfile,
        courseFilter,
        filteredCourses,
        setCourseFilter,
        studentStats, //  将 studentStats 添加到模板中
        loading,
        error,
        getFullAvatarUrl,  // 添加到返回值中
        editForm,
        goToCoursePlayer,
        handleFileChange,
        editProfileModal
      }
    }
  }
</script>

<style scoped>
  .course-image {
    width: 120px;
    height: 68px;
    object-fit: cover;
    border-radius: 4px;
  }

  .course-item {
    padding: 1rem;
    border-radius: 8px;
    background-color: #f8f9fa;
    transition: all 0.3s ease;
    position: relative;
    height: 250px; /*  设置一个合适的高度 */
    overflow: hidden; /* 隐藏溢出内容 */
    padding-bottom: 100px; /* 为课件列表留出足够的空间 */
    cursor: pointer;
  }

  .course-item:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
  }

  .material-item {
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .material-item:hover {
    background-color: #f8f9fa;
  }

  .reminder-item {
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
  }

  .avatar img {
    border: 3px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .user-info h3 {
    font-weight: 600;
    color: #2c3e50;
  }

  .card {
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .progress {
    background-color: rgba(0, 0, 0, 0.1);
    height: 6px; /* 修改高度 */
  }

  .badge {
    padding: 0.5em 0.8em;
  }

  .student-center {
    background-color: #f5f5f5;
  }

  .lesson-list {
    margin-top: 10px;
    padding-left: 15px;
    border-left: 1px solid #ddd;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
  }

  .lesson-item {
    margin-bottom: 5px;
  }

  .lesson-title {
    font-size: 14px;
  }

  .lesson-progress {
    font-size: 12px;
    color: #777;
  }

  .lesson-item .progress {  /* 限定范围 */
    height: 4px;          /* 标准的进度条高度 */
  }
</style>

