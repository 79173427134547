import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import CourseList from '@/pages/CourseList.vue'
import CourseDetail from '@/pages/CourseDetail.vue'
import CourseSearch from '@/pages/CourseSearch.vue'
import News from '@/pages/News.vue'
import NewsDetail from '@/pages/NewsDetail.vue'
import About from '@/pages/About.vue'
import Auth from '@/pages/Auth.vue'
import StudentCenter from '@/pages/StudentCenter.vue'
import NotFound from '@/pages/NotFound.vue'
import AdminLayout from '@/pages/admin/AdminLayout.vue'
import Dashboard from '@/pages/admin/Dashboard.vue'
import TeacherManage from '@/pages/admin/TeacherManage.vue'
import StudentManage from '@/pages/admin/StudentManage.vue'
import Settings from '@/pages/admin/Settings.vue'
import CoursePlay from '@/pages/CoursePlay.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/courses',
    name: 'CourseList',
    component: CourseList,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/courses/:id',
    name: 'CourseDetail',
    component: CourseDetail,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/course-search',
    name: 'CourseSearch',
    component: CourseSearch,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { 
      requiresAuth: false,
      isPublic: true
    }
  },
  {
    path: '/auth',
    component: Auth,
    meta: { requiresAuth: false },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/components/auth/Login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/components/auth/Register.vue')
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/components/auth/ForgotPassword.vue')
      }
    ]
  },
  {
    path: '/student',
    name: 'StudentCenter',
    component: StudentCenter,
    meta: { 
      requiresAuth: true,
      requiresStudent: true,
      title: '学生中心'
    }
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: { 
      requiresAuth: true,
      requiresAdmin: true,
      title: '后台管理'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { 
          title: '仪表盘',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'courses',
        name: 'CourseManage',
        component: () => import('@/pages/admin/CourseManage.vue'),
        meta: { 
          title: '课程管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'courses/edit/:id?',
        name: 'CourseEditBasicInfo',
        component: () => import('@/pages/admin/CourseEditBasicInfo.vue'),
        meta: { 
          title: '编辑课程基本信息',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'courses/outline/:id',
        name: 'CourseOutlineManage',
        component: () => import('@/pages/admin/CourseOutlineManage.vue'),
        meta: { 
          title: '课程大纲管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'teachers',
        name: 'TeacherManage',
        component: TeacherManage,
        meta: { 
          title: '教师管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'students',
        name: 'StudentManage',
        component: StudentManage,
        meta: { 
          title: '学生管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'categories',
        name: 'Categories',
        component: () => import('@/pages/admin/CategoryManage.vue'),
        meta: { title: '分类管理' }
      },
      {
        path: 'resources',
        name: 'Resources',
        component: () => import('@/pages/admin/ResourceManage.vue'),
        meta: { title: '资源管理' }
      },
      {
        path: 'news',
        name: 'NewsManage',
        component: () => import('@/pages/admin/NewsManage.vue'),
        meta: { 
          title: '新闻管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        meta: { 
          title: '系统设置',
          requiresAuth: true,
          requiresAdmin: true
        }
      },
      {
        path: 'users',
        component: () => import('@/pages/admin/UserManage.vue'),
        meta: { 
          title: '用户管理',
          requiresAuth: true,
          requiresAdmin: true
        }
      }
    ]
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/pages/Profile.vue'),
    meta: { 
      requiresAuth: true,
      title: '个人中心'
    }
  },
  {
    path: '/course/:courseId/play/:videoId',
    name: 'CoursePlay',
    component: CoursePlay,
    meta: { 
      requiresAuth: true,
      requiresStudent: true,
      title: '课程播放'
    }
  },
  {
    path: '/job-service',
    name: 'JobService',
    component: () => import('@/pages/JobService.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: false }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // 获取token
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('userRole')
  
  // 如果访问的是首页，直接放行
  if (to.path === '/') {
    next()
    return
  }
  
  // 如果访问的是公开页面，直接放行
  if (to.meta.public) {
    next()
    return
  }
  
  // 如果访问的是登录页面且已登录，则重定向到默认页面
  if (to.path === '/auth/login' && token) {
    const defaultRoute = userRole === 'ADMIN' ? '/admin/dashboard' : '/student/dashboard'
    next(defaultRoute)
    return
  }
  
  // 如果页面需要登录但用户未登录，重定向到登录页面
  if (to.meta.requiresAuth && !token) {
    next({
      path: '/auth/login',
      query: { redirect: to.fullPath }
    })
    return
  }
  
  // 如果页面需要管理员权限
  if (to.meta.requiresAdmin) {
    if (userRole !== 'ADMIN') {
      next('/auth/login')
      return
    }
  }
  
  // 如果页面需要教师权限
  if (to.meta.requiresTeacher) {
    if (userRole !== 'TEACHER' && userRole !== 'ADMIN') {
      next('/auth/login')
      return
    }
  }
  
  // 如果页面需要机构管理员权限
  if (to.meta.requiresOrgAdmin) {
    if (userRole !== 'ORG_ADMIN' && userRole !== 'ADMIN') {
      next('/auth/login')
      return
    }
  }
  
  // 如果页面需要学生权限
  if (to.meta.requiresStudent) {
    if (userRole !== 'STUDENT' && userRole !== 'ADMIN') {
      next('/auth/login')
      return
    }
  }
  
  // 默认放行
  next()
})

export default router 