import request from '@/utils/axios'

// 获取用户列表
export function getUserList(params) {
  return request({
    url: '/api/admin/users',  // 修改这里
    method: 'get',
    params
  })
}

// 创建用户
export function createUser(data) {
  return request({
    url: '/api/admin/users/add',
    method: 'post',
    data
  })
}
// 更新用户
export function updateUser(id, data) {
  return request({
    url: `/api/admin/users/update/${id}`,
    method: 'put',
    data
  })
}
// 删除用户
export function deleteUser(id) {
  return request({
    url: `/api/admin/users/delete/${id}`,
    method: 'delete'
  })
}