<template>
  <div class="course-search bg-light py-4 mb-4 rounded shadow-sm">
    <div class="container">
      <!-- 搜索标题 -->
      <h4 class="mb-3 text-center text-primary">
        <i class="fas fa-search me-2"></i>寻找适合你的课程
      </h4>
      
      <!-- 搜索框 -->
      <div class="row justify-content-center mb-4">
        <div class="col-md-8">
          <div class="input-group input-group-lg">
            <input 
              type="text" 
              class="form-control border-primary" 
              v-model="searchQuery" 
              placeholder="搜索课程名称、描述或讲师"
              @keyup.enter="handleSearch"
            >
            <button class="btn btn-primary px-4" @click="handleSearch">
              <i class="fas fa-search me-1"></i> 搜索
            </button>
          </div>
        </div>
      </div>
      
      <!-- 分类快捷筛选 -->
      <div class="row justify-content-center mb-3">
        <div class="col-md-10 text-center">
          <span class="me-1 text-muted">课程分类:</span>
          <span 
            class="badge bg-light text-primary border border-primary mx-1 mb-2 px-3 py-2"
            style="cursor: pointer;"
            @click="searchByCategory('')"
          >
            全部
          </span>
          <span 
            v-for="category in categories" 
            :key="category.id"
            class="badge bg-light text-primary border border-primary mx-1 mb-2 px-3 py-2"
            :class="{'bg-primary text-white': selectedCategory == category.id}"
            style="cursor: pointer;"
            @click="searchByCategory(category.id)"
          >
            {{ category.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch } from 'vue'
  import request from '@/utils/axios'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'CourseSearch',
    emits: ['courses-updated'],  // 声明组件会触发的事件
    setup(props, { emit }) {
      const searchQuery = ref('')
      const selectedCategory = ref('')
      const categories = ref([])
      const isLoading = ref(false)
      
      // 获取课程分类
      const fetchCategories = async () => {
        try {
          const response = await request({
            url: 'api/categories/public',
            method: 'get'
          })
          
          if (response.data) {
            categories.value = Array.isArray(response.data) ? response.data :
              (response.data?.content || [])
          }
        } catch (error) {
          console.error('获取课程分类失败:', error)
        }
      }
      
      // 获取课程数据（服务器端分页和筛选）
      const fetchCourses = async () => {
        isLoading.value = true
        try {
          const params = {
            page: 0,
            size: 9, // 与CourseList组件中的pageSize保持一致
            deletedStatus: false
          }
          
          // 添加搜索参数
          if (searchQuery.value) {
            params.keyword = searchQuery.value
          }
          
          // 添加分类筛选参数
          if (selectedCategory.value) {
            params.categoryId = selectedCategory.value
          }
          
          const response = await request({
            url: 'api/public/courses/list',
            params: params
          })
          
          if (response.data) {
            const processedCourses = response.data.content.map(course => ({
              ...course,
              requiresLogin: course.requiresLogin || false,
              coverImageUrl: course.coverImageUrl || '默认图片URL'
            }))
            
            // 发送处理后的课程数据和分页信息到父组件
            emit('courses-updated', processedCourses, {
              totalElements: response.data.totalElements,
              totalPages: response.data.totalPages,
              currentPage: response.data.number
            })
          }
        } catch (error) {
          console.error('获取课程列表失败:', error)
          ElMessage.error('获取课程列表失败，请稍后重试')
        } finally {
          isLoading.value = false
        }
      }
      
      // 按分类搜索
      const searchByCategory = (categoryId) => {
        selectedCategory.value = categoryId
        fetchCourses()
      }

      const handleSearch = () => {
        fetchCourses()
      }
      
      // 重置筛选条件
      const resetFilters = () => {
        searchQuery.value = ''
        selectedCategory.value = ''
        fetchCourses()
      }
      
      // 监听搜索查询的变化
      watch(searchQuery, (newVal, oldVal) => {
        if (newVal === '' && oldVal !== '') {
          // 当用户清空搜索框时重新搜索
          fetchCourses()
        }
      })
      
      onMounted(() => {
        // 组件挂载时加载课程数据和分类
        Promise.all([fetchCourses(), fetchCategories()])
      })

      return {
        searchQuery,
        selectedCategory,
        categories,
        isLoading,
        handleSearch,
        searchByCategory,
        resetFilters
      }
    }
  }
</script>

<style scoped>
  .course-search {
    border-radius: 8px;
  }
  
  .badge {
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .badge:hover {
    background-color: #0d6efd !important;
    color: white !important;
  }
  
  .form-control:focus,
  .form-select:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
  }
  
  .input-group-lg .form-control {
    border-right: none;
  }
  
  .input-group-lg .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
</style>