<template>
  <div class="home" :class="{ 'is-loading': loading }">
    <div v-if="loading" class="loading-overlay">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">加载中...</span>
      </div>
    </div>

    <!-- 错误提示 -->
    <div v-if="error" class="alert alert-danger mx-3 mt-3" role="alert">
      {{ error }}
      <button type="button" class="btn-close float-end" @click="error = null"></button>
    </div>

    <!-- 主要内容 -->
    <main class="flex-grow-1">
      <!-- 轮播图 -->
      <div class="container mt-6">
        <div id="homeCarousel" class="carousel slide" data-bs-ride="carousel" style="margin-top: 30px;">
          <div class="carousel-indicators">
            <button v-for="(item, index) in carouselItems"
                    :key="index"
                    type="button"
                    data-bs-target="#homeCarousel"
                    :data-bs-slide-to="index"
                    :class="{ active: index === 0 }">
            </button>
          </div>
          <div class="carousel-inner rounded">
            <div v-for="(item, index) in carouselItems"
                 :key="index"
                 class="carousel-item"
                 :class="{ active: index === 0 }">
              <img :src="getImageUrl(item.imageUrl)" class="d-block w-100" :alt="item.title">
              <div class="carousel-caption">
                <h2>{{ item.title }}</h2>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#homeCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#homeCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>
      </div>

      <!-- 快速入口 -->
      <section class="quick-access py-4">
        <div class="container">
          <div class="row g-3">
            <div class="col-md-3">
              <router-link to="/courses" class="text-decoration-none">
                <div class="quick-item bg-primary text-white rounded p-4 text-center">
                  <i class="fas fa-user-graduate fa-2x mb-3"></i>
                  <h5>课程中心</h5>
                  <p class="mb-0">精品课程等你来学</p>
                </div>
              </router-link>
            </div>
            <div class="col-md-3">
              <router-link to="/courses" class="text-decoration-none">
                <div class="quick-item bg-success text-white rounded p-4 text-center">
                  <i class="fas fa-laptop-code fa-2x mb-3"></i>
                  <h5>在线学习</h5>
                  <p class="mb-0">随时随地学习</p>
                </div>
              </router-link>
            </div>
            <div class="col-md-3">
              <router-link to="/job-service" class="text-decoration-none">
                <div class="quick-item bg-info text-white rounded p-4 text-center">
                  <i class="fas fa-briefcase fa-2x mb-3"></i>
                  <h5>就业服务</h5>
                  <p class="mb-0">高薪就业保障</p>
                </div>
              </router-link>
            </div>
            <div class="col-md-3">
              <router-link to="/about" class="text-decoration-none">
                <div class="quick-item bg-warning text-white rounded p-4 text-center">
                  <i class="fas fa-info-circle fa-2x mb-3"></i>
                  <h5>关于我们</h5>
                  <p class="mb-0">了解更多详情</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <!-- 热门课程 -->
      <section class="py-5">
        <div class="container">
          <div class="section-header text-center mb-5">
            <h2 class="section-title">热门课程</h2>
            <p class="text-muted">精心打造的IT培训课程，助你快速成长为技术精英</p>
          </div>
          <div class="row g-4">
            <router-link v-for="course in popularCourses" :key="course.id" :to="'/courses/' + course.id" class="col-md-4 text-decoration-none">
              <div class="card h-100">
                <img :src="(course.coverImageUrl)" class="card-img-top" :alt="course.name">
                <div class="card-body">
                  <h5 class="card-title">{{ course.name }}</h5>
                  <p class="card-text text-muted">{{ course.description }}</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="text-primary fw-bold">¥{{ course.price }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="text-center mt-4">
            <router-link to="/courses" class="btn btn-primary">查看全部课程</router-link>
          </div>
        </div>
      </section>

      <!-- 就业明星 -->
      <section class="successful-students py-5">
        <div class="container">
          <h2 class="text-center mb-4">就业明星</h2>
          <div class="row g-4">
            <div v-for="student in successfulStudents" :key="student.id" class="col-md-6">
              <img :src="getImageUrl(student.imageUrl)" class="img-fluid rounded shadow" alt="就业明星展示">
            </div>
          </div>
        </div>
      </section>

      <!-- 教学特色 -->
      <section class="py-5">
        <div class="container">
          <div class="section-header text-center mb-5">
            <h2 class="section-title">办学特色</h2>
            <p class="text-muted">六大优势，助你快速成长</p>
          </div>
          <div class="row g-4">
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-coins"></i>
                </div>
                <h4>超高性价比</h4>
                <p class="text-muted">每小时学费低于20元，毕业6个月即可收回全部投资</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-seedling"></i>
                </div>
                <h4>零基础入学</h4>
                <p class="text-muted">门槛低，零基础入学，从菜鸟到精英的完美蜕变</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-briefcase"></i>
                </div>
                <h4>保证就业</h4>
                <p class="text-muted">毕业即就业，月薪不低于5000元，否则全额退款</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-clock"></i>
                </div>
                <h4>时间成本低</h4>
                <p class="text-muted">每天8小时，8-18个月即可上岗，远低于市场培训周期</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-chalkboard-teacher"></i>
                </div>
                <h4>师资经验足</h4>
                <p class="text-muted">培训老师+企业总监双轨制，理论实操完美结合</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-center">
                <div class="feature-icon bg-primary bg-gradient text-white mb-3">
                  <i class="fas fa-hand-holding-heart"></i>
                </div>
                <h4>先上岗后交费</h4>
                <p class="text-muted">针对特困生，零投入先学习，就业后再缴费</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 校企合作 -->
      <div class="cooperation-section py-5">
        <div class="container">
          <div class="section-title text-center mb-5">
            <h2 class="mb-3">校企合作</h2>
            <p class="text-muted">与多家知名企业建立深度合作关系</p>
          </div>
          <div class="row g-4 justify-content-center align-items-center">
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(11).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(12).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(13).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(14).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(15).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(17).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(19).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(20).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(21).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(22).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(57).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(58).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(60).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
            <div class="col-6 col-md-3 col-lg-2">
              <img :src="'/img/company/qy(62).jpg'" class="img-fluid company-logo" alt="合作企业">
            </div>
          </div>
        </div>
      </div>

      <!-- 最新动态 -->
      <section class="news-section py-5">
        <div class="container">
          <div class="section-header text-center mb-5">
            <h2 class="section-title">最新动态</h2>
            <p class="text-muted">了解学校最新资讯</p>
          </div>
          <div class="row g-4">
            <div v-for="news in latestNews" :key="news.id" class="col-md-4">
              <div class="card h-100 news-card">
                <img :src="news.imageUrl" class="card-img-top news-img" :alt="news.title" @error="handleImageError">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title text-truncate">{{ news.title }}</h5>
                  <p class="card-text text-muted flex-grow-1">{{ news.summary }}</p>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <small class="text-muted">{{ news.publishDate }}</small>
                    <router-link :to="'/news/' + news.id" class="btn btn-sm btn-outline-primary">
                      阅读更多
                      <i class="fas fa-arrow-right ms-1"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <router-link to="/news" class="btn btn-primary">
              查看更多新闻
              <i class="fas fa-chevron-right ms-1"></i>
            </router-link>
          </div>
        </div>
      </section>

      <!-- 联系我们 -->
      <section class="py-5 bg-light">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h2 class="mb-4">联系我们</h2>
              <ul class="list-unstyled contact-info">
                <li class="mb-3">
                  <i class="fas fa-map-marker-alt text-primary me-2"></i>
                  地址：郴州市苏仙区白露塘镇石虎路东河路 中智软件学校
                </li>
                <li class="mb-3">
                  <i class="fas fa-phone text-primary me-2"></i>
                  电话：0735-8883388
                </li>
                <li class="mb-3">
                  <i class="fas fa-mobile-alt text-primary me-2"></i>
                  手机：18073544223（马老师）
                </li>
                <li class="mb-3">
                  <i class="fab fa-qq text-primary me-2"></i>
                  QQ：9135554
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <div class="map-container rounded overflow-hidden">
                <!-- 这里可以嵌入百度地图 -->
                <img src="/img/map.png" class="img-fluid" alt="中智软件学校地图">
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- 页脚 -->

    <site-footer></site-footer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import request from '@/utils/axios'
import { formatDate } from '@/utils/date'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'HomePage',
  components: {
    SiteFooter
  },
  setup() {
    const loading = ref(false)
    const error = ref(null)
    const carouselItems = ref([
      {
        imageUrl: '/img/banner1.jpg',
        title: '专业的IT教育',
        description: '打造高质量IT人才培养基地'
      },
      {
        imageUrl: '/img/banner2.jpg',
        title: '实战教学',
        description: '理论与实践相结合的教学模式'
      },
      {
        imageUrl: '/img/banner3.jpg',
        title: '就业保障',
        description: '与知名企业深度合作，保障学员就业'
      },
      {
        imageUrl: '/img/course-ai.jpg',
        title: 'AI人工智能',
        description: '引领人工智能时代的技术革新'
      },
      {
        imageUrl: '/img/course-bigdata.jpg',
        title: '大数据开发',
        description: '掌握大数据分析与处理技术'
      },
      {
        imageUrl: '/img/course-java.jpg',
        title: 'Java开发',
        description: '全栈式Java工程师培养计划'
      }
    ])

    const popularCourses = ref([])
    const successfulStudents = ref([
      {
        id: 1,
        imageUrl: '/img/student/stu1.png'
      },
      {
        id: 2,
        imageUrl: '/img/student/stu2.png'
      }
    ])
    const latestNews = ref([])

    const fetchPopularCourses = async () => {
      try {
        const response = await request({
          url: '/api/public/courses/list',
          method: 'get',
          params: {
            page: 0,
            size: 3
          }
        })
        popularCourses.value = response.data.content

      } catch (error) {
        console.error('获取热门课程失败:', error)
      }
    }

    const fetchLatestNews = async () => {
      try {
        const response = await request({
          url: '/api/public/news/get/latest',
          method: 'get',
          params: {
            page: 0,
            size: 3
          }
        })
        latestNews.value = response.data.content.map(news => {
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = news.content
          const plainText = tempDiv.textContent || tempDiv.innerText || ''
          const summary = plainText.length > 100 ? plainText.substring(0, 100) + '...' : plainText

          return {
            ...news,
            imageUrl: news.image 
              ? `${process.env.VUE_APP_API_URL}/api${news.image}`
              : '/img/news-default.jpg',
            summary: summary
          }
        })
      } catch (error) {
        console.error('获取最新新闻失败:', error)
      }
    }

    const handleImageError = (e) => {
      if (e.target.src.includes('news-default.jpg')) {
        return
      }
      console.error('图片加载失败:', e.target.src)
      e.target.src = '/img/news-default.jpg'
    }

    const getImageUrl = (url) => {
    //  if (!url) return '';
    //  if (url.startsWith('http')) return url;
      return ""+url;
    };

    onMounted(() => {
      fetchPopularCourses()
      fetchLatestNews()
    })

    return {
      loading,
      error,
      carouselItems,
      popularCourses,
      successfulStudents,
      latestNews,
      formatDate,
      handleImageError,
      getImageUrl
    }
  }
}
</script>

<style scoped>
.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* 轮播图样式 */
.carousel {
  margin-bottom: 2rem;
  margin-top: 2rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.carousel-inner {
  border-radius: 8px;
  overflow: hidden;
  padding-top: 20px;
}

.carousel-item {
  height: 450px; /* 调整轮播图高度 */
  background-color: #000;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  border-radius: 8px;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
  width: auto;
}

.carousel-caption h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-caption p {
  font-size: 1.1rem;
  margin-bottom: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* 响应式调整 */
@media (max-width: 768px) {
  .carousel-item {
    height: 300px;
  }
  
  .carousel-caption h2 {
    font-size: 1.5rem;
  }
  
  .carousel-caption p {
    font-size: 1rem;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feature-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.partner-logo {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  transition: transform 0.3s;
}

.partner-logo:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.partner-logo img {
  max-height: 50px;
  max-width: 100%;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.3s;
}

.partner-logo:hover img {
  filter: grayscale(0%);
  opacity: 1;
}

.news-card {
  border: none;
  box-shadow: 0 2px 15px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0,0,0,0.12);
}

.news-img {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.8rem;
}

.btn-outline-primary {
  border-width: 2px;
  font-weight: 500;
  padding: 0.4rem 1rem;
}

.btn-outline-primary:hover {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .news-img {
    height: 160px;
  }
  
  .card-title {
    font-size: 1.1rem;
  }
  
  .card-text {
    font-size: 0.9rem;
  }
}

.cooperation-section {
  background-color: #f8f9fa;
}

.company-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 15px;
  transition: all 0.3s ease;
  filter: grayscale(100%);
}

.company-logo:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.section-title h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
}

.section-title p {
  font-size: 1.1rem;
}

/* 添加新的工具类 */
.mt-6 {
  margin-top: 5rem !important;
}
</style>