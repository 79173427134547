<template>
  <div class="page-header bg-white border-bottom py-4">
    <div class="container">
      <h1 class="text-center text-primary mb-0">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .page-header {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
h1 {
  font-size: 2rem;
  font-weight: 600;
}
</style> 