<template>
  <div class="not-found">
    <div class="container text-center py-5">
      <h1 class="display-1">404</h1>
      <p class="lead">抱歉，您访问的页面不存在</p>
      <router-link to="/" class="btn btn-primary">
        返回首页
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script> 