<template>
  <div class="student-manage">
    <div class="card shadow-sm">
      <div class="card-header bg-white py-3 d-flex justify-content-between align-items-center">
        <h5 class="mb-0">学生管理</h5>
        <div>
          <button class="btn btn-success me-2" @click="handleExport">
            <i class="fas fa-file-export me-1"></i> 导出数据
          </button>
          <button class="btn btn-primary" @click="handleAdd">
            <i class="fas fa-plus me-1"></i> 新增学生
          </button>
        </div>
      </div>
      <div class="card-body">
        <!-- 搜索栏 -->
        <div class="row mb-4">
          <div class="col-md-3">
            <div class="input-group">
              <input
                      type="text"
                      class="form-control"
                      v-model="searchQuery"
                      placeholder="搜索学生姓名/学号..."
                      @keyup.enter="handleSearch"
              >
              <button class="btn btn-outline-secondary" type="button" @click="handleSearch">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div class="col-md-2">
            <select class="form-select" v-model="statusFilter" @change="handleSearch">
              <option value="">全部状态</option>
              <option value="ACTIVE">正常</option>
              <option value="INACTIVE">禁用</option>
            </select>
          </div>
        </div>

        <!-- 学生列表 -->
        <div class="table-responsive">
          <table class="table table-hover align-middle">
            <thead class="table-light">
            <tr>
              <th>学号</th>
              <th>姓名</th>
              <th>登录名</th>
              <th>状态</th>
              <th>入学时间</th>
              <th>学习进度</th>
              <th>总时长</th>
              <th>学习天数</th>
              <th>最近登录</th>
              <th style="width: 200px">操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="student in students" :key="student.id">
              <td>{{ student.studentId }}</td>
              <td>
                <div class="d-flex align-items-center">
                  <img
                          :src="getFullAvatarUrl(student.avatar) || '/default-avatar.png'"
                          class="rounded-circle me-2"
                          width="32"
                          height="32"
                          alt="Avatar"
                  >
                  {{ student.name }}
                </div>
              </td>
              <td>{{ student.username }}</td>
              <td>
                  <span class="badge" :class="getStatusClass(student.status)">
                    {{ getStatusText(student.status) }}
                  </span>
              </td>
              <td>{{ formatDate(student.enrollmentDate) }}</td>
              <td>
                <div class="progress" style="height: 6px; width: 120px">
                  <div
                          class="progress-bar bg-success"
                          :style="{ width: student.progress + '%' }"
                  >

                  </div>

                </div>
                <small class="text-muted">{{ student.progress }}%</small>
              </td>

              <td>
                {{ student.totalStudyTime!=null?getTotalStudyTime(student.totalStudyTime):'0小时0分钟' }}
              </td>

              <td>
                {{ student.studyDays }}
              </td>
              <td>{{ formatDate(student.lastLoginTime) }}</td>
              <td>
                <button class="btn btn-sm btn-outline-primary me-1"
                        title="查看详情"
                        @click="handleViewDetails(student)">
                  <i class="fas fa-eye"></i>
                </button>
                <button class="btn btn-sm btn-outline-info me-1"
                        title="学习统计"
                        @click="handleViewStats(student)">
                  <i class="fas fa-chart-line"></i>
                </button>
                <button class="btn btn-sm btn-outline-warning me-1"
                        title="编辑"
                        @click="handleEdit(student)">
                  <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-sm btn-outline-danger"
                        title="删除"
                        @click="handleDelete(student)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- 分页 -->
        <div class="d-flex justify-content-between align-items-center mt-4">
          <div class="text-muted">
            共 {{ total }} 条记录
          </div>
          <nav aria-label="Page navigation">
            <ul class="pagination mb-0">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage - 1)">上一页</a>
              </li>
              <li v-for="page in pageNumbers"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage + 1)">下一页</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <!-- 删除确认对话框 -->
    <div class="modal fade" id="deleteConfirmModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">确认删除</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <p>确定要删除该学生吗？此操作不可恢复。</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">确定删除</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 学生表单对话框 -->
    <div class="modal fade" id="studentFormModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEdit ? '编辑学生' : '新增学生' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSubmit">
              <!-- 头像上传 -->
              <div class="mb-3">
                <label class="form-label">头像</label>
                <div class="avatar-upload">
                  <input
                    type="file"
                    ref="fileInput"
                    class="d-none"
                    accept="image/*"
                    @change="handleFileChange"
                  >
                  <div class="avatar-preview" v-if="form.avatar">
                    <img :src="getFullAvatarUrl(form.avatar)" class="avatar-image">
                    <button type="button" class="btn btn-sm btn-danger avatar-delete" @click="handleDeleteAvatar">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <button type="button" class="btn btn-outline-primary" @click="handleSelectFile" v-else>
                    <i class="fas fa-upload me-1"></i> 上传头像
                  </button>
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">学号 <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="form.name" required>
              </div>
              <div class="mb-3">
                <label class="form-label">用户名 <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="form.username" required>
              </div>
              <div class="mb-3" v-if="!isEdit">
                <label class="form-label">密码 <span class="text-danger">*</span></label>
                <input type="password" class="form-control" v-model="form.password" required>
              </div>
              <div class="mb-3">
                <label class="form-label">状态 <span class="text-danger">*</span></label>
                <select class="form-select" v-model="form.status" required>
                  <option value="ACTIVE">正常</option>
                  <option value="INACTIVE">禁用</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">入学时间 <span class="text-danger">*</span></label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="form.enrollmentDate"
                  required
                  step="1"
                >
              </div>
              <div class="mb-3">
                <label class="form-label">联系电话</label>
                <input type="tel" class="form-control" v-model="form.phone">
              </div>
              <div class="mb-3">
                <label class="form-label">电子邮箱</label>
                <input type="email" class="form-control" v-model="form.email">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" @click="handleSubmit">确定</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 学习统计对话框 -->
    <div class="modal fade" id="statsModal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title">
              <i class="fas fa-chart-line me-2 text-primary"></i>学习统计
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body p-4">
            <div v-if="selectedStudent" class="student-stats">
              <!-- 学生基本信息卡片 -->
              <div class="student-profile mb-4 p-3 border-start border-4 border-primary bg-light rounded d-flex align-items-center">
                <img 
                  :src="getFullAvatarUrl(selectedStudent.avatar) || '/default-avatar.png'" 
                  class="rounded-circle me-3" 
                  width="64" 
                  height="64" 
                  alt="学生头像"
                >
                <div>
                  <h5 class="mb-1">{{ selectedStudent.name }}</h5>
                  <div class="text-muted small d-flex flex-wrap">
                    <span class="me-3"><i class="fas fa-id-card me-1"></i>学号：{{ selectedStudent.studentId }}</span>
                    <span class="me-3"><i class="fas fa-user me-1"></i>登录名：{{ selectedStudent.username }}</span>
                    <span class="badge me-2" :class="getStatusClass(selectedStudent.status)">
                      {{ getStatusText(selectedStudent.status) }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- 学习概览卡片组 -->
              <div class="row g-3 mb-4">
                <!-- 学习时长卡片 -->
                <div class="col-md-3 col-sm-6">
                  <div class="card shadow-sm h-100 border-0">
                    <div class="card-body text-center">
                      <div class="stat-icon bg-light-success rounded-circle mb-2">
                        <i class="fas fa-clock text-success"></i>
                      </div>
                      <h3 class="mb-1">{{ formatDuration(learningStats.totalDuration) }}</h3>
                      <div class="text-muted small">总学习时长</div>
                    </div>
                  </div>
                </div>

                <!-- 课程完成卡片 -->
                <div class="col-md-3 col-sm-6">
                  <div class="card shadow-sm h-100 border-0">
                    <div class="card-body text-center">
                      <div class="stat-icon bg-light-primary rounded-circle mb-2">
                        <i class="fas fa-book-reader text-primary"></i>
                      </div>
                      <h3 class="mb-1">{{ learningStats.completedCourses }}/{{ learningStats.totalCourses }}</h3>
                      <div class="text-muted small">课程完成率</div>
                    </div>
                  </div>
                </div>

                <!-- 学习天数卡片 -->
                <div class="col-md-3 col-sm-6">
                  <div class="card shadow-sm h-100 border-0">
                    <div class="card-body text-center">
                      <div class="stat-icon bg-light-warning rounded-circle mb-2">
                        <i class="fas fa-calendar-alt text-warning"></i>
                      </div>
                      <h3 class="mb-1">{{ selectedStudent.studyDays || 0 }}</h3>
                      <div class="text-muted small">累计学习天数</div>
                    </div>
                  </div>
                </div>

                <!-- 笔记数量卡片 -->
                <div class="col-md-3 col-sm-6">
                  <div class="card shadow-sm h-100 border-0">
                    <div class="card-body text-center">
                      <div class="stat-icon bg-light-info rounded-circle mb-2">
                        <i class="fas fa-sticky-note text-info"></i>
                      </div>
                      <h3 class="mb-1">{{ learningStats.totalNotes }}</h3>
                      <div class="text-muted small">笔记数量</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 详细统计部分 -->
              <div class="row g-4">
                <!-- 总体学习情况 -->
                <div class="col-md-6">
                  <div class="card shadow-sm border-0 h-100">
                    <div class="card-header bg-white py-3">
                      <h6 class="mb-0"><i class="fas fa-chart-bar text-primary me-2"></i>总体学习情况</h6>
                    </div>
                    <div class="card-body">
                      <div class="stat-item d-flex justify-content-between align-items-center py-2 border-bottom">
                        <span class="stat-label"><i class="fas fa-graduation-cap me-2 text-muted"></i>总课程数</span>
                        <span class="stat-value fw-medium">{{ learningStats.totalCourses }}</span>
                      </div>
                      <div class="stat-item d-flex justify-content-between align-items-center py-2 border-bottom">
                        <span class="stat-label"><i class="fas fa-check-circle me-2 text-muted"></i>已完成课程</span>
                        <span class="stat-value fw-medium">{{ learningStats.completedCourses }}</span>
                      </div>
                      <div class="stat-item d-flex justify-content-between align-items-center py-2 border-bottom">
                        <span class="stat-label"><i class="fas fa-clock me-2 text-muted"></i>学习总时长</span>
                        <span class="stat-value fw-medium">{{ formatDuration(learningStats.totalDuration) }}</span>
                      </div>
                      <div class="stat-item d-flex justify-content-between align-items-center py-2">
                        <span class="stat-label"><i class="fas fa-sticky-note me-2 text-muted"></i>笔记数量</span>
                        <span class="stat-value fw-medium">{{ learningStats.totalNotes }}</span>
                      </div>
                      <!-- 平均进度条 -->
                      <div class="mt-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <span class="small text-muted">平均学习进度</span>
                          <span class="small">{{ learningStats.averageProgress || 0 }}%</span>
                        </div>
                        <div class="progress" style="height: 8px;">
                          <div 
                            class="progress-bar bg-success" 
                            :style="{ width: (learningStats.averageProgress || 0) + '%' }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 今日学习情况 -->
                <div class="col-md-6">
                  <div class="card shadow-sm border-0 h-100">
                    <div class="card-header bg-white py-3">
                      <h6 class="mb-0"><i class="fas fa-history text-primary me-2"></i>今日学习情况</h6>
                    </div>
                    <div class="card-body">
                      <div class="stat-item d-flex justify-content-between align-items-center py-2 border-bottom">
                        <span class="stat-label"><i class="fas fa-clock me-2 text-muted"></i>学习时长</span>
                        <span class="stat-value fw-medium">{{ formatDuration(learningStats.todayDuration) }}</span>
                      </div>
                      <div class="stat-item d-flex justify-content-between align-items-center py-2 border-bottom">
                        <span class="stat-label"><i class="fas fa-video me-2 text-muted"></i>观看视频数</span>
                        <span class="stat-value fw-medium">{{ learningStats.todayVideos }}</span>
                      </div>
                      <div class="stat-item d-flex justify-content-between align-items-center py-2">
                        <span class="stat-label"><i class="fas fa-pen me-2 text-muted"></i>记录笔记数</span>
                        <span class="stat-value fw-medium">{{ learningStats.todayNotes }}</span>
                      </div>
                      
                      <!-- 今日进度可视化 -->
                      <div class="daily-activity mt-4">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <span class="small text-muted">今日活跃度</span>
                        </div>
                        <div class="activity-timeline d-flex justify-content-between">
                          <div v-for="(_, index) in 8" :key="index" 
                            class="activity-hour" 
                            :class="{'active': index < (learningStats.todayVideos / 2)}">
                            <div class="activity-dot"></div>
                            <span class="activity-label small text-muted">{{ (index + 8) }}:00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 最近学习记录 -->
              <div class="card shadow-sm border-0 mt-4">
                <div class="card-header bg-white py-3">
                  <h6 class="mb-0"><i class="fas fa-list text-primary me-2"></i>最近学习记录</h6>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead class="table-light">
                        <tr>
                          <th>课程名称</th>
                          <th>视频标题</th>
                          <th>观看时长</th>
                          <th>观看时间</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in todayRecords" :key="record.id">
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="course-icon me-2 rounded-circle bg-light-primary">
                                <i class="fas fa-book text-primary"></i>
                              </div>
                              {{ record.courseName }}
                            </div>
                          </td>
                          <td>{{ record.videoTitle }}</td>
                          <td>{{ formatDuration(record.duration) }}</td>
                          <td>{{ formatDateTime(record.watchTime) }}</td>
                        </tr>
                        <tr v-if="!todayRecords || todayRecords.length === 0">
                          <td colspan="4" class="text-center py-3 text-muted">暂无学习记录</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { ElMessage } from 'element-plus'
  import { Modal } from 'bootstrap'
  import request from '@/utils/axios'
  import { formatDate, formatDateTime, formatDuration } from '@/utils/date'
  import {
    getStudentList,
    createStudent,
    deleteStudent,
    resetStudentPassword,
    getStudentStatistics,
    getStudentLearningRecords
  } from '@/api/student'
  import { updateUser } from '@/api/user'

  export default {
    name: 'StudentManage',
    setup() {
      // 状态定义
      const students = ref([])
      const total = ref(0)
      const currentPage = ref(1)
      const pageSize = ref(10)
      const searchQuery = ref('')
      const statusFilter = ref('')
      const isEdit = ref(false)
      const selectedStudent = ref(null)
      const studentModal = ref(null)
      const deleteModal = ref(null)
      const statsModal = ref(null)
      const learningStats = ref({})
      const todayRecords = ref([])
      const showStatsDialog = ref(false)
      const form = ref({
        studentId: '',
        name: '',       // 学号
        username: '',   // 登录名
        password: '',
        status: 'ACTIVE',
        phone: '',
        email: '',
        enrollmentDate: '',
        role: 'STUDENT',
        avatar: ''      // 头像URL
      })

      // 文件上传相关
      const fileInput = ref(null)

      // 触发文件选择
      const handleSelectFile = () => {
        fileInput.value.click()
      }

      // 获取完整的头像URL
      const getFullAvatarUrl = (url) => {
        if (!url) return ''
        if (url.startsWith('http')) return url
        return `http://localhost:8888/api${url}`
      }

      // 处理文件上传
      const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (!file) return

        // 验证文件类型
        if (!file.type.startsWith('image/')) {
          ElMessage.warning('请选择图片文件')
          return
        }

        // 验证文件大小（限制为2MB）
        if (file.size > 2 * 1024 * 1024) {
          ElMessage.warning('图片大小不能超过2MB')
          return
        }

        try {
          const formData = new FormData()
          formData.append('file', file)

          const response = await request({
            url: '/api/upload/image',
            method: 'post',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })

          if (response.data.code === 200) {
            form.value.avatar = response.data.data.url
            ElMessage.success('头像上传成功')
          } else {
            ElMessage.error(response.data.message || '头像上传失败')
          }
        } catch (error) {
          console.error('上传头像失败:', error)
          ElMessage.error(error.response?.data?.message || '头像上传失败')
        }
      }

      // 删除头像
      const handleDeleteAvatar = () => {
        form.value.avatar = ''
        ElMessage.success('头像已删除')
      }

      // 计算总页数
      const totalPages = ref(0)

      // 初始化模态框
      onMounted(() => {
        try {
          const studentModalEl = document.getElementById('studentFormModal')
          const deleteModalEl = document.getElementById('deleteConfirmModal')
          const statsModalEl = document.getElementById('statsModal')

          if (studentModalEl) {
            studentModal.value = new Modal(studentModalEl)
          } else {
            console.error('Student modal element not found')
          }
          if (deleteModalEl) {
            deleteModal.value = new Modal(deleteModalEl)
          } else {
            console.error('Delete modal element not found')
          }
          if (statsModalEl) {
            statsModal.value = new Modal(statsModalEl)
          } else {
            console.error('Stats modal element not found')
          }
          loadStudents()
        } catch (error) {
          console.error('Modal initialization failed:', error)
        }
      })

      // 加载学生列表
      const loadStudents = async () => {
        try {
          const params = {
            pageNumber: currentPage.value - 1,
            pageSize: pageSize.value,
            keyword: searchQuery.value || '',
            status: statusFilter.value || null
          }

          const response = await getStudentList(params);
          students.value = response.data.content;
          total.value = response.data.totalElements;
          totalPages.value = response.data.totalPages;
        } catch (error) {
          console.error('加载学生列表失败:', error);
          ElMessage.error(error.response?.data?.message || '加载学生列表失败');
        }
      }

      // 搜索处理
      const handleSearch = () => {
        currentPage.value = 1
        loadStudents()
      }

      // 添加学生
      const handleAdd = () => {
        isEdit.value = false;
        const now = new Date();
        // 格式化为 yyyy-MM-ddTHH:mm:ss
        const defaultDate = now.toISOString().slice(0, 19);

        form.value = {
          studentId: '',
          name: '',
          password: '',
          status: 'ACTIVE',
          phone: '',
          email: '',
          enrollmentDate: defaultDate,
          role: 'STUDENT'
        };
        if (studentModal.value) {
          studentModal.value.show();
        }
      };

      // 编辑学生
      const handleEdit = (student) => {
        isEdit.value = true;
        selectedStudent.value = student;

        // 确保日期时间格式正确
        let enrollmentDate = student.enrollmentDate;
        if (!enrollmentDate) {
          enrollmentDate = new Date().toISOString().slice(0, 19);
        } else if (!enrollmentDate.includes('T')) {
          // 如果日期不包含时间部分，添加时间
          enrollmentDate = enrollmentDate + 'T00:00:00';
        }

        form.value = {
          ...student,
          name: student.studentId,      // 学号
          username: student.name,       // 登录名
          enrollmentDate: enrollmentDate,
          avatar: student.avatar || ''  // 头像URL
        };
        studentModal.value.show();
      };

      // 删除学生
      const handleDelete = async (student) => {
        try {
          selectedStudent.value = student
          if (deleteModal.value) {
            deleteModal.value.show()
          } else {
            ElMessage.error('系统错误：对话框未初始化')
          }
        } catch (error) {
          console.error('显示删除对话框失败:', error)
          ElMessage.error('系统错误')
        }
      }

      // 确认删除
      const confirmDelete = async () => {
        try {
          await deleteStudent(selectedStudent.value.id)
          if (deleteModal.value) {
            deleteModal.value.hide()
          }
          ElMessage.success('删除成功')
          await loadStudents()
        } catch (error) {
          console.error('删除学生失败:', error)
          ElMessage.error(error.response?.data?.message || '删除学生失败')
        }
      }

      // 重置密码
      const handleResetPassword = async (student) => {
        try {
          await resetStudentPassword(student.id)
          ElMessage.success('密码重置成功')
          await loadStudents()
        } catch (error) {
          console.error('重置密码失败:', error)
          ElMessage.error(error.response?.data?.message || '重置密码失败')
        }
      }

      // 提交表单
      const handleSubmit = async () => {
        try {
          // 表单验证
          if (!form.value.name || !form.value.username) {
            ElMessage.warning('请填写必填字段')
            return
          }

          if (!isEdit.value && !form.value.password) {
            ElMessage.warning('请设置密码')
            return
          }

          // 准备提交的数据
          const submitData = {
            ...form.value,
            role: 'STUDENT',
            studentId: form.value.name,        // 学号作为studentId
            enrollmentDate: form.value.enrollmentDate,  // 直接使用enrollmentDate，不需要再次格式化
            avatar: form.value.avatar || null  // 确保头像字段存在
          }

          if (isEdit.value) {
            await updateUser(selectedStudent.value.id, submitData)
            ElMessage.success('更新成功')
          } else {
            await createStudent(submitData)
            ElMessage.success('添加成功')
          }

          if (studentModal.value) {
            studentModal.value.hide()
          }
          loadStudents()
        } catch (error) {
          console.error('保存学生失败:', error)
          ElMessage.error(error.response?.data?.message || '保存学生失败')
        }
      }

      // 页码变化
      const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages.value) {
          currentPage.value = page
          loadStudents()
        }
      }

      // 查看学习统计
      const handleViewStats = async (student) => {
        try {
          console.log("student",student);
          selectedStudent.value = student
          
          // 从单个API获取所有数据
          const response = await getStudentStatistics(student.id);
          if (response.data) {
            // 设置学生基本信息
            selectedStudent.value = {
              ...student,
              ...response.data.student
            };
            
            // 设置学习统计信息
            learningStats.value = {
              // 总体学习情况
              totalCourses: response.data.overview.totalCourses || 0,
              completedCourses: response.data.overview.completedCourses || 0,
              totalDuration: response.data.overview.totalDuration || 0, // 秒
              totalNotes: response.data.overview.totalNotes || 0,
              averageProgress: response.data.overview.averageProgress || 0,
              
              // 今日学习情况
              todayDuration: response.data.today.todayDuration || 0, // 秒
              todayVideos: response.data.today.todayVideos || 0,
              todayNotes: response.data.today.todayNotes || 0
            };
            
            // 获取最近学习记录
            const recordsResponse = await getStudentLearningRecords(student.id);
            todayRecords.value = recordsResponse.data || [];
          }
          
          // 显示模态框
          if (statsModal.value) {
            statsModal.value.show()
          } else {
            console.error('Stats modal not initialized')
            ElMessage.error('无法显示统计信息')
          }
        } catch (error) {
          console.error('获取学习统计失败:', error)
          ElMessage.error('获取学习统计失败')
        }
      }

      const getStatusClass = (status) => {
        switch (status) {
          case 'ACTIVE':
            return 'bg-success';
          case 'INACTIVE':
            return 'bg-danger';
          default:
            return 'bg-secondary';
        }
      }

      const getStatusText = (status) => {
        switch (status) {
          case 'ACTIVE':
            return '正常';
          case 'INACTIVE':
            return '禁用';
          default:
            return '未知';
        }
      }

      const getTotalStudyTime = (totalSeconds) => {
        if (isNaN(totalSeconds) || totalSeconds === null || totalSeconds === undefined) {
          return "0小时0分钟";
        }
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        return `${hours}小时${minutes}分钟`;
      };

      return {
        students,
        total,
        currentPage,
        totalPages,
        searchQuery,
        statusFilter,
        isEdit,
        form,
        selectedStudent,
        learningStats,
        todayRecords,
        showStatsDialog,
        handleSearch,
        handleAdd,
        handleEdit,
        handleDelete,
        handleSubmit,
        confirmDelete,
        handleResetPassword,
        handlePageChange,
        handleViewStats,
        formatDate,
        formatDateTime,
        formatDuration,
        getStatusClass,
        getStatusText,
        fileInput,
        handleSelectFile,
        handleFileChange,
        handleDeleteAvatar,
        getFullAvatarUrl,
        getTotalStudyTime
      }
    }
  }
</script>

<style scoped>
  .student-manage {
    width: 100%;
    height: 100%;
  }

  .card {
    height: 100%;
    margin-bottom: 0;
    border-radius: 8px;
  }

  .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 1rem;
  }

  .card-body {
    padding: 1.5rem;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  .col-md-3 {
    padding: 0 1rem;
  }

  .table th {
    font-weight: 600;
    color: #495057;
  }

  .badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
  }

  .btn-sm {
    padding: 0.25rem 0.5rem;
  }

  .progress {
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }

  .progress-bar {
    transition: width 0.3s ease;
  }

  /* Element Plus 样式覆盖 */
  :deep(.el-dialog__body) {
    padding: 20px 30px;
  }

  :deep(.el-form-item__label) {
    font-weight: 500;
  }

  :deep(.el-input__inner) {
    border-radius: 4px;
  }

  :deep(.el-button) {
    border-radius: 4px;
    padding: 8px 20px;
  }

  :deep(.el-select) {
    width: 100%;
  }

  .avatar-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .avatar-preview {
    position: relative;
    width: 100px;
    height: 100px;
  }

  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .avatar-delete {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 4px 8px;
    border-radius: 50%;
  }

  /* 学习统计相关样式 */
  .student-stats {
    color: #495057;
  }

  .stat-icon {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 0 auto;
  }

  .stat-icon i {
    font-size: 18px;
  }

  .bg-light-primary {
    background-color: rgba(13, 110, 253, 0.1);
  }

  .bg-light-success {
    background-color: rgba(25, 135, 84, 0.1);
  }

  .bg-light-warning {
    background-color: rgba(255, 193, 7, 0.1);
  }

  .bg-light-info {
    background-color: rgba(13, 202, 240, 0.1);
  }

  .stat-label {
    color: #6c757d;
  }

  .stat-value {
    color: #212529;
  }

  .daily-activity {
    margin-top: 1rem;
  }

  .activity-timeline {
    position: relative;
    margin: 1rem 0;
  }

  .activity-hour {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30px;
  }

  .activity-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e9ecef;
    margin-bottom: 4px;
  }

  .activity-hour.active .activity-dot {
    background-color: #198754;
  }

  .activity-label {
    font-size: 10px;
  }

  .course-icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  /* 表格样式优化 */
  .table th {
    font-weight: 600;
    color: #495057;
    border-top: none;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .card {
    transition: all 0.3s;
  }

  .student-profile {
    background-color: #f8f9fa;
  }

  .fw-medium {
    font-weight: 500;
  }
</style>