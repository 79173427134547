<template>
  <div class="video-player-container" :class="{ 'disable-seeking': disableSeeking }">
    <video
            ref="videoElement"
            class="video-js vjs-default-skin vjs-big-play-centered"
            controls
            preload="auto"
            :width="width"
            :height="height"
            :poster="poster"
            @timeupdate="handleTimeUpdate"
            @ended="handleVideoEnd"
            @play="handlePlay"
            @pause="handlePause"
            @seeking="handleSeeking"
            @seeked="handleSeeked"
            @waiting="handleWaiting"
            @canplay="handleCanPlay"
            @error="handleError"
    >
      <source :src="source" type="video/mp4">
      <p class="vjs-no-js">
        要观看此视频，请启用JavaScript，并考虑升级到支持HTML5视频的浏览器。
      </p>
    </video>

    <!-- 加载状态 -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">加载中...</span>
      </div>
    </div>

    <!-- 错误提示 -->
    <div v-if="error" class="error-message">
      {{ error }}
      <button class="btn btn-light btn-sm" @click="retryLoad">重试</button>
    </div>

    <!-- 播放控制器 -->
    <div class="custom-controls" v-show="!loading && !error">
      <!-- 进度条 -->
      <div class="progress-bar"
           @click="handleProgressClick"
           @mousemove="handleProgressHover">
        <div class="progress-track">
          <div class="progress-loaded" :style="{ width: loadedProgress + '%' }"></div>
          <div class="progress-played" :style="{ width: playedProgress + '%' }"></div>
        </div>
        <div class="progress-handle"
             :style="{ left: playedProgress + '%' }"
             v-show="isDragging"></div>
        <div class="progress-tooltip"
             v-show="showTooltip"
             :style="{ left: tooltipPosition + 'px' }">
          {{ formatTime(tooltipTime) }}
        </div>
      </div>

      <!-- 控制按钮 -->
      <div class="control-buttons">
        <button class="btn btn-link" @click="togglePlay">
          <i :class="['fas', isPlaying ? 'fa-pause' : 'fa-play']"></i>
        </button>
        <div class="time-display">
          {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
        </div>
        <div class="volume-control">
          <button class="btn btn-link" @click="toggleMute">
            <i :class="['fas', isMuted ? 'fa-volume-mute' : 'fa-volume-up']"></i>
          </button>
          <input type="range"
                 class="volume-slider"
                 min="0"
                 max="100"
                 v-model="volume">
        </div>
        <button class="btn btn-link" @click="toggleFullscreen">
          <i class="fas fa-expand"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch, nextTick, onBeforeUnmount, defineComponent, inject } from 'vue'
  import { ElMessage } from 'element-plus'
  import request from '@/utils/axios'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'VideoPlayer',
    props: {
      source: {
        type: String,
        required: true
      },
      poster: {
        type: String,
        default: ''
      },
      width: {
        type: [String, Number],
        default: '100%'
      },
      height: {
        type: [String, Number],
        default: 'auto'
      },
      autoplay: {
        type: Boolean,
        default: false
      },
      courseId: {
        type: Number,
        required: true
      },
      videoId: {
        type: Number,
        required: true
      },
      requiresLogin: {  //  新增：接收 requiresLogin prop
        type: Boolean,
        default: false
      },
      disableSeeking: {  // 新增属性：禁用拖动进度条
        type: Boolean,
        default: false
      }
    },
    emits: ['timeupdate', 'ended', 'error', 'completed', 'progress-updated'],
    setup(props, { emit }) {
      const router = useRouter();
      const videoElement = ref(null)
      const loading = ref(true)
      const error = ref(null)
      const isPlaying = ref(false)
      const isMuted = ref(false)
      const volume = ref(100)
      const currentTime = ref(0)
      const duration = ref(0)
      const loadedProgress = ref(0)
      const playedProgress = ref(0)
      const isDragging = ref(false)
      const showTooltip = ref(false)
      const tooltipPosition = ref(0)
      const tooltipTime = ref(0)
      const watchStartTime = ref(null)
      const isVideoCompleted = ref(false)

      const isLoggedIn = inject('isLoggedIn', ref(false));  //  注入 isLoggedIn 状态

      console.log("isLoggedIn--->>",isLoggedIn.value);

      let deviceInfo = {}; // Declare deviceInfo here


      const totalWatchDuration = ref(0); // 存储累计观看时长，单位为毫秒
      const currentWatchStartTime = ref(null); // 存储当前播放开始时间
      let reportTimer = null; // 定时上报的计时器
      let pendingWatchData = null;
      // 监听视频源变化
      watch(() => props.source, (newSource, oldSource) => {
        if (!newSource) return;

        if (newSource !== oldSource) {
          nextTick(async () => {
            try {
              console.log('视频源发生变化，重新初始化播放器');
              // 重置状态
              isPlaying.value = false;
              loading.value = true;
              error.value = null;
              currentTime.value = 0;
              duration.value = 0;
              loadedProgress.value = 0;
              playedProgress.value = 0;
              totalWatchDuration.value = 0;
              isVideoCompleted.value = false;

              // 停止之前的进度上报
              stopReportingProgress();

              // 清理之前的播放记录
              if (pendingWatchData) {
                localStorage.removeItem('pendingWatchData');
                pendingWatchData = null;
              }

              // 设置新的开始时间
              watchStartTime.value = new Date();

              // 重新初始化视频
              await initVideo();
              // 加载新视频的观看记录
              await loadWatchRecord();
            } catch (err) {
              console.error('视频切换失败:', err);
              error.value = '视频加载失败，请刷新页面重试';
            }
          });
        }
      }, { immediate: true });

      // 格式化时间
      const formatTime = (seconds) => {
        if (!seconds) return '00:00'
        const h = Math.floor(seconds / 3600)
        const m = Math.floor((seconds % 3600) / 60)
        const s = Math.floor(seconds % 60)
        return h > 0
                ? `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`
                : `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`
      }

      // 初始化视频
      const initVideo = () => {
        try {
          console.log('初始化视频播放器:', {
            source: props.source,
            volume: volume.value,
            autoplay: props.autoplay
          });

          if (!props.source) {
            throw new Error('视频源不能为空');
          }

          if (videoElement.value) {
            videoElement.value.src = props.source;
            videoElement.value.volume = volume.value / 100;

            // 重置视频元素
            videoElement.value.currentTime = 0;
            videoElement.value.load();

            // 确保视频控件可见
            videoElement.value.controls = true;

            // 如果设置了自动播放，则尝试播放
            if (props.autoplay) {
              videoElement.value.play().catch(err => {
                console.warn('自动播放失败:', err);
              });
            }
          } else {
            throw new Error('视频元素未找到');
          }
        } catch (err) {
          console.error('初始化视频失败:', err);
          error.value = err.message;
          emit('error', {
            type: 'error',
            message: err.message
          });
        }
      };
      // 播放/暂停切换
      const togglePlay = () => {
        if (!videoElement.value) return
        if (isPlaying.value) {
          videoElement.value.pause()
        } else {
          videoElement.value.play().catch(err => {
            console.error('播放失败:', err)
            ElMessage.error('视频播放失败，请重试')
          })
        }
      }

      // 静音切换
      const toggleMute = () => {
        if (!videoElement.value) return
        videoElement.value.muted = !videoElement.value.muted
        isMuted.value = videoElement.value.muted
      }

      // 全屏切换
      const toggleFullscreen = () => {
        if (!videoElement.value) return
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else {
          videoElement.value.requestFullscreen()
        }
      }

      // 进度条点击
      const handleProgressClick = (event) => {
        if (!videoElement.value || props.disableSeeking) return
        const rect = event.currentTarget.getBoundingClientRect()
        const percent = (event.clientX - rect.left) / rect.width
        videoElement.value.currentTime = percent * duration.value
      }

      // 进度条悬停
      const handleProgressHover = (event) => {
        if (!videoElement.value || props.disableSeeking) return
        const rect = event.currentTarget.getBoundingClientRect()
        const percent = (event.clientX - rect.left) / rect.width
        tooltipPosition.value = event.clientX - rect.left
        tooltipTime.value = percent * duration.value
        showTooltip.value = true
      }

      const reportProgress = async (watchDataInput = null) => {

        const isLoggedInValue = isLoggedIn.value;


        console.log("props.requiresLogin",props.requiresLogin);

        console.log("isLoggedInValue",isLoggedInValue);
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn("课程需要登录，并且用户未登录，不记录进度");
          return;
        }

        if (isVideoCompleted.value) {
          return;
        }

        try {
          if (!videoElement.value) return;

          const userInfoStr = localStorage.getItem('userInfo');
          if (!userInfoStr) {
            console.warn('用户未登录，无法记录进度');
            return;
          }

          const userInfo = JSON.parse(userInfoStr);
          const studentId = userInfo.id;
          if (!studentId) {
            console.warn('未找到用户id，无法记录进度');
            return;
          }

          const currentTime = Math.floor(videoElement.value.currentTime);
          const totalTime = Math.floor(videoElement.value.duration);
          const progress = calculateProgress(currentTime, totalTime);

          // 计算实际观看时长（秒）
          const actualWatchDuration = Math.floor(totalWatchDuration.value / 1000);

          // 确保 watchDuration 不为0，且不小于之前记录的时长
          const watchData = watchDataInput || {
            courseId: props.courseId,
            lessonId: props.videoId,
            currentTime: currentTime,
            totalTime: totalTime,
            progress: progress,
            watchDuration: Math.max(actualWatchDuration, currentTime), // 使用较大的值
            isCompleted: progress >= 98,
            startTime: watchStartTime.value.toISOString(),
            lastWatchTime: new Date().toISOString(),
            deviceInfo: JSON.stringify(deviceInfo),
            ipAddress: ''
          };

          console.log('上报观看进度:', watchData);


          console.log("props.requiresLogin===>>>",props.requiresLogin);
          // 在发送请求前再次进行判断
          if (props.requiresLogin && isLoggedInValue) { // 添加判断
            try {
              const response = await request({
                url: `/api/student/learning/${studentId}/courses/${props.courseId}/lessons/${props.videoId}/progress`,
                method: 'post',
                data: watchData,
                headers: { // 确保请求头包含 Authorization
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              });

              // 只有在请求成功后才执行以下代码
              console.log('进度记录成功:', response.data);

              // 如果返回的记录显示视频已完成，则更新状态
              if (response.data && response.data.isCompleted) {
                isVideoCompleted.value = true;
              }

              // 触发进度更新事件
              emit('progress-updated', {
                lessonId: props.videoId,
                progress: response.data.progress, // 使用服务器返回的进度
                isCompleted: response.data.isCompleted,
                currentTime: response.data.currentTime
              });
            } catch (apiError) {
              console.error('请求失败:', apiError);
              // 现在 apiError 可以在 catch 块中使用
            }

          } else {
            console.warn("课程需要登录或用户未登录，不发送观看进度请求");
          }

          // 清理 pendingWatchData
          if (watchDataInput) {
            pendingWatchData = null;
            localStorage.removeItem('pendingWatchData');
          }

        } catch (error) {
          console.error('记录观看进度失败:', error);
          // 保存到本地，等待下次重试
          if (!watchDataInput && videoElement.value) {
            const errorWatchData = {
              courseId: props.courseId,
              lessonId: props.videoId,
              currentTime: Math.floor(videoElement.value.currentTime),
              totalTime: Math.floor(videoElement.value.duration),
              watchDuration: Math.floor(totalWatchDuration.value / 1000),
              progress: calculateProgress(videoElement.value.currentTime, videoElement.value.duration),
              isCompleted: false,
              startTime: watchStartTime.value.toISOString(),
              lastWatchTime: new Date().toISOString(),
              deviceInfo: JSON.stringify(deviceInfo)
            };
            pendingWatchData = errorWatchData;
            localStorage.setItem('pendingWatchData', JSON.stringify(errorWatchData));
          }
          ElMessage.error('记录观看进度失败，将在下次播放时重试');
        }
      };

      // 计算观看进度
      const calculateProgress = (currentTime, totalTime) => {
        if (!totalTime) return 0
        const progress = (currentTime / totalTime) * 100
        return Math.min(Math.max(progress, 0), 100) // 确保进度在0-100之间
      }


      // 初始化时获取历史观看记录
      const loadWatchRecord = async () => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不加载观看记录');
          return;
        }

        try {
          const userInfoStr = localStorage.getItem('userInfo')
          if (!userInfoStr) return

          const userInfo = JSON.parse(userInfoStr)
          const studentId = userInfo.id
          if (!studentId) return

          const response = await request({
            url: `/api/student/learning/${studentId}/courses/${props.courseId}/lessons/${props.videoId}/record`,
            method: 'get'
          })

          if (response.data) {
            // 如果视频已完成，标记状态
            if (response.data.isCompleted) {
              isVideoCompleted.value = true;
              console.log('该视频已完成学习，不再记录进度');
            } else {
              // 只有在视频未完成时才恢复进度
              if (response.data.currentTime) {
                videoElement.value.currentTime = response.data.currentTime
                console.log('已恢复上次观看进度:', response.data.currentTime)
              }
            }
          }
        } catch (error) {
          console.error('获取观看记录失败:', error)
        }
      }

      // 组件挂载时初始化
      onMounted(() => {
        deviceInfo = {
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
          screenResolution: `${window.screen.width}x${window.screen.height}`
        };

        // 初始化视频播放器
        initVideo();

        // 添加页面可见性变化监听
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // 添加路由变化监听
        const unsubscribe = router.beforeEach(async (to, from, next) => {
          if (videoElement.value && isPlaying.value) {
            await updateTotalWatchDuration();
            await reportProgress();
            stopReportingProgress();
          }
          next();
        });

        window.addEventListener('beforeunload', handleBeforeUnload);

        // 在组件卸载时清理路由监听
        onBeforeUnmount(() => {
          unsubscribe && unsubscribe();
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          window.removeEventListener('beforeunload', handleBeforeUnload);
          stopReportingProgress();

          // 确保在组件卸载前保存进度
          if (videoElement.value) {
            videoElement.value.pause();
            updateTotalWatchDuration();
            reportProgress();
          }
        });
      });


      // 修改 handlePause 事件
      const handlePause = async () => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不保存观看记录');
          return;
        }
        isPlaying.value = false;
        await updateTotalWatchDuration(); // 暂停时更新总时长
        await reportProgress(); // 暂停时上报进度
        stopReportingProgress();
      };

      // 修改 startReportingProgress 函数
      const startReportingProgress = () => {
        // 如果是需要登录的课程，且未登录，则不加载观看记录
        const isLoggedInValue = isLoggedIn.value;
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不启动上报机制');
          return;
        }
        if (isVideoCompleted.value) {
          return;
        }

        stopReportingProgress(); // 先清理可能存在的定时器

        if (!reportTimer) {
          // 立即执行一次
          updateTotalWatchDuration();
          reportProgress();

          // 设置定时器
          reportTimer = setInterval(async () => {
            if (isPlaying.value) { // 只在播放状态下更新
              await updateTotalWatchDuration();
              await reportProgress();
            }
          }, 30000); // 每30秒上报一次
        }
      };

      // 添加页面离开事件处理
      const handleVisibilityChange = async () => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不处理页面状态变化');
          return;
        }
        if (document.hidden && videoElement.value) {
          videoElement.value.pause(); // 页面隐藏时暂停视频
          await updateTotalWatchDuration();
          await reportProgress();
          stopReportingProgress();
        }
      };

      // 修改 handleVideoEnd 事件
      // handleVideoEnd
      const handleVideoEnd = async () => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不处理视频结束事件');
          return;
        }

        isPlaying.value = false;
        await updateTotalWatchDuration();
        stopReportingProgress();

        // 只有在视频未完成时才上报最终进度
        if (!isVideoCompleted.value) {
          const finalProgress = {
            ...pendingWatchData,
            progress: 100,
            isCompleted: true,
            currentTime: videoElement.value.duration,
            watchDuration: Math.floor(videoElement.value.duration),
            lastWatchTime: new Date().toISOString()
          };

          await reportProgress(finalProgress);
          isVideoCompleted.value = true; // 标记视频已完成
        }

        // 清理相关数据
        totalWatchDuration.value = 0;
        pendingWatchData = null;
        localStorage.removeItem('pendingWatchData');

        emit('ended');
      };


      const handleCanPlay = async () => {
        loading.value = false;
        duration.value = videoElement.value?.duration || 0;

        // 确保视频控件可见
        if (videoElement.value) {
          videoElement.value.controls = true;
        }

        // 只处理未完成的进度
        if (!isVideoCompleted.value && pendingWatchData) {
          await reportProgress(pendingWatchData);
          localStorage.removeItem('pendingWatchData');
          pendingWatchData = null;
        }
      };

      // handleTimeUpdate 函数
      const handleTimeUpdate = (event) => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不更新时间');
          return;
        }

        if (!videoElement.value) return;
        currentTime.value = videoElement.value.currentTime;
        playedProgress.value = calculateProgress(currentTime.value, duration.value);
        emit('timeupdate', event);
      };

      const handleSeeking = () => {
        loading.value = true
      }

      const handleSeeked = () => {
        loading.value = false
      }
      const handleWaiting = () => {
        loading.value = true
      }
      // const handleCanPlay = () => {
      //   loading.value = false
      //   duration.value = videoElement.value?.duration || 0
      // }

      const handleError = (event) => {
        loading.value = false
        const videoElement = event.target
        let errorMessage = '视频加载失败，请检查网络连接后重试'

        if (videoElement.error) {
          console.error('视频错误详情:', {
            code: videoElement.error.code,
            message: videoElement.error.message
          })

          switch (videoElement.error.code) {
            case 1:
              errorMessage = '视频加载被中断'
              break
            case 2:
              errorMessage = '网络错误导致视频下载失败'
              break
            case 3:
              errorMessage = '视频解码失败'
              break
            case 4:
              errorMessage = '视频格式不支持'
              break
          }
        }

        error.value = errorMessage
        emit('error', {
          type: 'error',
          message: errorMessage,
          originalEvent: event
        })
      }

      // 重试加载
      const retryLoad = () => {
        if (!videoElement.value) return
        error.value = null
        loading.value = true
        videoElement.value.load()
      }

      // 监听音量变化
      const handleVolumeChange = () => {
        if (!videoElement.value) return
        videoElement.value.volume = volume.value / 100
        isMuted.value = volume.value === 0
      }

      // 添加 handlePlay 函数定义
      const handlePlay = () => {
        const isLoggedInValue = isLoggedIn.value;


        console.log("isLoggedInValue",isLoggedInValue);

        // 如果是需要登录的课程，且未登录，则暂停播放并跳转到登录页面
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，暂停播放并跳转到登录页面');
          ElMessage.warning('请登录后观看完整课程');
          router.push('/auth/login'); //  替换为你的登录页面路径
          videoElement.value.pause(); // 暂停视频播放
          return;
        }
        isPlaying.value = true;
        if (!currentWatchStartTime.value) {
          currentWatchStartTime.value = Date.now();
        }
        startReportingProgress();
      };

      // 添加 handleBeforeUnload 函数定义
      const handleBeforeUnload = async () => {
        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不处理页面卸载事件');
          return;
        }
        if (videoElement.value && isPlaying.value) {
          await updateTotalWatchDuration(); // 更新并保存观看记录
          await reportProgress();
        }
      };

      // 提取更新 totalWatchDuration 的逻辑
      const updateTotalWatchDuration = async () => {

        const isLoggedInValue = isLoggedIn.value;

        // 如果是需要登录的课程，且未登录，则不加载观看记录
        if (props.requiresLogin && !isLoggedInValue) {
          console.warn('该课程需要登录，但用户未登录，不更新学习时长');
          return;
        }
        const now = Date.now();

        // 计算这次播放的时长
        if (currentWatchStartTime.value) {
          const watchedMilliseconds = now - currentWatchStartTime.value;
          totalWatchDuration.value += watchedMilliseconds;
          currentWatchStartTime.value = null;
        }

        // 只在需要时更新 pendingWatchData
        if (videoElement.value) {
          const currentTime = Math.floor(videoElement.value.currentTime);
          const totalTime = Math.floor(videoElement.value.duration);
          const currentProgress = calculateProgress(currentTime, totalTime);

          // 计算实际观看时长（秒）
          const actualWatchDuration = Math.floor(totalWatchDuration.value / 1000);

          // 如果进度接近100%，使用视频总时长，否则使用实际观看时长
          const watchDuration = currentProgress >= 98 ? totalTime : actualWatchDuration;

          if (currentProgress < 98) { // 只在视频未完成时保存进度
            pendingWatchData = {
              courseId: props.courseId,
              lessonId: props.videoId,
              currentTime: currentTime,
              totalTime: totalTime,
              watchDuration: watchDuration > 0 ? watchDuration : currentTime, // 确保 watchDuration 不为0
              progress: currentProgress,
              isCompleted: false,
              startTime: watchStartTime.value.toISOString(),
              lastWatchTime: new Date().toISOString(),
              deviceInfo: JSON.stringify(deviceInfo)
            };

            try {
              localStorage.setItem('pendingWatchData', JSON.stringify(pendingWatchData));
            } catch (error) {
              console.error('保存进度到本地存储失败:', error);
            }
          }
        }
      };

      // 定义 stopReportingProgress 函数
      const stopReportingProgress = () => {
        if (reportTimer) {
          clearInterval(reportTimer);
          reportTimer = null;
        }
      };

      return {
        videoElement,
        loading,
        error,
        isPlaying,
        isMuted,
        volume,
        currentTime,
        duration,
        loadedProgress,
        playedProgress,
        isDragging,
        showTooltip,
        tooltipPosition,
        tooltipTime,
        formatTime,
        togglePlay,
        toggleMute,
        toggleFullscreen,
        handleProgressClick,
        handleProgressHover,
        handleTimeUpdate,
        handleVideoEnd,
        handlePlay,
        handlePause,
        handleSeeking,
        handleSeeked,
        handleWaiting,
        handleCanPlay,
        handleError,
        retryLoad,
        handleVolumeChange,
        isVideoCompleted,
        startReportingProgress,
        stopReportingProgress,
        updateTotalWatchDuration,
        handleVisibilityChange,
        handleBeforeUnload,
      }
    }
  })
</script>

<style scoped>
  .video-player-container {
    position: relative;
    width: 100%;
    background: #000;
  }

  /* 添加禁用拖动进度条的样式 */
  :deep(.disable-seeking .vjs-progress-control) {
    pointer-events: none;
    opacity: 0.6;
  }

  :deep(.disable-seeking .progress-bar) {
    pointer-events: none;
    opacity: 0.6;
  }

  .video-js {
    width: 100%;
    height: 100%;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
    z-index: 2;
  }

  .custom-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
    padding: 1rem;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .video-player-container:hover .custom-controls {
    opacity: 1;
  }

  .progress-bar {
    position: relative;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    margin-bottom: 0.5rem;
  }

  .progress-track {
    position: relative;
    height: 100%;
  }

  .progress-loaded {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
  }

  .progress-played {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #1976d2;
  }

  .progress-handle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
  }

  .progress-tooltip {
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 12px;
  }

  .control-buttons {
    display: flex;
    align-items: center;
    color: #fff;
  }

  .btn-link {
    color: #fff;
    padding: 0.25rem 0.5rem;
  }

  .btn-link:hover {
    color: #1976d2;
  }

  .time-display {
    margin: 0 1rem;
    font-size: 14px;
  }

  .volume-control {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .volume-slider {
    width: 60px;
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    .custom-controls {
      padding: 0.5rem;
    }

    .time-display {
      display: none;
    }

    .volume-control {
      display: none;
    }
  }
</style>