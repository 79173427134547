<template>
  <div class="course-list">
    <page-header title="课程列表"></page-header>

    <!-- 引入 CourseSearch 组件 -->
    <CourseSearch @courses-updated="updateCourses" />

    <div class="container py-4">
      <!-- 结果统计和排序 -->
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="text-muted">
          <span class="badge bg-primary me-2">{{ totalElements }}</span>个课程
        </div>
        <div class="d-flex align-items-center">
          <span class="me-2">排序:</span>
          <select class="form-select form-select-sm" style="width: 120px;" v-model="sortBy" @change="sortCourses">
            <option value="newest">最新上架</option>
            <option value="popular">最受欢迎</option>
            <option value="priceAsc">价格从低到高</option>
            <option value="priceDesc">价格从高到低</option>
          </select>
        </div>
      </div>

      <!-- 加载状态 -->
      <div v-if="isLoading" class="text-center py-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">加载中...</span>
        </div>
        <p class="mt-3 text-muted">正在加载课程...</p>
      </div>

      <!-- 空结果提示 -->
      <div v-else-if="courses.length === 0" class="text-center py-5">
        <div class="mb-4">
          <i class="fas fa-search fa-3x text-muted"></i>
        </div>
        <h5 class="mb-2">暂无找到相关课程</h5>
        <p class="text-muted">尝试修改搜索条件或浏览其他课程分类</p>
        <button class="btn btn-outline-primary mt-2" @click="resetSearch">查看全部课程</button>
      </div>

      <!-- 课程列表内容 -->
      <div v-else class="row g-4">
        <div
                v-for="course in displayedCourses"
                :key="course.id"
                class="col-md-4 col-sm-6"
                @click="goToCourseDetail(course)"
        >
          <div class="card h-100 course-card shadow-sm">
            <div class="position-relative">
              <!-- 直接显示图片，移除懒加载相关代码 -->
              <img 
                :src="course.coverImageUrl" 
                class="card-img-top" 
                :alt="course.name"
                @error="handleImageError(course)"
              />
              <div v-if="course.price > 0" class="course-price">
                <span class="badge bg-primary">¥{{ course.price }}</span>
              </div>
              <div v-else class="course-price">
                <span class="badge bg-success">免费</span>
              </div>
            </div>
            <div class="card-body d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <!-- 课程类型标签 -->
                <span v-if="course.categoryName" class="badge bg-light text-dark me-2">
                  {{ course.categoryName }}
                </span>
                
                <!-- 难度标签 -->
                <span v-if="course.level" class="badge" :class="getLevelBadgeClass(course.level)">
                  {{ getLevelLabel(course.level) }}
                </span>
                
                <!-- 需要登录标志 -->
                <span v-if="course.requiresLogin" class="ms-auto">
                  <i class="fas fa-lock text-secondary" title="需要登录"></i>
                </span>
              </div>
              
              <h5 class="card-title">{{ course.name }}</h5>
              <p class="card-text text-muted description flex-grow-1">{{ course.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <nav v-if="totalElements > 0" class="mt-5">
        <div class="d-flex justify-content-between align-items-center">
          <div class="text-muted">
            显示 {{ displayStartIndex }} - {{ displayEndIndex }} 项，共 {{ totalElements }} 项
          </div>
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage - 1)">
                <i class="fas fa-chevron-left"></i>
              </a>
            </li>
            <template v-if="totalPages <= 7">
              <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
              </li>
            </template>
            <template v-else>
              <!-- 首页 -->
              <li class="page-item" :class="{ active: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(1)">1</a>
              </li>
              
              <!-- 省略号 -->
              <li v-if="currentPage > 3" class="page-item disabled">
                <span class="page-link">...</span>
              </li>
              
              <!-- 当前页附近的页码 -->
              <li v-for="page in middlePages" :key="page" class="page-item" :class="{ active: page === currentPage }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
              </li>
              
              <!-- 省略号 -->
              <li v-if="currentPage < totalPages - 2" class="page-item disabled">
                <span class="page-link">...</span>
              </li>
              
              <!-- 末页 -->
              <li class="page-item" :class="{ active: currentPage === totalPages }">
                <a class="page-link" href="#" @click.prevent="handlePageChange(totalPages)">{{ totalPages }}</a>
              </li>
            </template>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage + 1)">
                <i class="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
  import { ref, onMounted, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import PageHeader from '@/components/PageHeader.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  import CourseSearch from './CourseSearch.vue' // 引入 CourseSearch 组件
  import request from '@/utils/axios'
  import { ElMessage } from 'element-plus'
  import { processCourseImages } from '@/utils/imageLoader'

  export default {
    name: 'CourseList',
    components: {
      PageHeader,
      SiteFooter,
      CourseSearch // 注册 CourseSearch 组件
    },
    setup() {
      const courses = ref([])
      const currentPage = ref(1)
      const totalPages = ref(1)
      const totalElements = ref(0)
      const pageSize = 9
      const router = useRouter()
      const isLoading = ref(true)
      const sortBy = ref('newest')
      
      // 计算中间应该显示的页码
      const middlePages = computed(() => {
        const pages = []
        const start = Math.max(2, currentPage.value - 1)
        const end = Math.min(totalPages.value - 1, currentPage.value + 1)
        
        for (let i = start; i <= end; i++) {
          pages.push(i)
        }
        
        return pages
      })
      
      // 显示的课程
      const displayedCourses = computed(() => {
        return courses.value
      })
      
      // 课程等级配置
      const courseLevelConfig = {
        'BEGINNER': { label: '入门', class: 'bg-info' },
        'INTERMEDIATE': { label: '进阶', class: 'bg-warning' },
        'ADVANCED': { label: '高级', class: 'bg-danger' }
      }

      // 检查用户是否已经登录
      const isLoggedIn = () => {
        return localStorage.getItem('token') !== null
      }

      const fetchCourses = async (page) => {
        isLoading.value = true
        try {
          const response = await request({
            url: 'api/public/courses/list',
            params: {
              page: page - 1,
              size: pageSize,
              deletedStatus: false
            }
          })
          if (response.data) {
            // 使用imageLoader工具处理课程图片
            courses.value = processCourseImages(response.data.content)
            totalPages.value = response.data.totalPages
            totalElements.value = response.data.totalElements
          }
        } catch (error) {
          console.error('获取课程列表失败:', error)
          ElMessage.error('获取课程列表失败，请稍后重试')
        } finally {
          isLoading.value = false
        }
      }

      const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages.value) {
          currentPage.value = page
          window.scrollTo(0, 0) // 回到页面顶部
          fetchCourses(page)
        }
      }
      
      // 用于更新课程列表的方法
      const updateCourses = (newCourses, paginationInfo) => {
        // 使用imageLoader工具处理课程图片
        courses.value = processCourseImages(newCourses)
        
        // 更新分页信息
        if (paginationInfo) {
          totalPages.value = paginationInfo.totalPages || 1
          currentPage.value = (paginationInfo.currentPage || 0) + 1
          totalElements.value = paginationInfo.totalElements || newCourses.length
        } else {
          totalPages.value = Math.ceil(newCourses.length / pageSize)
          currentPage.value = 1
          totalElements.value = newCourses.length
        }
      }
      
      // 重置搜索，显示所有课程
      const resetSearch = () => {
        fetchCourses(1)
      }
      
      // 对课程进行排序
      const sortCourses = () => {
        const sortedCourses = [...courses.value]
        
        switch (sortBy.value) {
          case 'newest':
            sortedCourses.sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0))
            break
          case 'popular':
            sortedCourses.sort((a, b) => (b.enrollCount || 0) - (a.enrollCount || 0))
            break
          case 'priceAsc':
            sortedCourses.sort((a, b) => (a.price || 0) - (b.price || 0))
            break
          case 'priceDesc':
            sortedCourses.sort((a, b) => (b.price || 0) - (a.price || 0))
            break
        }
        
        courses.value = sortedCourses
      }
      
      // 格式化课程时长
      const formatDuration = (duration, unit) => {
        if (!duration) return '未知时长'
        
        switch (unit) {
          case 'MINUTE':
            return duration < 60 ? `${duration}分钟` : `${Math.floor(duration / 60)}小时${duration % 60 ? duration % 60 + '分钟' : ''}`
          case 'HOUR':
            return `${duration}小时`
          case 'DAY':
            return `${duration}天`
          case 'WEEK':
            return `${duration}周`
          default:
            return `${duration}分钟`
        }
      }
      
      // 获取课程难度标签类
      const getLevelBadgeClass = (level) => {
        return courseLevelConfig[level]?.class || 'bg-secondary'
      }
      
      // 获取课程难度标签文本
      const getLevelLabel = (level) => {
        return courseLevelConfig[level]?.label || level
      }

      const goToCourseDetail = (course) => {
        if (course.requiresLogin) {
          if (isLoggedIn()) {
            router.push(`/courses/${course.id}`)
          } else {
            ElMessage.warning('该课程需要登录后才能查看详情，请先登录')
            router.push('/auth/login')
          }
        } else {
          router.push(`/courses/${course.id}`)
        }
      }

      // 计算当前页显示的开始索引
      const displayStartIndex = computed(() => {
        return totalElements.value > 0 ? pageSize * (currentPage.value - 1) + 1 : 0
      })
      
      // 计算当前页显示的结束索引
      const displayEndIndex = computed(() => {
        return totalElements.value > 0 ? 
          Math.min(pageSize * currentPage.value, totalElements.value) : 0
      })

      // 处理图片加载失败
      const handleImageError = (course) => {
        course.coverImageUrl = '/assets/images/course-placeholder.jpg'
      }

      onMounted(() => {
        fetchCourses(1)
      })

      return {
        courses,
        displayedCourses,
        currentPage,
        totalPages,
        totalElements,
        middlePages,
        isLoading,
        sortBy,
        displayStartIndex,
        displayEndIndex,
        handlePageChange,
        goToCourseDetail,
        updateCourses,
        resetSearch,
        sortCourses,
        formatDuration,
        getLevelBadgeClass,
        getLevelLabel,
        handleImageError
      }
    }
  }
</script>

<style scoped>
  .course-list {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1;
  }

  .card-img-top {
    height: 180px;
    object-fit: cover;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 3rem;
  }
  
  .course-card {
    transition: all 0.3s ease;
    cursor: pointer;
    border: none;
    overflow: hidden;
  }
  
  .course-card:hover {
    transform: translateY(-6px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
  }
  
  .course-price {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .course-price .badge {
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .course-info {
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 0.75rem;
  }
  
  .page-link {
    color: #0d6efd;
    border-color: #dee2e6;
  }
  
  .page-item.active .page-link {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
</style>