<template>
  <div class="course-play">
    <!-- 加载状态 -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">加载中...</span>
      </div>
    </div>

    <!-- 错误提示 -->
    <div v-if="error" class="alert alert-danger mx-3 mt-3" role="alert">
      {{ error }}
      <button type="button" class="btn-close float-end" @click="error = null"></button>
    </div>

    <div class="course-play-container" v-if="!loading && !error">
      <div class="row g-0">
        <!-- 左侧视频播放区域 -->
        <div class="col-md-9">
          <div class="video-container">
            <video-player
              ref="videoPlayer"
              :source="videoSource"
              :poster="currentVideo.poster"
              :autoplay="false"
              :course-id="courseId"
              :video-id="currentVideo.id"
              :disable-seeking="true"
              @timeupdate="handleTimeUpdate"
              @ended="handleVideoEnd"
              @error="handleVideoError"
            />
          </div>

          <!-- 视频信息 -->
          <div class="video-info p-3">
            <h4>{{ currentVideo.title }}</h4>
            <p class="text-muted mb-0">{{ currentVideo.description }}</p>
          </div>

          <!-- 课程资料 -->
          <div class="course-materials p-3" v-if="currentChapter.resources && currentChapter.resources.length">
            <h5>课程资料</h5>
            <div class="list-group">
              <a v-for="resource in currentChapter.resources"
                 :key="resource.id"
                 :href="resource.url"
                 target="_blank"
                 class="list-group-item list-group-item-action">
                <i class="fas fa-file-pdf me-2"></i>
                {{ resource.title }}
              </a>
            </div>
          </div>
        </div>

        <!-- 右侧课程目录 -->
        <div class="col-md-3 course-chapters">
          <div class="chapters-container">
            <div class="accordion" id="chaptersAccordion">
              <div class="accordion-item" v-for="chapter in course.chapters" :key="chapter.id">
                <h2 class="accordion-header">
                  <button class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#chapter' + chapter.id">
                    {{ chapter.title }}
                  </button>
                </h2>
                <div :id="'chapter' + chapter.id"
                     class="accordion-collapse collapse show">
                  <div class="accordion-body p-0">
                    <div class="list-group list-group-flush">
                      <button v-for="video in chapter.videos"
                              :key="video.id"
                              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                              :class="{ active: currentVideo.id === video.id }"
                              @click="playVideo(video)">
                        <div class="d-flex align-items-center">
                          <i class="fas fa-play-circle me-2"></i>
                          <div>
                            <div>{{ video.title }}</div>
                            <small class="text-muted">{{ formatDuration(video.duration) }}</small>
                          </div>
                        </div>
                        <span v-if="isVideoCompleted(video.id)"
                              class="badge bg-success rounded-pill">
                          <i class="fas fa-check"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import VideoPlayer from '@/components/video/VideoPlayer.vue'
import { updateWatchProgress, getWatchRecord } from '@/api/learning'
import { formatDuration } from '@/utils/date'
import request from '@/utils/axios'

export default {
  name: 'CoursePlay',
  components: {
    VideoPlayer
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const videoPlayer = ref(null)
    const loading = ref(false)
    const error = ref(null)

    // 课程数据
    const course = ref({
      id: null,
      title: '',
      chapters: []
    })

    // 当前播放的视频
    const currentVideo = ref({
      id: null,
      title: '',
      url: '',
      duration: 0,
      description: ''
    })

    // 当前章节
    const currentChapter = ref({
      id: null,
      title: '',
      resources: []
    })

    // 视频源
    const videoSource = ref('')

    // 播放器配置
    const playerOptions = ref({
      autoplay: false,
      controls: true,
      sources: [{
        type: "video/mp4",
        src: ""
      }]
    })

    // 观看记录
    const watchRecords = ref({})

    // 更新进度的间隔（秒）
    const PROGRESS_UPDATE_INTERVAL = 10
    let progressTimer = null

    // 获取课程数据
    const fetchCourseData = async () => {
      loading.value = true
      error.value = null
      try {
        const courseId = route.params.courseId
        const videoId = route.params.videoId
        console.log('获取课程数据，参数:', { courseId, videoId })

        const token = localStorage.getItem('token')
        if (!token) {
          throw new Error('未登录或token已过期')
        }

        // 获取课程详情
        const courseResponse = await request({
          url: `/api/courses/${courseId}/detail`,
          method: 'GET'
        })

        console.log('课程详情响应:', courseResponse.data)

        if (!courseResponse.data) {
          throw new Error('获取课程数据失败：响应数据为空')
        }

        // 更新课程数据
        course.value = {
          ...courseResponse.data.course,
          chapters: courseResponse.data.chapters || []
        }

        // 查找当前视频
        const currentVideoId = parseInt(videoId)
        let foundVideo = null
        let foundChapter = null

        for (const chapter of course.value.chapters) {
          const video = chapter.videos.find(v => v.id === currentVideoId)
          if (video) {
            foundVideo = video
            foundChapter = chapter
            break
          }
        }

        if (foundVideo) {
          console.log('找到当前视频:', foundVideo)
          currentChapter.value = foundChapter
          await playVideo(foundVideo)
        } else {
          throw new Error('未找到指定视频')
        }
      } catch (err) {
        console.error('获取课程数据失败:', err)
        if (err.response) {
          console.error('错误响应:', {
            status: err.response.status,
            data: err.response.data,
            headers: err.response.headers
          })
        }
        error.value = `获取课程数据失败: ${err.message}`
        ElMessage.error(error.value)
      } finally {
        loading.value = false
      }
    }

    // 获取观看记录
    const fetchWatchRecords = async () => {
      try {
        const response = await getWatchRecord(course.value.id)
        watchRecords.value = response.data.reduce((acc, record) => {
          acc[record.videoId] = record
          return acc
        }, {})
      } catch (err) {
        console.error('获取观看记录失败:', err)
      }
    }

    // 播放视频
    const playVideo = async (video) => {
      try {
        console.log('开始播放视频:', video)

        if (!video || !video.url) {
          throw new Error('视频地址不存在')
        }

        // 检查视频 URL 格式
        const videoUrl = video.url.startsWith('http')
          ? video.url
          : `${process.env.VUE_APP_API_URL || 'http://localhost:8080'}${video.url}`

        console.log('处理后的视频地址:', videoUrl)

        currentVideo.value = {
          id: video.id,
          title: video.title || video.name,
          url: videoUrl,
          duration: video.duration || 0,
          description: video.description || ''
        }

        console.log('当前视频信息:', currentVideo.value)

        // 更新视频源
        videoSource.value = videoUrl

        // 查找当前视频所属的章节
        for (const chapter of course.value.chapters) {
          if (chapter.videos.some(v => v.id === video.id)) {
            currentChapter.value = chapter
            console.log('当前章节:', currentChapter.value)
            break
          }
        }

        // 设置上次观看的位置
        const record = watchRecords.value[video.id]
        if (record && record.lastPosition > 0) {
          console.log('恢复上次观看位置:', record.lastPosition)
          setTimeout(() => {
            if (videoPlayer.value) {
              videoPlayer.value.currentTime = record.lastPosition
            }
          }, 100)
        }
      } catch (err) {
        console.error('播放视频失败:', err)
        error.value = `播放视频失败: ${err.message}`
        ElMessage.error(error.value)
      }
    }

    // 处理视频播放进度更新
    const handleTimeUpdate = async (event) => {
      const currentTime = Math.floor(event.target.currentTime)

      // 每隔一段时间更新进度
      if (currentTime % PROGRESS_UPDATE_INTERVAL === 0) {
        try {
          await updateWatchProgress({
            courseId: course.value.id,
            videoId: currentVideo.value.id,
            position: currentTime,
            duration: Math.floor(event.target.duration)
          })
        } catch (err) {
          console.error('更新观看进度失败:', err)
        }
      }
    }

    // 处理视频播放结束
    const handleVideoEnd = async () => {
      try {
        await updateWatchProgress({
          courseId: course.value.id,
          videoId: currentVideo.value.id,
          position: currentVideo.value.duration,
          duration: currentVideo.value.duration,
          completed: true
        })

        // 更新本地记录
        if (watchRecords.value[currentVideo.value.id]) {
          watchRecords.value[currentVideo.value.id].isCompleted = true
        }

        ElMessage.success('本节课程已完成！')

        // 自动播放下一个视频
        const nextVideo = findNextVideo()
        if (nextVideo) {
          await playVideo(nextVideo)
        }
      } catch (err) {
        console.error('更新完成状态失败:', err)
      }
    }

    // 查找下一个要播放的视频
    const findNextVideo = () => {
      let foundCurrent = false
      for (const chapter of course.value.chapters) {
        for (const video of chapter.videos) {
          if (foundCurrent) {
            return video
          }
          if (video.id === currentVideo.value.id) {
            foundCurrent = true
          }
        }
      }
      return null
    }

    // 检查视频是否已完成
    const isVideoCompleted = (videoId) => {
      return watchRecords.value[videoId]?.isCompleted || false
    }

    // 处理视频错误
    const handleVideoError = (error) => {
      console.error('视频播放错误:', error)
      ElMessage.error(error.message || '视频播放失败，请重试')
    }

    // 组件挂载时获取数据
    onMounted(async () => {
      if (!localStorage.getItem('token')) {
        ElMessage.warning('请先登录')
        router.push('/auth/login')
        return
      }
      await fetchCourseData()
      await fetchWatchRecords()
    })

    // 组件卸载前清理定时器
    onBeforeUnmount(() => {
      if (progressTimer) {
        clearInterval(progressTimer)
      }
    })

    return {
      course,
      currentVideo,
      currentChapter,
      playerOptions,
      loading,
      error,
      videoPlayer,
      videoSource,
      playVideo,
      handleTimeUpdate,
      handleVideoEnd,
      handleVideoError,
      isVideoCompleted,
      formatDuration
    }
  }
}
</script>

<style scoped>
.course-play {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.course-play-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.video-container {
  width: 100%;
  background: #000;
  aspect-ratio: 16/9;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.course-chapters {
  height: calc(100vh - 64px); /* 减去顶部导航栏高度 */
  overflow-y: auto;
  border-left: 1px solid #ebeef5;
  background: #fff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.05);
}

.chapters-container {
  padding: 1.5rem;
}

.accordion {
  border-radius: 8px;
  overflow: hidden;
}

.accordion-item {
  border: none;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.accordion-button {
  padding: 1rem 1.5rem;
  font-weight: 600;
  color: #2c3e50;
  background-color: #f8fafc;
  border: none;
  transition: all 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background-color: #edf2f7;
  color: #1a56db;
}

.accordion-button:hover {
  background-color: #edf2f7;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: #ebeef5;
}

.list-group-item {
  cursor: pointer;
  border: none;
  padding: 1rem 1.25rem;
  transition: all 0.3s ease;
  border-radius: 6px;
  margin-bottom: 0.25rem;
}

.list-group-item:hover {
  background-color: #f8fafc;
  transform: translateX(4px);
}

.list-group-item.active {
  background-color: #ebf4ff;
  color: #1a56db;
  border-color: transparent;
}

.video-info {
  background: #fff;
  border-bottom: 1px solid #ebeef5;
  padding: 1.5rem;
}

.video-info h4 {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.video-info p {
  color: #64748b;
  line-height: 1.6;
}

.course-materials {
  background: #fff;
  border-bottom: 1px solid #ebeef5;
  padding: 1.5rem;
}

.course-materials h5 {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 1rem;
}

.course-materials .list-group-item {
  display: flex;
  align-items: center;
  color: #4a5568;
  text-decoration: none;
  padding: 0.75rem 1rem;
}

.course-materials .list-group-item:hover {
  background-color: #f8fafc;
  color: #1a56db;
}

.course-materials .list-group-item i {
  color: #4a5568;
  margin-right: 0.75rem;
  font-size: 1.1rem;
}

.badge {
  padding: 0.35rem 0.65rem;
  font-weight: 500;
  font-size: 0.75rem;
}

.badge.bg-success {
  background-color: #0d9488 !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.loading-overlay .spinner-border {
  width: 3rem;
  height: 3rem;
  color: #1a56db !important;
}

/* 自定义滚动条样式 */
.course-chapters::-webkit-scrollbar {
  width: 6px;
}

.course-chapters::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.course-chapters::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.course-chapters::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .course-chapters {
    height: auto;
    max-height: 400px;
    border-left: none;
    border-top: 1px solid #ebeef5;
  }

  .video-container {
    aspect-ratio: 16/9;
  }

  .chapters-container {
    padding: 1rem;
  }

  .video-info,
  .course-materials {
    padding: 1rem;
  }
}

/* 动画效果 */
.list-group-item {
  transition: all 0.3s ease;
}

.accordion-button::after {
  transition: all 0.3s ease;
}

.badge {
  transition: all 0.3s ease;
}

/* 视频控制器样式优化 */
.video-player-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>