import request from '@/utils/axios'

// 更新视频观看进度
export function updateWatchProgress(data) {
  return request({
    url: '/api/learning/watch-records',
    method: 'post',
    data
  })
}

// 获取视频观看记录
export function getWatchRecord(studentId, courseId, videoId) {
  return request({
    url: `/api/learning/watch-records`,
    method: 'get',
    params: { studentId, courseId, videoId }
  })
}

// 保存视频笔记
export function saveVideoNote(data) {
  return request({
    url: '/api/learning/notes',
    method: 'post',
    data
  })
}

// 获取视频笔记列表
export function getVideoNotes(studentId, videoId) {
  return request({
    url: '/api/learning/notes',
    method: 'get',
    params: { studentId, videoId }
  })
}

// 删除视频笔记
export function deleteVideoNote(noteId) {
  return request({
    url: `/api/learning/notes/${noteId}`,
    method: 'delete'
  })
}

// 获取学习统计数据
export function getLearningStats(studentId) {
  return request({
    url: `/api/learning/students/${studentId}/statistics`,
    method: 'get'
  })
}

// 获取学习提醒设置
export function getLearningReminders(studentId) {
  return request({
    url: `/api/learning/students/${studentId}/reminders`,
    method: 'get'
  })
}

// 更新学习提醒设置
export function updateLearningReminders(studentId, data) {
  return request({
    url: `/api/learning/students/${studentId}/reminders`,
    method: 'put',
    data
  })
}

// 获取学生学习进度
export function getStudentProgress(studentId) {
  return request({
    url: `/api/learning/students/${studentId}/progress`,
    method: 'get'
  })
}

// 获取所有课程统计
export function getAllCourseStatistics(studentId) {
  return request({
    url: `/api/learning/students/${studentId}/course-statistics`,
    method: 'get'
  })
}

// 获取今日观看记录
export function getTodayWatchRecords(studentId) {
  return request({
    url: `/api/learning/students/${studentId}/today-records`,
    method: 'get'
  })
}

// 导出相关功能
export function exportLearningProgress(studentId) {
  return request({
    url: `/api/learning/export/progress/${studentId}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function exportStatistics(studentId) {
  return request({
    url: `/api/learning/export/statistics/${studentId}`,
    method: 'get',
    responseType: 'blob'
  })
}

export function exportNotes(studentId) {
  return request({
    url: `/api/learning/export/notes/${studentId}`,
    method: 'get',
    responseType: 'blob'
  })
}

// 下载文件的辅助函数
export function downloadFile(blob, filename) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
} 