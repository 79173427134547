import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/main.css'
import { createMetaManager } from 'vue-meta-info';
import store from './store' // 确保路径正确

const app = createApp(App)

// 添加路由调试信息
router.beforeEach((to, from, next) => {
  // console.log('路由变化:', {
  //   from: from.path,
  //   to: to.path,
  //   meta: to.meta
  // })
  next()
})

// 路由变化时更新文档标题
router.afterEach((to) => {
  // 根据路由元数据设置文档标题
  document.title = to.meta.title ? `${to.meta.title} - 在线学习中心` : '在线学习中心'
  
  // 记录路由变化
  // console.log('路由变化:', {
  //   from: from.fullPath,
  //   to: to.fullPath,
  //   title: document.title
  // })
})

app.use(router)
app.use(ElementPlus)
app.use(createMetaManager)
app.use(store) // 安装 Vuex store
app.mount('#app') 