<template>
  <div class="news-detail">
    <!-- Loading 状态 -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">加载中...</span>
      </div>
    </div>

    <page-header title="新闻详情"></page-header>

    <div class="container py-5">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <!-- 错误提示 -->
          <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>

          <!-- 新闻内容 -->
          <div v-if="news" class="news-content card">
            <div class="card-body">
              <h1 class="news-title mb-4">{{ news.title }}</h1>
              
              <div class="meta text-muted mb-4">
                <span><i class="far fa-calendar-alt me-1"></i>{{ formatDate(news.createdAt) }}</span>
                <span class="mx-3">|</span>
                <span><i class="far fa-user me-1"></i>{{ news.author }}</span>
              </div>

              <!-- 新闻图片 -->
              <div v-if="news.image" class="news-image mb-4">
                <img :src="news.image" :alt="news.title" class="img-fluid rounded" @error="handleImageError">
              </div>

              <!-- 新闻内容 -->
              <div class="content" v-html="news.content"></div>

              <!-- 返回按钮 -->
              <div class="mt-5 text-center">
                <router-link to="/news" class="btn btn-primary">
                  <i class="fas fa-arrow-left me-2"></i>返回新闻列表
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import request from '@/utils/axios'
import { formatDate } from '@/utils/date'
import PageHeader from '@/components/PageHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'NewsDetail',
  components: {
    PageHeader,
    SiteFooter
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const news = ref(null)
    const loading = ref(false)
    const error = ref(null)

    const fetchNewsDetail = async () => {
      loading.value = true
      error.value = null
      try {
        const response = await request({
          url: `/api/public/news/get/${route.params.id}`,
          method: 'get'
        })
        // 处理图片URL
        if (response.data.image) {
          response.data.image = `${process.env.VUE_APP_API_URL}/api${response.data.image}`
        }
        news.value = response.data
      } catch (err) {
        console.error('获取新闻详情失败:', err)
        error.value = '获取新闻详情失败，请稍后重试'
        // 如果是404错误，跳转到新闻列表页
        if (err.response?.status === 404) {
          router.push('/news')
        }
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchNewsDetail()
    })

    const handleImageError = () => {
     // console.error('图片加载失败:', e.target.src)
    //  e.target.src = `${process.env.VUE_APP_API_URL}/api/static/images/news-default.jpg`

      return "";
    }

    return {
      news,
      loading,
      error,
      formatDate,
      handleImageError
    }
  }
}
</script>

<style scoped>
.news-detail {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.news-content {
  background: white;
  border: none;
  box-shadow: 0 2px 15px rgba(0,0,0,0.08);
}

.news-title {
  font-size: 2rem;
  font-weight: 600;
  color: #2c3e50;
  line-height: 1.4;
}

.meta {
  font-size: 0.95rem;
}

.news-image {
  border-radius: 8px;
  overflow: hidden;
}

.news-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #2c3e50;
}

.btn-primary {
  padding: 0.8rem 2rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .news-title {
    font-size: 1.5rem;
  }

  .content {
    font-size: 1rem;
  }
}
</style> 