/**
 * 图片加载优化工具
 * 提供图片优化处理、懒加载支持和错误处理功能
 */

// 默认占位图片URL
const defaultPlaceholder = '/assets/images/course-placeholder.jpg'

/**
 * 获取优化的图片URL
 * @param {string} url - 原始图片URL
 * @returns {string} 处理后的URL
 */
export const getOptimizedImageUrl = (url) => {
  if (!url) return defaultPlaceholder
  
  // 如果是外部URL，直接返回
  if (url.startsWith('http') || url.startsWith('https')) {
    return url
  }
  
  // 如果是相对URL，添加基础路径
  if (url.startsWith('/')) {
    return process.env.VUE_APP_API_URL + url
  }
  
  return url
}

/**
 * 预加载图片
 * @param {string} url - 图片URL
 * @returns {Promise} 加载Promise
 */
export const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(url)
    img.onerror = () => reject(new Error('图片加载失败'))
    img.src = url
  })
}

/**
 * 批量处理课程列表的图片
 * @param {Array} courses - 课程列表
 * @returns {Array} 处理后的课程列表
 */
export const processCourseImages = (courses) => {
  if (!courses || !Array.isArray(courses)) return []
  
  return courses.map(course => ({
    ...course,
    coverImageUrl: course.coverImageUrl ? 
      getOptimizedImageUrl(course.coverImageUrl) : 
      defaultPlaceholder
  }))
}

export default {
  getOptimizedImageUrl,
  preloadImage,
  processCourseImages,
  defaultPlaceholder
} 