import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    user: null,
    isAuthenticated: false,
    courses: [],
    enrolledCourses: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      state.isAuthenticated = !!user
    },
    setCourses(state, courses) {
      state.courses = courses
    },
    setEnrolledCourses(state, courses) {
      state.enrolledCourses = courses
    },
    deleteCourse(state, id) {
      state.courses = state.courses.filter(course => course.id !== id)
    }
  },
  actions: {
    async register(_, userData) {
      try {
        const response = await axios.post('/api/auth/register', userData)
        return response.data
      } catch (error) {
        console.error('注册失败:', error)
        throw error
      }
    },
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/api/auth/login', credentials)
        commit('setUser', response.data.user)
        return response.data
      } catch (error) {
        console.error('登录失败:', error)
        throw error
      }
    },
    async logout({ commit }) {
      try {
        await axios.post('/api/auth/logout')
        commit('setUser', null)
      } catch (error) {
        console.error('登出失败:', error)
        throw error
      }
    },
    async fetchCourses({ commit }) {
      try {
        const response = await axios.get('/api/courses')
        commit('setCourses', response.data)
      } catch (error) {
        console.error('获取课程列表失败:', error)
        throw error
      }
    },
    async enrollCourse({ commit }, courseId) {
      try {
        await axios.post(`/api/courses/${courseId}/enroll`)
        // 重新获取已报名课程列表
        const response = await axios.get('/api/student/courses')
        commit('setEnrolledCourses', response.data)
      } catch (error) {
        console.error('课程报名失败:', error)
        throw error
      }
    },
    async deleteCourse({ commit }, id) {
      // 发送请求
      const response = await axios.delete(`/api/manage/courses/${id}`)
      
      if (response.data) {
        // 从状态中删除
        commit('deleteCourse', id)
        return true
      }
      
      return false
    }
  },
  getters: {
    currentUser: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    getCourseById: state => id => {
      return state.courses.find(course => course.id === id)
    }
  }
}) 