<template>
  <div class="teacher-manage">
    <!-- 顶部操作栏 -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4 class="mb-0">教师管理</h4>
      <div class="d-flex gap-3">
        <div class="input-group" style="width: 300px;">
          <input 
            type="text" 
            class="form-control" 
            placeholder="搜索教师姓名或专业..." 
            v-model="searchQuery"
            @input="handleSearch"
          >
          <button class="btn btn-outline-secondary" type="button">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <button class="btn btn-primary" @click="handleAdd">
          <i class="fas fa-plus me-1"></i>添加教师
        </button>
      </div>
    </div>

    <!-- 教师列表 -->
    <div class="card">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead class="table-light">
            <tr>
              <th>ID</th>
              <th>姓名</th>
              <th>性别</th>
              <th>专业</th>
              <th>职称</th>
              <th>联系电话</th>
              <th>邮箱</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="teacher in teachers" :key="teacher.id">
              <td>{{ teacher.id }}</td>
              <td>{{ teacher.name }}</td>
              <td>{{ teacher.gender }}</td>
              <td>{{ teacher.specialty }}</td>
              <td>{{ teacher.title }}</td>
              <td>{{ teacher.phone }}</td>
              <td>{{ teacher.email }}</td>
              <td>
                <span 
                  class="badge" 
                  :class="teacher.status === 'ACTIVE' ? 'bg-success' : 'bg-secondary'"
                >
                  {{ teacher.status === 'ACTIVE' ? '在职' : '离职' }}
                </span>
              </td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-sm btn-outline-primary" @click="handleEdit(teacher)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-danger" @click="handleDelete(teacher)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 分页 -->
    <div class="d-flex justify-content-between align-items-center mt-4">
      <div class="text-muted">
        共 {{ total }} 条记录
      </div>
      <nav>
        <ul class="pagination mb-0">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage - 1)">
              <i class="fas fa-chevron-left"></i>
            </a>
          </li>
          <li 
            v-for="page in totalPages" 
            :key="page" 
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage + 1)">
              <i class="fas fa-chevron-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <!-- 添加/编辑教师对话框 -->
    <div class="modal fade" id="teacherModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEdit ? '编辑教师' : '添加教师' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSubmit">
              <div class="mb-3">
                <label class="form-label">姓名</label>
                <input type="text" class="form-control" v-model="form.name" required>
              </div>
              <div class="mb-3">
                <label class="form-label">性别</label>
                <select class="form-select" v-model="form.gender" required>
                  <option value="男">男</option>
                  <option value="女">女</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">专业</label>
                <input type="text" class="form-control" v-model="form.specialty" required>
              </div>
              <div class="mb-3">
                <label class="form-label">职称</label>
                <select class="form-select" v-model="form.title" required>
                  <option value="讲师">讲师</option>
                  <option value="副教授">副教授</option>
                  <option value="教授">教授</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">联系电话</label>
                <input type="tel" class="form-control" v-model="form.phone" required>
              </div>
              <div class="mb-3">
                <label class="form-label">邮箱</label>
                <input type="email" class="form-control" v-model="form.email" required>
              </div>
              <div class="mb-3">
                <label class="form-label">状态</label>
                <select class="form-select" v-model="form.status" required>
                  <option value="ACTIVE">在职</option>
                  <option value="INACTIVE">离职</option>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" @click="handleSubmit">确定</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除确认对话框 -->
    <div class="modal fade" id="deleteModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">确认删除</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            确定要删除教师 <strong>{{ selectedTeacher?.name }}</strong> 吗？此操作不可恢复。
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">确定删除</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { Modal } from 'bootstrap'
import { ElMessage } from 'element-plus'
import { getTeacherList, createTeacher, updateTeacher, deleteTeacher } from '@/api/teacher'

export default {
  name: 'TeacherManage',
  setup() {
    // 状态定义
    const teachers = ref([])
    const total = ref(0)
    const currentPage = ref(1)
    const pageSize = ref(10)
    const searchQuery = ref('')
    const isEdit = ref(false)
    const selectedTeacher = ref(null)
    const teacherModal = ref(null)
    const deleteModal = ref(null)
    const form = ref({
      name: '',
      gender: '男',
      specialty: '',
      title: '讲师',
      phone: '',
      email: '',
      status: 'ACTIVE'
    })

    // 计算总页数
    const totalPages = ref(0)

    // 初始化模态框
    onMounted(() => {
      teacherModal.value = new Modal(document.getElementById('teacherModal'))
      deleteModal.value = new Modal(document.getElementById('deleteModal'))
      loadTeachers()
    })

    // 加载教师列表
    const loadTeachers = async () => {
      try {
        const params = {
          page: currentPage.value - 1,
          size: pageSize.value,
          search: searchQuery.value
        }
        const response = await getTeacherList(params)
        teachers.value = response.data.content
        total.value = response.data.totalElements
        totalPages.value = response.data.totalPages
      } catch (error) {
        console.error('加载教师列表失败:', error)
        ElMessage.error('加载教师列表失败')
      }
    }

    // 搜索处理
    const handleSearch = () => {
      currentPage.value = 1
      loadTeachers()
    }

    // 添加教师
    const handleAdd = () => {
      isEdit.value = false
      form.value = {
        name: '',
        gender: '男',
        specialty: '',
        title: '讲师',
        phone: '',
        email: '',
        status: 'ACTIVE'
      }
      teacherModal.value.show()
    }

    // 编辑教师
    const handleEdit = (teacher) => {
      isEdit.value = true
      selectedTeacher.value = teacher
      form.value = { ...teacher }
      teacherModal.value.show()
    }

    // 删除教师
    const handleDelete = (teacher) => {
      selectedTeacher.value = teacher
      deleteModal.value.show()
    }

    // 确认删除
    const confirmDelete = async () => {
      try {
        await deleteTeacher(selectedTeacher.value.id)
        deleteModal.value.hide()
        ElMessage.success('删除成功')
        loadTeachers()
      } catch (error) {
        console.error('删除教师失败:', error)
        ElMessage.error('删除教师失败')
      }
    }

    // 提交表单
    const handleSubmit = async () => {
      try {
        if (isEdit.value) {
          await updateTeacher(selectedTeacher.value.id, form.value)
          ElMessage.success('更新成功')
        } else {
          await createTeacher(form.value)
          ElMessage.success('添加成功')
        }
        teacherModal.value.hide()
        loadTeachers()
      } catch (error) {
        console.error('保存教师失败:', error)
        ElMessage.error('保存教师失败')
      }
    }

    // 页码变化
    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page
        loadTeachers()
      }
    }

    return {
      teachers,
      total,
      currentPage,
      totalPages,
      searchQuery,
      isEdit,
      form,
      selectedTeacher,
      handleSearch,
      handleAdd,
      handleEdit,
      handleDelete,
      handleSubmit,
      confirmDelete,
      handlePageChange
    }
  }
}
</script>

<style scoped>
.teacher-manage {
  height: 100%;
}

.table th {
  white-space: nowrap;
}

.btn-group {
  gap: 0.5rem;
}

.pagination {
  margin-bottom: 0;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
</style> 