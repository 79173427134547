import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';





// 创建 axios 实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
  // 1. 获取 token
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('userRole');
  const userInfo = localStorage.getItem('userInfo');

  // 打印请求信息
  console.log('发送请求:', {
    url: config.url,
    method: config.method,
    headers: config.headers,
    data: config.data,
    baseURL: config.baseURL
  });
  // 打印用户信息
  console.log('当前用户信息:', {
    token: token ? token.substring(0, 20) + '...' : '未设置',
    role: userRole || '未设置',
    userInfo: userInfo ? JSON.parse(userInfo) : null
  });

  // 2. 定义公共路径
  const publicPaths = [
    '/api/public/courses/list',
    '/api/public/courses/get',
    '/api/public/news/latest',
    '/api/public/news/get',
    '/api/public/news',
    '/api/public/categories',
    '/api/public/students/successful',
    '/api/public/partners',
    '/api/auth/login',
    '/api/auth/register'
  ];

  // 3. 定义管理路径
  const adminPaths = [
    '/api/admin/',
    '/api/manage/',
    '/api/upload/',
    '/admin/'
  ];

  // 4. 检查是否是公共路径
  const isPublicPath = (config.method.toUpperCase() === "GET" || config.method.toUpperCase() === "OPTIONS") &&
      publicPaths.some(path => config.url.startsWith(path)) &&
      !adminPaths.some(path => config.url.startsWith(path));

  // 5. 检查是否是后台管理路径
  const isAdminPath = adminPaths.some(path => config.url.startsWith(path)) || config.url.includes('/dashboard');

  // 6. 打印请求路径检查
  console.log('请求路径检查:', {
    url: config.url,
    method: config.method.toUpperCase(),
    fullUrl: config.baseURL + config.url,
    isPublicPath,
    isAdminPath,
    hasToken: !!token
  });

  // 7. 如果有 token，添加到所有非公共路径的请求中
  if (token && !isPublicPath) {
    config.headers['Authorization'] = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    console.log('设置请求头 Authorization:', config.headers['Authorization']?.substring(0, 20) + '...');
  }

  // 8. 只有访问后台管理接口时才需要验证token
  if (isAdminPath && !token) {
    console.error('访问后台需要认证:', {
      url: config.url,
      method: config.method,
      token: token ? '已设置' : '未设置',
      headers: config.headers
    });
    return Promise.reject(new Error('未登录或登录已过期'));
  }

  return config;
},
error => {
  console.error('请求错误:', error);
  ElMessage.error('请求发送失败');
  return Promise.reject(error);
}
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
  console.log('收到响应:', {
    url: response.config.url,
    status: response.status,
    headers: response.headers,
    data: response.data,
    authorization: response.headers['authorization'],
    newToken: response.headers['new-token']
  })

  // 检查响应中是否包含新的 token
  const newToken = response.headers['new-token'];
if (newToken) {
  const tokenWithBearer = newToken.startsWith('Bearer ') ? newToken : `Bearer ${newToken}`;
  localStorage.setItem('token', tokenWithBearer);
  console.log('更新 token:', {
    oldToken: localStorage.getItem('token')?.substring(0, 20) + '...',
      newToken: tokenWithBearer.substring(0, 20) + '...'
})
}

return response;
},
error => {
  console.error('响应错误:', {
    message: error.message,
    config: error.config,
    response: error.response ? {
      status: error.response.status,
      data: error.response.data,
      headers: error.response.headers
    } : null
  })

  if (error.response) {
    const { status, data,headers  } = error.response;
    let currentPath = router.currentRoute.value.fullPath;

    // **Check for Token-Expired header**
    if (headers['token-expired'] === 'true') {
      // Token is expired, perform logout
      localStorage.removeItem('token');
      localStorage.removeItem('userRole');
      localStorage.removeItem('userInfo');

      ElMessage.warning('您的登录已过期，请重新登录');
      router.push({ path: '/auth/login' });  // Replace with your login route

      return Promise.reject(error); // Prevent further request handling
    }

    // 2. 定义公共路径
    const publicPaths = [
      '/api/public/courses/list',
      '/api/public/courses/get', // 确保这里是 get
      '/api/auth/login',
      '/api/auth/register',
      '/api/news/',
      '/api/news/latest',
      '/files/',
      '/api/students/successful',
      '/api/partners'
    ];

    // 3. 定义管理路径
    const adminPaths = [
      '/api/admin/',
      '/api/manage/',
      '/api/upload/' // 根据你的实际情况修改
    ];

    // 4. 检查是否是公共路径
    // isPublicPath 更精细的判断逻辑
    // 只有当路径匹配 publicPaths 列表中的某个前缀，且不是 adminPaths 中定义的管理路径，
    // 且请求方法是 GET 或 OPTIONS 时，才认为是公共路径
    const isPublicPath = (error.config.method.toUpperCase() === "GET" || error.config.method.toUpperCase() === "OPTIONS") &&
        publicPaths.some(path => error.config.url.startsWith(path)) &&
    !adminPaths.some(path => error.config.url.startsWith(path));

    // 5. 检查是否是后台管理路径 (包含 /dashboard 的判断)
    const isAdminPath = adminPaths.some(path => error.config.url.startsWith(path)) || error.config.url.includes('/dashboard');

    switch (status) {
      case 400:
        ElMessage.error(data?.message || '请求参数错误');
        break;
      case 401:
        // 如果是公开路径，不处理 401 错误，让请求继续
        if (isPublicPath) {
          console.log('公开路径 401 错误，继续请求:', error.config.url);
          return Promise.resolve();
        }

        // 如果不是后台管理路径,只显示错误信息,不重定向
        if (!isAdminPath) {
          ElMessage.error(data?.message || '获取数据失败');
          break;
        }

        console.log('收到 401 响应，当前状态:', {
          path: currentPath,
          token: localStorage.getItem('token')?.substring(0, 20) + '...',
          userRole: localStorage.getItem('userRole'),
          userInfo: localStorage.getItem('userInfo')
    })
    // 清除所有存储的信息
    localStorage.clear();
    ElMessage.error('登录已过期，请重新登录');
    // 跳转到登录页面，并记录当前路由
    if (currentPath !== '/auth/login') {
      console.log('重定向到登录页面:', {
        from: currentPath,
        reason: '401 未授权'
      })
      router.push({
        path: '/auth/login',
        query: { redirect: currentPath }
      })
    }
    break;
  case 403:
    console.log('没有权限访问该资源');
    //ElMessage.error(data?.message || '没有权限访问该资源');
    break;
  case 404:
    console.log('请求的资源不存在');
   // ElMessage.error(data?.message || '请求的资源不存在');
    break;
  case 500:
    console.log('服务器内部错误');
   // ElMessage.error(data?.message || '服务器内部错误');
    break;
  default:
    console.log('未知错误');
   // ElMessage.error(data?.message || '未知错误');
  }
  } else if (error.request) {
    console.error('请求未收到响应:', error.request);
    ElMessage.error('网络连接失败，请检查网络设置');
  } else {
    console.error('请求配置错误:', error.message);
    ElMessage.error(error.message || '请求发送失败');
  }
  return Promise.reject(error);
}
)

export default instance