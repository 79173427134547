<template>
    <div class="course-detail">
        <!-- 加载状态 -->
        <div v-if="loading" class="loading-overlay">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">加载中...</span>
            </div>
        </div>

        <!-- 错误提示 -->
        <div v-if="error" class="alert alert-danger mx-3 mt-3" role="alert">
            {{ error }}
            <button type="button" class="btn-close float-end" @click="error = null"></button>
        </div>

        <div class="course-container" v-if="!loading && !error">
            <div class="row g-0">
                <!-- 中间播放区域 - 扩大宽度 -->
                <div class="col-md-9 video-section">
                    <div class="video-wrapper">
                        <div class="video-container">
                            <video-player
                                    v-if="currentVideo.url"
                                    ref="videoPlayer"
                                    :source="currentVideo.url"
                                    :poster="course.coverImageUrl"
                                    :autoplay="false"
                                    :course-id="course.id"
                                    :video-id="currentVideo.id"
                                    :requires-login="course.requiresLogin"
                                    :controls="true"
                                    :disable-seeking="true"
                                    :options="{
                  controlBar: {
                    playToggle: true,
                    progressControl: true,
                    volumePanel: true,
                    fullscreenToggle: true
                  }
                }"
                                    @timeupdate="handleTimeUpdate"
                                    @ended="handleVideoEnd"
                                    @error="handleVideoError"
                                    @progress-updated="handleProgressUpdate"
                                    @ready="handleVideoReady"
                            />
                            <div v-else class="video-placeholder">
                                <div class="placeholder-content">
                                    <i class="el-icon-video-play"></i>
                                    <p>{{ isLoggedIn ? '请选择要播放的课时' : '请登录后观看完整课程' }}</p>
                                    <el-button v-if="!isLoggedIn" type="primary" @click="$router.push('/auth/login')">
                                        立即登录
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 当前播放信息 -->
                    <div class="current-lesson-info" v-if="currentVideo.title">
                        <h3>{{ currentVideo.title }}</h3>
                        <p>{{ currentVideo.description }}</p>
                    </div>

                    <!-- 课程详细信息标签页 -->
                    <div class="course-tabs">
                        <el-tabs v-model="activeTab">
                            <el-tab-pane label="课程介绍" name="intro">
                                <!-- 添加课程基本信息 -->
                                <div class="course-intro-header">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="course-cover">
                                                <img :src="course.coverImageUrl" :alt="course.name">
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <h1 class="course-title">{{ course.name }}</h1>
                                            <div class="course-meta">
                                                <span><i class="el-icon-reading"></i> {{ course.level }}</span>
                                                <span><i class="el-icon-time"></i> {{ totalLessons }} 课时</span>
                                                <span><i class="el-icon-user"></i> {{ course.studentCount || 0 }}人学习</span>
                                            </div>
                                            <div class="course-price">
                                                <span class="currency">¥</span>
                                                <span class="amount">{{ course.price }}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <!-- 课程描述 -->
                                <div class="course-description" v-html="course.description"></div>

                                <!-- 讲师信息 -->
                                <div class="teacher-info">
                                    <h3>主讲教师</h3>
                                    <div class="row">
                                        <div v-for="teacher in course.teachers" :key="teacher.id" class="col-md-4">
                                            <div class="teacher-card">
                                                <img :src="teacher.avatar" :alt="teacher.name" class="teacher-avatar">
                                                <div class="teacher-detail">
                                                    <h4>{{ teacher.name }}</h4>
                                                    <p>{{ teacher.title }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <!--  <el-tab-pane label="课程大纲" name="outline">
                                <div class="course-syllabus" v-html="course.syllabus"></div>
                              </el-tab-pane>-->
                            <!--   <el-tab-pane label="课程问答" name="qa">
                                 <div class="course-qa" v-if="course.id">
                                   <!– 问答区域组件 –>
                                   <course-qa :course-id="course.id" />
                                 </div>
                               </el-tab-pane>-->
                        </el-tabs>
                    </div>
                </div>

                <!-- 右侧课程大纲 -->
                <div class="col-md-3 course-chapters">
                    <div class="chapters-container">
                        <h3>课程大纲</h3>
                        <el-collapse v-model="activeChapters">
                            <el-collapse-item v-for="chapter in chapters" :key="chapter.id" :name="chapter.id">
                                <template #title>
                                    <div class="chapter-title">
                                        <span>{{ chapter.title }}</span>
                                        <small class="text-muted">{{ chapter.lessons.length }}课时</small>
                                    </div>
                                </template>
                                <div class="lesson-list">
                                    <div
                                            v-for="lesson in chapter.lessons"
                                            :key="lesson.id"
                                            class="lesson-item"
                                            :class="{
                      'active': currentVideo.id === lesson.id,
                      'is-preview': lesson.isPreview,
                      'is-public': lesson.isPublic
                    }"
                                            @click="handleLessonClick(lesson)"
                                    >
                                        <div class="lesson-info">
                                            <i class="el-icon-video-play"></i>
                                            <span class="lesson-title">{{ lesson.title }}</span>
                                            <span class="lesson-duration">{{ lesson.duration }}秒</span>
                                        </div>
                                        <div class="lesson-status">
                                            <el-tag v-if="lesson.isPreview || lesson.isPublic" size="small" type="success">
                                                {{ lesson.isPreview ? '试看' : '公开' }}
                                            </el-tag>
                                            <i v-else class="el-icon-lock"></i>
                                            <span v-if="lessonProgress[lesson.id]" class="progress-tag">
                        <el-tag
                                size="small"
                                :type="lessonProgress[lesson.id].isCompleted ? 'success' : 'warning'"
                        >
                          {{ lessonProgress[lesson.id].isCompleted ? '已完成' : `${Math.floor(lessonProgress[lesson.id].progress)}%` }}
                        </el-tag>
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>

                    <!-- 相关课程推荐 -->
                    <!--  <div class="related-courses" v-if="course.id">
                        <h3>相关课程推荐</h3>
                        <div class="course-list">
                          <!– 相关课程组件 –>
                          <related-courses :current-course-id="course.id" />
                        </div>
                      </div>-->

                </div>
            </div>
        </div>

        <!-- 添加页脚 -->
        <site-footer />
    </div>
</template>

<script>
    import { ref, onMounted,provide,computed, watch, nextTick } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { ElMessage } from 'element-plus'
    import VideoPlayer from '@/components/video/VideoPlayer.vue'
    import SiteFooter from '@/components/SiteFooter.vue'
    import axios from '@/utils/axios'
    import store from '@/store'

    export default {
        name: 'CourseDetail',
        components: {
            VideoPlayer,
            SiteFooter
        },
        setup() {
            const router = useRouter()
            const route = useRoute()
            const loading = ref(false)
            const error = ref(null)
            const activeTab = ref('intro')
            const activeChapters = ref([])
            const videoPlayer = ref(null)
            const isLoggedIn = ref(false)
            const lessonProgress = ref({})
            const userInfo = ref(null)
            const isPurchased = ref(false)
            const purchasing = ref(false)
            const enrolling = ref(false)

            // 课程数据
            const course = ref({
                id: null,
                name: '',
                description: '',
                level: '',
                duration: 0,
                durationUnit: '',
                price: 0,

                coverImageUrl: '',
                teachers: [],
                studentCount: 0,
                requiresLogin: false // 确保 course 对象包含 requiresLogin 属性，并设置默认值
            })

            // 章节数据
            const chapters = ref([])

            // 当前播放的视频
            const currentVideo = ref({
                id: null,
                title: '',
                url: '',
                description: ''
            })

            // 检查登录状态
            const checkLoginStatus = () => {
                const token = localStorage.getItem('token')
                isLoggedIn.value = !!token
                if (token) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                }
            }
            // 在 setup 中 provide isLoggedIn
            provide('isLoggedIn', isLoggedIn);  //  provide ref 对象

            // 获取用户信息
            const getUserInfo = () => {
                const userInfoStr = localStorage.getItem('userInfo')
                if (userInfoStr) {
                    userInfo.value = JSON.parse(userInfoStr)
                }
            }

            // 检查课程是否已经购买
            const checkPurchased = async (id) => {
                try {
                    if (!isLoggedIn.value) return

                    const result = await store.dispatch('checkCoursePurchased', id)
                    isPurchased.value = result
                } catch (error) {
                    // 处理错误
                    isPurchased.value = false
                }
            }

            // 查找第一个可用的课时
            const findFirstAvailableLesson = () => {
                // 添加调试信息
                console.log('开始查找第一个可用课时，章节数:', chapters.value.length);
                
                // 先检查章节是否已经加载完成
                if (chapters.value.length === 0) {
                    console.log('章节数据为空，无法查找可用课时');
                    return null;
                }
                
                for (const chapter of chapters.value) {
                    console.log('检查章节:', chapter.title, '课时数:', chapter.lessons?.length || 0);
                    
                    if (!chapter.lessons || chapter.lessons.length === 0) {
                        continue;
                    }
                    
                    for (const lesson of chapter.lessons) {
                        console.log('检查课时:', lesson.title, 
                            '是试看:', lesson.isPreview, 
                            '是公开:', lesson.isPublic, 
                            '需要登录:', course.value.requiresLogin,
                            '已登录:', isLoggedIn.value);
                            
                        // 逻辑优化：如果课时是试看或公开的，或者用户已登录，或者课程不需要登录
                        if (lesson.isPreview || 
                            lesson.isPublic || 
                            isLoggedIn.value || 
                            !course.value.requiresLogin) {
                            
                            // 检查是否有视频URL
                            if (lesson.videoUrl) {
                                console.log('找到合适的课时:', lesson.title);
                                return lesson;
                            } else {
                                console.log('课时没有视频URL:', lesson.title);
                            }
                        }
                    }
                }
                
                console.log('未找到可用课时，默认返回第一个课时');
                // 如果没有找到符合条件的课时，返回第一章节的第一个课时（如果存在）
                if (chapters.value.length > 0 && 
                    chapters.value[0].lessons && 
                    chapters.value[0].lessons.length > 0) {
                    return chapters.value[0].lessons[0];
                }
                
                return null;
            }

            // 设置默认视频并自动播放
            const setDefaultVideoAndPlay = () => {
                console.log('开始设置默认视频');
                const firstLesson = findFirstAvailableLesson()
                if (firstLesson) {
                    console.log('设置默认视频:', firstLesson.title);
                    currentVideo.value = {
                        id: firstLesson.id,
                        title: firstLesson.title,
                        url: firstLesson.videoUrl,
                        description: firstLesson.description
                    }
                    
                    // 使用nextTick确保视频组件已经渲染
                    nextTick(() => {
                        if (videoPlayer.value) {
                            console.log('准备自动播放视频');
                            setTimeout(() => {
                                try {
                                    videoPlayer.value.play();
                                    console.log('视频开始自动播放');
                                } catch (err) {
                                    console.error('自动播放失败:', err);
                                }
                            }, 500);
                        }
                    });
                } else {
                    console.log('未找到可播放的默认视频');
                }
            }

            // 修改课程数据加载函数
            const fetchCourseData = async () => {
                loading.value = true
                error.value = null
                try {
                    const courseId = route.params.id
                    console.log('开始加载课程数据, 课程ID:', courseId);
                    
                    const response = await axios.get(`/api/public/courses/get/${courseId}`)
                    const courseData = response.data
                    console.log('课程基本信息加载成功:', courseData.name);

                    // 设置课程基本信息
                    course.value = {
                        id: courseData.id,
                        syllabus:courseData.syllabus||'',
                        name: courseData.name || '',
                        description: courseData.description || '',
                        level: formatLevel(courseData.level) || '',
                        duration: courseData.duration || 0,
                        durationUnit: formatDurationUnit(courseData.durationUnit) || '',
                        price: courseData.price || 0,
                        coverImageUrl: courseData.coverImageUrl || '/img/default-course.jpg',
                        teachers: courseData.teachers || [],
                        requiresLogin: courseData.requiresLogin || false,
                        studentCount: courseData.studentCount || 0
                    }

                    // 加载课程章节
                    console.log('开始加载课程章节');
                    await loadCourseChapters(courseData.id)
                    console.log('章节数据加载完成, 章节数:', chapters.value.length);

                    // 检查是否已购买
                    if (isLoggedIn.value) {
                        await checkPurchased(courseData.id)
                    }

                    document.title = courseData.name + ' - 课程详情'

                    // 设置默认视频并自动播放
                    setDefaultVideoAndPlay();
                    
                } catch (error) {
                    console.error('获取课程信息失败:', error);
                    ElMessage.error('获取课程信息失败')
                } finally {
                    loading.value = false
                }
            }

            // 加载课程章节
            const loadCourseChapters = async (courseId) => {
                try {
                    const response = await axios.get(`/api/public/courses/${courseId}/chapters`)
                    chapters.value = response.data || []
                    
                    // 如果章节加载成功，自动展开第一个章节
                    if (chapters.value.length > 0) {
                        activeChapters.value = [chapters.value[0].id]
                        console.log('自动展开第一个章节:', chapters.value[0].title);
                    }
                } catch (error) {
                    console.error('获取课程章节失败:', error);
                    ElMessage.error('获取课程章节失败')
                }
            }

            // 处理课时点击
            const handleLessonClick = (lesson) => {
                if (!lesson.videoUrl) {
                    ElMessage.warning('该课时暂无视频')
                    return
                }

                if (lesson.isPublic || lesson.isPreview || isLoggedIn.value || !course.value.requiresLogin) {
                    currentVideo.value = {
                        id: lesson.id,
                        title: lesson.title,
                        url: lesson.videoUrl,
                        description: lesson.description
                    }
                    // 恢复上次播放进度
                    if (course.value.requiresLogin && isLoggedIn.value) {  // 只有需要登录且用户已登录才恢复
                        const lastPosition = getLastPosition(lesson.id)
                        if (lastPosition && videoPlayer.value) {
                            setTimeout(() => {
                                videoPlayer.value.currentTime = lastPosition
                            }, 100)
                        }
                    }
                } else {
                    ElMessage.warning('请登录后观看完整课程内容')
                }
            }

            // 处理视频播放进度更新
            // 处理视频播放进度更新
            const handleTimeUpdate = (event) => {
                // 如果课程需要登录且用户未登录，则不保存播放进度
                if (course.value.requiresLogin && !isLoggedIn.value) return;

                const videoId = currentVideo.value.id
                if (videoId) {
                    localStorage.setItem(`video_progress_${videoId}`, event.target.currentTime)
                }
            }

            // 获取上次播放进度
            const getLastPosition = (videoId) => {
                return parseFloat(localStorage.getItem(`video_progress_${videoId}`)) || 0
            }

            // 处理视频播放结束
            const handleVideoEnd = () => {
                // 可以添加播放完成的逻辑，比如自动播放下一课时
                const nextLesson = findNextLesson()
                if (nextLesson) {
                    handleLessonClick(nextLesson)
                }
            }

            // 查找下一个课时
            const findNextLesson = () => {
                let foundCurrent = false
                for (const chapter of chapters.value) {
                    for (const lesson of chapter.lessons) {
                        if (foundCurrent) {
                            if (lesson.isPublic || lesson.isPreview || isLoggedIn.value) {
                                return lesson
                            }
                        }
                        if (lesson.id === currentVideo.value.id) {
                            foundCurrent = true
                        }
                    }
                }
                return null
            }

            // 处理视频错误
            const handleVideoError = (error) => {
                console.error('视频播放错误:', error)
                ElMessage.error('视频播放失败，请重试')
            }

            // 处理报名
            const handleEnroll = async () => {
                if (!isLoggedIn.value) {
                    ElMessage.warning('请先登录后再报名课程')
                    router.push('/login')
                    return
                }

                try {
                    const response = await axios.post(`/api/courses/${course.value.id}/enroll`)
                    if (response.data.success) {
                        ElMessage.success('报名成功')
                        router.push('/learning')
                    } else {
                        ElMessage.error(response.data.message || '报名失败')
                    }
                } catch (error) {
                    console.error('报名失败:', error)
                    ElMessage.error('报名失败，请稍后重试')
                }
            }

            // 格式化课程等级
            const formatLevel = (level) => {
                const levelMap = {
                    'BEGINNER': '初级',
                    'INTERMEDIATE': '中级',
                    'ADVANCED': '高级'
                }
                return levelMap[level] || level
            }

            // 格式化时长单位
            const formatDurationUnit = (unit) => {
                const unitMap = {
                    'MINUTE': '分钟',
                    'HOUR': '小时',
                    'DAY': '天',
                    'WEEK': '周',
                    'MONTH': '月'
                }
                return unitMap[unit] || unit
            }

            // 计算总课时数
            const totalLessons = computed(() => {
                let count = 0
                chapters.value.forEach(chapter => {
                    count += chapter.lessons ? chapter.lessons.length : 0
                })
                return count
            })

            // 处理进度更新
            const handleProgressUpdate = (data) => {
                if (!data || !data.lessonId) return;

                // 更新课时进度显示
                lessonProgress.value[data.lessonId] = {
                    isCompleted: data.isCompleted,
                    progress: data.progress,
                    currentTime: data.currentTime
                };
            };

            // 视频播放器就绪处理
            const handleVideoReady = () => {
                console.log('视频播放器就绪');
                // 视频播放器就绪后尝试自动播放
                if (videoPlayer.value && currentVideo.value.url) {
                    setTimeout(() => {
                        try {
                            videoPlayer.value.play();
                            console.log('视频自动播放已触发');
                        } catch (err) {
                            console.error('自动播放失败:', err);
                        }
                    }, 300);
                }
            }

            // 组件挂载时初始化
            onMounted(() => {
                console.log('课程详情页面加载');
                getUserInfo()
                checkLoginStatus()
                fetchCourseData()
                
                // 监听章节数据变化
                watch(chapters, (newChapters) => {
                    if (newChapters.length > 0 && activeChapters.value.length === 0) {
                        activeChapters.value = [newChapters[0].id];
                        console.log('检测到章节数据更新，展开第一个章节');
                    }
                })
            })

            return {
                course,
                chapters,
                currentVideo,
                loading,
                error,
                activeTab,
                activeChapters,
                isLoggedIn,
                videoPlayer,
                handleLessonClick,
                handleTimeUpdate,
                handleVideoEnd,
                handleVideoError,
                handleEnroll,
                lessonProgress,
                handleProgressUpdate,
                totalLessons,
                isPurchased,
                purchasing,
                enrolling,
                handleVideoReady
            }
        }
    }
</script>

<style lang="scss" scoped>
    .course-detail {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: #f5f7fa;

        .course-container {
            flex: 1;
            max-width: 1400px;
            width: 100%;
            margin: 80px auto 20px;
            padding: 0 20px;
            background-color: #fff;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
        }

        .course-info {
            height: calc(100vh - 100px);
            overflow-y: auto;
            border-right: 1px solid #ebeef5;
            background: #fff;

            .info-container {
                padding: 30px;

                .course-cover {
                    img {
                        width: 100%;
                        border-radius: 8px;
                        margin-bottom: 20px;
                    }
                }

                .course-title {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .course-meta {
                    margin-bottom: 20px;
                    span {
                        display: inline-block;
                        margin-right: 15px;
                        color: #666;
                        i {
                            margin-right: 5px;
                        }
                    }
                }

                .course-price {
                    margin-bottom: 20px;
                    .currency {
                        font-size: 16px;
                        vertical-align: super;
                    }
                    .amount {
                        font-size: 28px;
                        font-weight: bold;
                        color: #f56c6c;
                    }
                }

                .teacher-info {
                    margin-top: 30px;
                    h3 {
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                    .teacher-card {
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        border-radius: 8px;
                        background: #f8f9fa;
                        margin-bottom: 10px;

                        .teacher-avatar {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }

                        .teacher-detail {
                            h4 {
                                font-size: 14px;
                                margin: 0 0 5px;
                            }
                            p {
                                font-size: 12px;
                                color: #666;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .video-section {
            .video-wrapper {
                padding: 30px;
                background: #f8f9fa;
            }

            .video-container {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                background: #000;
                aspect-ratio: 16/9;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

                :deep(.video-js) {
                    width: 100%;
                    height: 100%;

                    .vjs-control-bar {
                        background-color: rgba(0, 0, 0, 0.7);

                        .vjs-play-control {
                            cursor: pointer;

                            &:hover {
                                color: #409eff;
                            }
                        }

                        .vjs-progress-control {
                            .vjs-progress-holder {
                                height: 0.4em;

                                .vjs-play-progress {
                                    background-color: #409eff;
                                }
                            }
                        }

                        .vjs-volume-panel {
                            margin-right: 10px;
                        }

                        .vjs-fullscreen-control {
                            cursor: pointer;
                        }
                    }
                }
            }

            .video-placeholder {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f8f9fa;

                .placeholder-content {
                    text-align: center;

                    i {
                        font-size: 64px;
                        color: #dcdfe6;
                        margin-bottom: 20px;
                        cursor: pointer;
                        transition: color 0.3s;

                        &:hover {
                            color: #409eff;
                        }
                    }

                    p {
                        color: #909399;
                        margin-bottom: 20px;
                        font-size: 16px;
                    }
                }
            }

            .current-lesson-info {
                padding: 30px;
                border-bottom: 1px solid #ebeef5;
                background: #fff;

                h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                    color: #303133;
                }

                p {
                    color: #606266;
                    line-height: 1.8;
                    margin: 0;
                    font-size: 14px;
                }
            }

            .course-tabs {
                padding: 30px;

                :deep(.el-tabs__header) {
                    margin-bottom: 20px;
                }

                :deep(.el-tabs__item) {
                    font-size: 16px;
                    padding: 0 20px;
                }
            }
        }
        .course-chapters {
            height: calc(100vh - 100px);
            overflow-y: auto;
            border-left: 1px solid #ebeef5;
            background: #fff;

            .chapters-container {
                padding: 20px; /* 减小内边距以增加可用空间 */

                /* 自定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: #ccc;
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #999;
                }

                h3 {
                    font-size: 18px;
                    margin-bottom: 20px;
                    color: #303133;
                }

                .chapter-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    small {
                        font-size: 12px;
                    }
                }

                .lesson-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px;
                    cursor: pointer;
                    border-radius: 4px;
                    margin-bottom: 5px;

                    &:hover {
                        background: #f5f7fa;
                    }

                    &.active {
                        background: #ecf5ff;
                        color: #409eff;
                    }

                    .lesson-info {
                        display: flex;
                        align-items: center;
                        flex: 1;

                        i {
                            margin-right: 8px;
                        }
                        .lesson-title {
                            flex: 1;
                            max-width: calc(100% - 50px); /* 减去时长显示的宽度 */
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                            padding-right: 5px; /* 给时长留出间距 */
                        }
                        .lesson-duration {
                            font-size: 12px;
                            color: #909399;
                            min-width: 45px; /* 固定时长显示区域宽度 */
                            text-align: right;
                        }
                    }

                    .lesson-status {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .progress-tag {
                            margin-left: 8px;
                        }

                        .el-tag {
                            margin-left: 0; /* 减小标签间距 */
                            white-space: nowrap;
                        }
                    }
                }
            }

            .related-courses {
                padding: 20px;
                border-top: 1px solid #ebeef5;

                h3 {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }

        }

        @media (max-width: 768px) {
            .course-container {
                margin: 60px auto 10px;
                padding: 0;
                border-radius: 0;
            }

            .course-chapters {
                height: auto;
                max-height: 400px;
            }

            .video-section {
                .video-wrapper {
                    padding: 15px;
                }

                .video-container {
                    border-radius: 0;
                }

                .current-lesson-info,
                .course-tabs {
                    padding: 15px;
                }
            }
        }
    }

    // 加载状态样式
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    // 调整左右两侧的宽度比例
    .video-section {
        @media (min-width: 768px) {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }

    .course-chapters {
        @media (min-width: 768px) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .lesson-item {
            .lesson-info {
                .lesson-title {
                    flex: 1;
                    max-width: calc(100% - 50px); /* 减去时长显示的宽度 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    padding-right: 5px; /* 给时长留出间距 */
                }

                .lesson-duration {
                    font-size: 12px;
                    color: #909399;
                    min-width: 45px; /* 固定时长显示区域宽度 */
                    text-align: right;
                }
            }

        }
    }

    /* 课程介绍标签页样式 */
    .course-intro-header {
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ebeef5;

        .course-cover {
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        .course-title {
            font-size: 24px;
            font-weight: 600;
            color: #303133;
            margin-bottom: 15px;
        }

        .course-meta {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 15px;

            span {
                color: #606266;
                font-size: 14px;

                i {
                    margin-right: 5px;
                }
            }
        }

        .course-price {
            font-size: 18px;
            color: #f56c6c;
            font-weight: 600;
            margin-bottom: 20px;

            .currency {
                font-size: 14px;
                margin-right: 2px;
            }

            .amount {
                font-size: 24px;
            }
        }

        .course-actions {
            margin-bottom: 20px;
        }
    }

    .course-description {
        margin-bottom: 30px;
        line-height: 1.8;
        color: #606266;
    }

    .teacher-info {
        margin-top: 30px;
        border-top: 1px solid #ebeef5;
        padding-top: 20px;

        h3 {
            font-size: 18px;
            margin-bottom: 20px;
            color: #303133;
        }

        .teacher-card {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 15px;
            border-radius: 8px;
            background: #f8f9fa;

            .teacher-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 15px;
            }

            .teacher-detail {
                h4 {
                    font-size: 16px;
                    margin-bottom: 5px;
                    color: #303133;
                }

                p {
                    font-size: 14px;
                    color: #909399;
                    margin: 0;
                }
            }
        }
    }
</style>