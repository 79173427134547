<template>
  <div class="news-page">
    <page-header title="新闻动态" subtitle="了解学校最新动态和重要通知"></page-header>
    
    <div class="container py-5">
      <!-- Loading 状态 -->
      <div v-if="loading" class="text-center py-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">加载中...</span>
        </div>
      </div>

      <!-- 新闻列表 -->
      <div v-else>
        <div class="row g-4">
          <div v-for="news in newsList" :key="news.id" class="col-md-6">
            <div class="card h-100 news-card">
              <div class="row g-0">
                <div class="col-4">
                  <img :src="news.imageUrl" class="img-fluid rounded-start h-100" :alt="news.title" style="object-fit: cover;">
                </div>
                <div class="col-8">
                  <div class="card-body d-flex flex-column">
                    <h5 class="card-title text-truncate">{{ news.title }}</h5>
                    <p class="card-text flex-grow-1">{{ news.summary }}</p>
                    <div class="mt-auto">
                      <p class="card-text">
                        <small class="text-muted">发布于：{{ formatDate(news.createdAt) }}</small>
                      </p>
                      <router-link :to="'/news/' + news.id" class="btn btn-outline-primary btn-sm">
                        阅读更多
                        <i class="fas fa-arrow-right ms-1"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 无数据显示 -->
        <div v-if="newsList.length === 0" class="text-center py-5">
          <i class="fas fa-newspaper fa-3x text-muted mb-3"></i>
          <p class="text-muted">暂无新闻</p>
        </div>

        <!-- 分页 -->
        <nav v-if="totalPages > 1" class="mt-5">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage - 1)">
                <i class="fas fa-chevron-left"></i>
              </a>
            </li>
            <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
              <a class="page-link" href="#" @click.prevent="handlePageChange(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="handlePageChange(currentPage + 1)">
                <i class="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import request from '@/utils/axios'
import { formatDate } from '@/utils/date'

export default {
  name: 'News',
  components: {
    PageHeader,
    SiteFooter
  },
  setup() {
    const newsList = ref([])
    const currentPage = ref(1)
    const totalPages = ref(1)
    const pageSize = 10
    const loading = ref(false)

    const fetchNews = async (page) => {
      loading.value = true
      try {
        const response = await request({
          url: 'api/public/news/get/latest',
          method: 'get',
          params: {
            page: page - 1,
            size: pageSize
          }
        })

        // 清理 HTML 标签和特殊字符的函数
        const cleanContent = (content) => {
          if (!content) return ''
          // 先移除所有 HTML 标签
          let cleaned = content.replace(/<[^>]+>/g, '')
          // 移除特殊字符和 HTML 实体
          cleaned = cleaned.replace(/&[^;]+;/g, '')
          // 移除多余的空格和换行
          cleaned = cleaned.replace(/\s+/g, ' ')
          // 移除特殊引号
          cleaned = cleaned.replace(/[""'']/g, '"')
          return cleaned.trim()
        }

        newsList.value = response.data.content.map(news => ({
          ...news,
          imageUrl: news.image 
            ? `${process.env.VUE_APP_API_URL}/api${news.image}`
            : `${process.env.VUE_APP_API_URL}/api/static/images/news-default.jpg`,
          summary: cleanContent(news.content).substring(0, 100) + (news.content.length > 100 ? '...' : '')
        }))
        totalPages.value = response.data.totalPages
      } catch (error) {
        console.error('获取新闻列表失败:', error)
      } finally {
        loading.value = false
      }
    }

    const handlePageChange = (page) => {
      if (page > 0 && page <= totalPages.value) {
        currentPage.value = page
        fetchNews(page)
        // 滚动到顶部
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }

    onMounted(() => {
      fetchNews(1)
    })

    return {
      newsList,
      currentPage,
      totalPages,
      handlePageChange,
      loading,
      formatDate
    }
  }
}
</script>

<style scoped>
.news-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}

.container {
  flex: 1;
}

.news-card {
  border: none;
  box-shadow: 0 2px 15px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0,0,0,0.12);
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.8rem;
}

.card-text {
  color: #6c757d;
  font-size: 0.95rem;
  line-height: 1.5;
}

.btn-outline-primary {
  border-width: 2px;
  font-weight: 500;
  padding: 0.4rem 1rem;
}

.btn-outline-primary:hover {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

.pagination {
  gap: 5px;
}

.page-link {
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: #4c84ff;
  border: none;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.page-link:hover {
  background-color: #4c84ff;
  color: white;
}

.page-item.active .page-link {
  background-color: #4c84ff;
  border-color: #4c84ff;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

img {
  min-height: 200px;
  max-height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
  .col-4 {
    min-height: 150px;
  }
  
  img {
    min-height: 150px;
    max-height: 150px;
  }
  
  .card-title {
    font-size: 1rem;
  }
  
  .card-text {
    font-size: 0.9rem;
  }
}
</style> 