<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img src="/logo.png" alt="Logo" height="50">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/" exact>首页</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/courses">课程中心</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/job-service">就业服务</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/courses">在线学习</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/news">新闻动态</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link px-3" to="/about">关于我们</router-link>
          </li>
        </ul>
        <div class="d-flex align-items-center">
          <!-- 用户未登录 -->
          <template v-if="!isLoggedIn">
            <router-link to="/auth/login" class="btn btn-outline-primary me-2">登录</router-link>
            <router-link to="/auth/register" class="btn btn-primary">注册</router-link>
          </template>
          <!-- 用户已登录 -->
          <div v-else class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
              <i class="fas fa-user-circle me-1"></i>
              {{ username }}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li v-if="isAdmin">
                <router-link class="dropdown-item" to="/admin/dashboard">
                  <i class="fas fa-tachometer-alt me-2"></i>后台管理
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/student">
                  <i class="fas fa-user me-2"></i>个人中心
                </router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a class="dropdown-item" href="#" @click.prevent="handleLogout">
                  <i class="fas fa-sign-out-alt me-2"></i>退出登录
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'SiteNavbar',
    setup() {
      const router = useRouter()

      // Use a ref to store the isLoggedIn status. This forces the component to re-render
      // whenever the isLoggedIn status changes.
      const loggedIn = ref(localStorage.getItem('token') !== null)

      const isLoggedIn = computed(() => loggedIn.value)


      const isAdmin = computed(() => {
        return localStorage.getItem('userRole') === 'ADMIN'
      })

      // Use a ref to trigger re-render when username changes
      const name = ref(localStorage.getItem('username') || '用户');
      const username = computed(() => name.value);


      const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userRole')
        localStorage.removeItem('username')
        loggedIn.value = false // Update the ref value to trigger re-render
        name.value = '用户' //Update the ref value for the name
        ElMessage.success('退出登录成功')
        router.push('/auth/login')
      }

      return {
        isLoggedIn,
        isAdmin,
        username,
        handleLogout
      }
    }
  }
</script>

<style scoped>
  .navbar {
    padding: 0.5rem 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #fff;
  }

  .navbar-brand {
    padding: 0;
  }

  .nav-link {
    font-size: 1.1rem;
    font-weight: 500;
    position: relative;
    color: #333;
  }

  .nav-link:hover {
    color: #666;
  }

  .nav-link::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #666;
    transition: all 0.3s;
    transform: translateX(-50%);
    opacity: 0;
  }

  .nav-link:hover::after,
  .nav-link.active::after {
    width: 80%;
    opacity: 1;
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    padding: 0.5rem 0;
  }

  .dropdown-item {
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    transition: all 0.2s;
    color: #333;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
    padding-left: 2rem;
    color: #666;
  }

  .btn {
    padding: 0.5rem 1.5rem;
    font-weight: 500;
    transition: all 0.3s;
  }

  .btn-primary {
    background-color: #666;
    border-color: #666;
  }

  .btn-primary:hover {
    background-color: #555;
    border-color: #555;
  }

  .btn-outline-primary {
    color: #666;
    border-color: #666;
  }

  .btn-outline-primary:hover {
    background-color: #666;
    border-color: #666;
    color: #fff;
  }

  @media (max-width: 991.98px) {
    .navbar-nav {
      padding: 1rem 0;
    }

    .nav-link {
      padding: 0.5rem 1rem !important;
    }

    .nav-link::after {
      display: none;
    }

    .dropdown-menu {
      border: none;
      background: transparent;
      box-shadow: none;
    }

    .dropdown-item {
      padding: 0.5rem 2rem;
    }

    .dropdown-item:hover {
      background-color: rgba(76,132,255,0.1);
    }
  }
</style>