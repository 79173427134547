import request from '@/utils/axios'



// 获取学生列表
export function getStudentList(params) {
  return request({
    url: '/api/admin/users/students',  // 修改为新的学生查询接口
    method: 'get',
    params
  })
}

// 获取学生详情
export function getStudentDetail(id) {
  return request({
    url: `/api/admin/students/${id}`,
    method: 'get'
  })
}

// 创建学生
export function createStudent(data) {
  return request({
    url: '/api/admin/students/add',
    method: 'post',
    data
  })
}

// 更新学生信息
export function updateStudent(id, data) {
  return request({
    url: `/api/admin/students/update/${id}`,
    method: 'put',
    data
  })
}

// 删除学生
export function deleteStudent(id) {
  return request({
    url: `/api/admin/students/delete/${id}`,
    method: 'delete'
  })
}

// 重置学生密码
export function resetStudentPassword(id) {
  return request({
    url: `/api/admin/students/reset-password/${id}`,
    method: 'post'
  })
}

// 获取学生统计信息
export function getStudentStatistics(id) {
  return request({
    url: `/api/admin/users/statistics/${id}`,
    method: 'get'
  })
}

// 获取学生学习记录
export function getStudentLearningRecords(id) {
  return request({
    url: `/api/admin/users/learning-records/${id}`,
    method: 'get'
  })
}

// 获取最近学生
export function getRecentStudents() {
  return request({
    url: '/api/admin/users/recent',
    method: 'get'
  })
} 