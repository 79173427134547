<template>
  <footer class="bg-dark text-light py-5">
    <div class="container">
      <div class="row g-4">
        <div class="col-md-4">
          <h5>关于我们</h5>
          <p>郴州中智软件学校是一所专注于Java、大数据、人工智能等IT技术培训的专业院校。拥有阿里云双认证讲师，培训体系完善，就业有保障。</p>
        </div>
        <div class="col-md-4">
          <h5>联系方式</h5>
          <ul class="list-unstyled">
            <li><i class="fas fa-phone me-2"></i> 0735-8883388</li>
            <li><i class="fas fa-phone me-2"></i> 18073544223（马老师）</li>
            <li><i class="fab fa-qq me-2"></i> 9135554</li>
            <li><i class="fas fa-map-marker-alt me-2"></i> 郴州市苏仙区白露塘镇石虎路东河路 中智软件学校</li>
          </ul>
        </div>
        <div class="col-md-4">
          <h5>关注我们</h5>
          <div class="social-links">
            <img src="../../public/wx.jpg" alt="微信二维码" class="qr-code me-3">
            <a href="#" class="text-light me-3"><i class="fab fa-qq"></i></a>
          </div>
        </div>
        <div class="col-md-12 text-center mt-4">
          <p class="mb-0">© 2025 郴州中智软件学校 版权所有</p>
          <p class="mb-0">
            <a href="https://beian.miit.gov.cn" target="_blank" class="text-light text-decoration-none">
              <small>湘ICP备19023789号-3</small>
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
.social-links a {
  font-size: 1.5rem;
  transition: opacity 0.2s;
}

.social-links a:hover {
  opacity: 0.8;
}

.qr-code {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}
</style> 