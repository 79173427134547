import request from '@/utils/axios'

// 获取教师列表
export function getTeacherList(params) {
  return request({
    url: '/api/teachers',
    method: 'get',
    params
  })
}

// 获取教师详情
export function getTeacherDetail(id) {
  return request({
    url: `/api/teachers/${id}`,
    method: 'get'
  })
}

// 创建教师
export function createTeacher(data) {
  return request({
    url: '/api/teachers',
    method: 'post',
    data
  })
}

// 更新教师信息
export function updateTeacher(id, data) {
  return request({
    url: `/api/teachers/${id}`,
    method: 'put',
    data
  })
}

// 删除教师
export function deleteTeacher(id) {
  return request({
    url: `/api/teachers/${id}`,
    method: 'delete'
  })
} 