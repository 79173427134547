<template>
  <div class="auth-page">
    <div class="auth-container">
      <div class="text-center mb-4">
        <h3>用户注册</h3>
        <p class="text-muted">欢迎使用中智爱学习系统</p>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style scoped>
.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%);
}

.auth-container {
  width: 100%;
  background: white;
  border-radius: 1rem;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.text-muted {
  color: #666;
  font-size: 0.95rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.text-center {
  text-align: center;
}
</style> 