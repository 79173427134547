<template>
  <div class="settings-page">
    <div class="content-card">
      <div class="content-header">
        <h2>系统设置</h2>
      </div>
      
      <!-- 设置选项卡 -->
      <el-tabs v-model="activeTab" class="p-4">
        <!-- 基础设置 -->
        <el-tab-pane label="基础设置" name="basic">
          <el-form :model="basicForm" label-width="120px">
            <el-form-item label="网站名称">
              <el-input v-model="basicForm.siteName" placeholder="请输入网站名称"/>
            </el-form-item>
            
            <el-form-item label="网站Logo">
              <el-upload
                class="avatar-uploader"
                action="/api/upload"
                :show-file-list="false"
                :on-success="handleLogoSuccess"
                :before-upload="beforeLogoUpload">
                <img v-if="basicForm.logo" :src="basicForm.logo" class="avatar">
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
              </el-upload>
              <div class="text-muted mt-2">建议尺寸：200x50px，支持jpg、png格式</div>
            </el-form-item>
            
            <el-form-item label="网站域名">
              <el-input v-model="basicForm.domain" placeholder="请输入网站域名"/>
            </el-form-item>
            
            <el-form-item label="默认语言">
              <el-select v-model="basicForm.language" placeholder="请选择默认语言">
                <el-option label="简体中文" value="zh_CN"/>
                <el-option label="English" value="en_US"/>
              </el-select>
            </el-form-item>
            
            <el-form-item label="时区设置">
              <el-select v-model="basicForm.timezone" placeholder="请选择时区">
                <el-option label="(GMT+08:00) 北京" value="Asia/Shanghai"/>
                <el-option label="(GMT+08:00) 香港" value="Asia/Hong_Kong"/>
                <el-option label="(GMT+08:00) 台北" value="Asia/Taipei"/>
              </el-select>
            </el-form-item>
            
            <el-form-item label="备案信息">
              <el-input v-model="basicForm.icp" placeholder="请输入备案信息"/>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        
        <!-- 用户与权限设置 -->
        <el-tab-pane label="用户与权限" name="user">
          <el-tabs v-model="userActiveTab" tab-position="left" class="user-tabs">
            <el-tab-pane label="角色管理" name="roles">
              <div class="d-flex justify-content-between mb-3">
                <h4>角色列表</h4>
                <el-button type="primary" @click="handleAddRole">
                  <el-icon><Plus /></el-icon>添加角色
                </el-button>
              </div>
              
              <el-table :data="roles" style="width: 100%">
                <el-table-column prop="name" label="角色名称"/>
                <el-table-column prop="description" label="描述"/>
                <el-table-column label="操作" width="200">
                  <template #default="scope">
                    <el-button size="small" @click="handleEditRole(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDeleteRole(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            
            <el-tab-pane label="注册设置" name="register">
              <el-form :model="registerForm" label-width="160px">
                <el-form-item label="是否开放注册">
                  <el-switch v-model="registerForm.allowRegister"/>
                </el-form-item>
                
                <el-form-item label="允许注册的角色">
                  <el-checkbox-group v-model="registerForm.allowedRoles">
                    <el-checkbox label="student">学生</el-checkbox>
                    <el-checkbox label="teacher">教师</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                
                <el-form-item label="注册验证方式">
                  <el-radio-group v-model="registerForm.verifyMethod">
                    <el-radio label="email">邮箱验证</el-radio>
                    <el-radio label="phone">手机验证</el-radio>
                    <el-radio label="none">无需验证</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        
        <!-- 邮件/短信设置 -->
        <el-tab-pane label="通知设置" name="notification">
          <el-tabs v-model="notificationActiveTab" tab-position="left">
            <el-tab-pane label="邮件配置" name="email">
              <el-form :model="emailForm" label-width="120px">
                <el-form-item label="SMTP服务器">
                  <el-input v-model="emailForm.smtpServer" placeholder="请输入SMTP服务器地址"/>
                </el-form-item>
                
                <el-form-item label="SMTP端口">
                  <el-input v-model="emailForm.smtpPort" placeholder="请输入SMTP端口"/>
                </el-form-item>
                
                <el-form-item label="发件人邮箱">
                  <el-input v-model="emailForm.fromEmail" placeholder="请输入发件人邮箱"/>
                </el-form-item>
                
                <el-form-item label="发件人名称">
                  <el-input v-model="emailForm.fromName" placeholder="请输入发件人名称"/>
                </el-form-item>
                
                <el-form-item label="SMTP密码">
                  <el-input v-model="emailForm.password" type="password" placeholder="请输入SMTP密码"/>
                </el-form-item>
                
                <el-form-item>
                  <el-button type="primary" @click="testEmail">发送测试邮件</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            
            <el-tab-pane label="短信配置" name="sms">
              <el-form :model="smsForm" label-width="120px">
                <el-form-item label="服务提供商">
                  <el-select v-model="smsForm.provider" placeholder="请选择短信服务提供商">
                    <el-option label="阿里云" value="aliyun"/>
                    <el-option label="腾讯云" value="tencent"/>
                  </el-select>
                </el-form-item>
                
                <el-form-item label="AccessKey">
                  <el-input v-model="smsForm.accessKey" placeholder="请输入AccessKey"/>
                </el-form-item>
                
                <el-form-item label="SecretKey">
                  <el-input v-model="smsForm.secretKey" type="password" placeholder="请输入SecretKey"/>
                </el-form-item>
                
                <el-form-item label="短信签名">
                  <el-input v-model="smsForm.signName" placeholder="请输入短信签名"/>
                </el-form-item>
                
                <el-form-item>
                  <el-button type="primary" @click="testSms">发送测试短信</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            
            <el-tab-pane label="通知事件" name="events">
              <el-table :data="notificationEvents" style="width: 100%">
                <el-table-column prop="name" label="事件名称"/>
                <el-table-column label="邮件通知">
                  <template #default="scope">
                    <el-switch v-model="scope.row.emailEnabled"/>
                  </template>
                </el-table-column>
                <el-table-column label="短信通知">
                  <template #default="scope">
                    <el-switch v-model="scope.row.smsEnabled"/>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        
        <!-- 存储设置 -->
        <el-tab-pane label="存储设置" name="storage">
          <el-form :model="storageForm" label-width="120px">
            <el-form-item label="存储方式">
              <el-radio-group v-model="storageForm.type">
                <el-radio label="local">本地存储</el-radio>
                <el-radio label="oss">阿里云OSS</el-radio>
                <el-radio label="cos">腾讯云COS</el-radio>
              </el-radio-group>
            </el-form-item>
            
            <!-- 图片访问地址配置 -->
            <el-form-item label="图片访问地址">
              <el-input 
                v-model="storageForm.imageBaseUrl" 
                placeholder="请输入图片访问基础地址，例如：http://localhost:8888/api/images"
              >
                <template #append>
                  <el-tooltip 
                    content="用于访问上传的图片，例如：http://localhost:8888/api/images" 
                    placement="top"
                  >
                    <el-icon><QuestionFilled /></el-icon>
                  </el-tooltip>
                </template>
              </el-input>
              <div class="text-muted mt-1">该地址将作为图片访问的基础URL</div>
            </el-form-item>
            
            <!-- 本地存储配置 -->
            <template v-if="storageForm.type === 'local'">
              <el-form-item label="上传目录">
                <el-input v-model="storageForm.local.uploadPath" placeholder="请输入上传目录路径"/>
              </el-form-item>
            </template>
            
            <!-- 阿里云OSS配置 -->
            <template v-if="storageForm.type === 'oss'">
              <el-form-item label="AccessKey">
                <el-input v-model="storageForm.oss.accessKey" placeholder="请输入AccessKey"/>
              </el-form-item>
              
              <el-form-item label="SecretKey">
                <el-input v-model="storageForm.oss.secretKey" type="password" placeholder="请输入SecretKey"/>
              </el-form-item>
              
              <el-form-item label="Bucket">
                <el-input v-model="storageForm.oss.bucket" placeholder="请输入Bucket名称"/>
              </el-form-item>
              
              <el-form-item label="访问域名">
                <el-input v-model="storageForm.oss.domain" placeholder="请输入访问域名"/>
              </el-form-item>
            </template>
            
            <el-form-item label="文件大小限制">
              <el-input-number v-model="storageForm.maxSize" :min="1" :max="100" label="MB"/>
              <span class="ms-2">MB</span>
            </el-form-item>
            
            <el-form-item label="允许的文件类型">
              <el-select v-model="storageForm.allowedTypes" multiple placeholder="请选择允许上传的文件类型">
                <el-option label="图片文件(*.jpg, *.png, *.gif)" value="image"/>
                <el-option label="文档文件(*.doc, *.pdf)" value="document"/>
                <el-option label="视频文件(*.mp4, *.avi)" value="video"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        
        <!-- 课程设置 -->
        <el-tab-pane label="课程设置" name="course">
          <el-form :model="courseForm" label-width="120px">
            <el-form-item label="课程状态">
              <el-table :data="courseForm.statusList" style="width: 100%">
                <el-table-column prop="name" label="状态名称"/>
                <el-table-column prop="value" label="状态值"/>
                <el-table-column label="操作" width="150">
                  <template #default="scope">
                    <el-button size="small" @click="handleEditStatus(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDeleteStatus(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button type="primary" class="mt-3" @click="handleAddStatus">添加状态</el-button>
            </el-form-item>
            
            <el-form-item label="课程等级">
              <el-checkbox-group v-model="courseForm.levels">
                <el-checkbox label="beginner">初级</el-checkbox>
                <el-checkbox label="intermediate">中级</el-checkbox>
                <el-checkbox label="advanced">高级</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            
            <el-form-item label="时长单位">
              <el-radio-group v-model="courseForm.durationUnit">
                <el-radio label="minute">分钟</el-radio>
                <el-radio label="hour">小时</el-radio>
                <el-radio label="day">天</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        
        <!-- 系统日志 -->
        <el-tab-pane label="系统日志" name="log">
          <el-tabs v-model="logActiveTab">
            <el-tab-pane label="操作日志" name="operation">
              <el-table :data="operationLogs" style="width: 100%">
                <el-table-column prop="timestamp" label="时间" width="180"/>
                <el-table-column prop="username" label="操作人" width="120"/>
                <el-table-column prop="action" label="操作"/>
                <el-table-column prop="ip" label="IP地址" width="140"/>
              </el-table>
            </el-tab-pane>
            
            <el-tab-pane label="访问日志" name="access">
              <el-table :data="accessLogs" style="width: 100%">
                <el-table-column prop="timestamp" label="时间" width="180"/>
                <el-table-column prop="path" label="访问路径"/>
                <el-table-column prop="method" label="请求方法" width="100"/>
                <el-table-column prop="ip" label="IP地址" width="140"/>
                <el-table-column prop="status" label="状态码" width="100"/>
              </el-table>
            </el-tab-pane>
            
            <el-tab-pane label="异常日志" name="error">
              <el-table :data="errorLogs" style="width: 100%">
                <el-table-column prop="timestamp" label="时间" width="180"/>
                <el-table-column prop="level" label="级别" width="100"/>
                <el-table-column prop="message" label="错误信息"/>
                <el-table-column prop="stackTrace" label="堆栈信息">
                  <template #default="scope">
                    <el-button size="small" @click="showStackTrace(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      
      <!-- 底部按钮 -->
      <div class="p-4 border-top">
        <el-button type="primary" @click="saveSettings">保存设置</el-button>
        <el-button @click="resetSettings">重置</el-button>
      </div>
    </div>
    
    <!-- 角色编辑对话框 -->
    <el-dialog
      v-model="roleDialog.visible"
      :title="roleDialog.isEdit ? '编辑角色' : '添加角色'"
      width="500px">
      <el-form :model="roleDialog.form" label-width="100px">
        <el-form-item label="角色名称">
          <el-input v-model="roleDialog.form.name"/>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input type="textarea" v-model="roleDialog.form.description"/>
        </el-form-item>
        <el-form-item label="权限设置">
          <el-tree
            :data="permissionTree"
            show-checkbox
            node-key="id"
            :default-checked-keys="roleDialog.form.permissions"
            ref="permissionTreeRef"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="roleDialog.visible = false">取消</el-button>
        <el-button type="primary" @click="saveRole">确定</el-button>
      </template>
    </el-dialog>
    
    <!-- 课程状态编辑对话框 -->
    <el-dialog
      v-model="statusDialog.visible"
      :title="statusDialog.isEdit ? '编辑状态' : '添加状态'"
      width="400px">
      <el-form :model="statusDialog.form" label-width="80px">
        <el-form-item label="状态名称">
          <el-input v-model="statusDialog.form.name"/>
        </el-form-item>
        <el-form-item label="状态值">
          <el-input v-model="statusDialog.form.value"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="statusDialog.visible = false">取消</el-button>
        <el-button type="primary" @click="saveStatus">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus, QuestionFilled } from '@element-plus/icons-vue'
import request from '@/utils/axios'

export default {
  name: 'Settings',
  components: {
    Plus,
    QuestionFilled
  },
  setup() {
    // 当前激活的标签页
    const activeTab = ref('basic')
    const userActiveTab = ref('roles')
    const notificationActiveTab = ref('email')
    const logActiveTab = ref('operation')
    
    // 基础设置表单
    const basicForm = reactive({
      siteName: '',
      logo: '',
      domain: '',
      language: 'zh_CN',
      timezone: 'Asia/Shanghai',
      icp: ''
    })
    
    // 角色管理
    const roles = ref([])
    const roleDialog = reactive({
      visible: false,
      isEdit: false,
      form: {
        name: '',
        description: '',
        permissions: []
      }
    })
    
    // 注册设置
    const registerForm = reactive({
      allowRegister: true,
      allowedRoles: ['student'],
      verifyMethod: 'email'
    })
    
    // 邮件设置
    const emailForm = reactive({
      smtpServer: '',
      smtpPort: '',
      fromEmail: '',
      fromName: '',
      password: ''
    })
    
    // 短信设置
    const smsForm = reactive({
      provider: 'aliyun',
      accessKey: '',
      secretKey: '',
      signName: ''
    })
    
    // 通知事件
    const notificationEvents = ref([
      { name: '新用户注册', emailEnabled: true, smsEnabled: false },
      { name: '课程报名', emailEnabled: true, smsEnabled: true },
      { name: '订单支付', emailEnabled: true, smsEnabled: true }
    ])
    
    // 存储设置
    const storageForm = reactive({
      type: 'local',
      local: {
        uploadPath: '/uploads'
      },
      oss: {
        accessKey: '',
        secretKey: '',
        bucket: '',
        domain: ''
      },
      maxSize: 10,
      allowedTypes: ['image', 'document'],
      imageBaseUrl: ''
    })
    
    // 课程设置
    const courseForm = reactive({
      statusList: [
        { name: '草稿', value: 'DRAFT' },
        { name: '待审核', value: 'PENDING' },
        { name: '已发布', value: 'PUBLISHED' }
      ],
      levels: ['beginner', 'intermediate', 'advanced'],
      durationUnit: 'hour'
    })
    
    // 状态编辑对话框
    const statusDialog = reactive({
      visible: false,
      isEdit: false,
      form: {
        name: '',
        value: ''
      }
    })
    
    // 日志数据
    const operationLogs = ref([])
    const accessLogs = ref([])
    const errorLogs = ref([])
    
    // 权限树数据
    const permissionTree = ref([])
    
    // 设置数据
    const settings = ref({
      basic: {}, // 基础设置
      user: {}, // 用户设置
      email: {}, // 邮件设置
      sms: {}, // 短信设置
      storage: {}, // 存储设置
      course: {}, // 课程设置
      log: {} // 日志设置
    })
    
    // 修改加载设置的方法
    const loadSettings = async () => {
      try {
        const response = await request.get('/api/admin/settings')
        if (response.data.success) {
          const data = response.data.data;
          
          // 同步基础设置
          if (data.basic) {
            basicForm.siteName = data.basic.siteName || '';
            basicForm.logo = data.basic.logo || '';
            basicForm.domain = data.basic.domain || '';
            basicForm.language = data.basic.language || 'zh_CN';
            basicForm.timezone = data.basic.timezone || 'Asia/Shanghai';
            basicForm.icp = data.basic.icp || '';
          }
          
          // 同步用户设置
          if (data.user) {
            registerForm.allowRegister = data.user.allowRegister ?? true;
            registerForm.allowedRoles = data.user.allowedRoles || ['student'];
            registerForm.verifyMethod = data.user.verifyMethod || 'email';
          }
          
          // 同步邮件设置
          if (data.email) {
            emailForm.smtpServer = data.email.smtpServer || '';
            emailForm.smtpPort = data.email.smtpPort || '';
            emailForm.fromEmail = data.email.fromEmail || '';
            emailForm.fromName = data.email.fromName || '';
            emailForm.password = data.email.password || '';
          }
          
          // 同步短信设置
          if (data.sms) {
            smsForm.provider = data.sms.provider || 'aliyun';
            smsForm.accessKey = data.sms.accessKey || '';
            smsForm.secretKey = data.sms.secretKey || '';
            smsForm.signName = data.sms.signName || '';
          }
          
          // 同步存储设置
          if (data.storage) {
            storageForm.type = data.storage.type || 'local';
            storageForm.local.uploadPath = data.storage.uploadPath || '/uploads';
            storageForm.maxSize = data.storage.maxSize || 10;
            storageForm.allowedTypes = data.storage.allowedTypes || ['image', 'document'];
            storageForm.imageBaseUrl = data.storage.imageBaseUrl || '';
            
            if (data.storage.oss) {
              storageForm.oss.accessKey = data.storage.oss.accessKey || '';
              storageForm.oss.secretKey = data.storage.oss.secretKey || '';
              storageForm.oss.bucket = data.storage.oss.bucket || '';
              storageForm.oss.domain = data.storage.oss.domain || '';
            }
          }
          
          // 同步课程设置
          if (data.course) {
            courseForm.statusList = data.course.statusList || [
              { name: '草稿', value: 'DRAFT' },
              { name: '待审核', value: 'PENDING' },
              { name: '已发布', value: 'PUBLISHED' }
            ];
            courseForm.levels = data.course.levels || ['beginner', 'intermediate', 'advanced'];
            courseForm.durationUnit = data.course.durationUnit || 'hour';
          }
          
          // 保存原始设置数据
          settings.value = data;
        }
      } catch (error) {
        console.error('加载设置失败:', error);
        ElMessage.error('加载设置失败：' + (error.response?.data?.message || error.message));
      }
    };
    
    // 修改保存设置的方法
    const saveSettings = async () => {
      try {
        // 根据当前激活的标签页保存对应的设置
        switch (activeTab.value) {
          case 'basic':
            await request.post('/api/admin/settings/basic', {
              siteName: basicForm.siteName,
              logo: basicForm.logo,
              domain: basicForm.domain,
              language: basicForm.language,
              timezone: basicForm.timezone,
              icp: basicForm.icp
            });
            break;
          case 'user':
            await request.post('/api/admin/settings/user', {
              allowRegister: registerForm.allowRegister,
              allowedRoles: registerForm.allowedRoles,
              verifyMethod: registerForm.verifyMethod
            });
            break;
          case 'notification':
            if (notificationActiveTab.value === 'email') {
              await request.post('/api/admin/settings/email', emailForm);
            } else if (notificationActiveTab.value === 'sms') {
              await request.post('/api/admin/settings/sms', smsForm);
            }
            break;
          case 'storage':
            await request.post('/api/admin/settings/storage', {
              type: storageForm.type,
              local: storageForm.local,
              oss: storageForm.oss,
              maxSize: storageForm.maxSize,
              allowedTypes: storageForm.allowedTypes,
              imageBaseUrl: storageForm.imageBaseUrl
            });
            break;
          case 'course':
            await request.post('/api/admin/settings/course', courseForm);
            break;
        }
        
        ElMessage.success('保存成功');
        await loadSettings(); // 重新加载设置
      } catch (error) {
        console.error('保存设置失败:', error);
        ElMessage.error('保存设置失败：' + (error.response?.data?.message || error.message));
      }
    };
    
    // 删除设置
    const deleteSetting = async (key) => {
      try {
        await request.delete(`/api/admin/settings/${key}`)
        ElMessage.success('删除成功')
        await loadSettings() // 重新加载设置
      } catch (error) {
        console.error('删除设置失败:', error)
        ElMessage.error('删除设置失败')
      }
    }
    
    // 测试邮件配置
    const testEmailConfig = async () => {
      try {
        await request.post('/api/admin/settings/email/test')
        ElMessage.success('测试邮件发送成功')
      } catch (error) {
        console.error('测试邮件发送失败:', error)
        ElMessage.error('测试邮件发送失败')
      }
    }
    
    // 测试短信配置
    const testSmsConfig = async () => {
      try {
        await request.post('/api/admin/settings/sms/test')
        ElMessage.success('测试短信发送成功')
      } catch (error) {
        console.error('测试短信发送失败:', error)
        ElMessage.error('测试短信发送失败')
      }
    }
    
    // 方法
    const handleLogoSuccess = (response) => {
      basicForm.logo = response.url
      ElMessage.success('Logo上传成功')
    }
    
    const beforeLogoUpload = (file) => {
      const isImage = file.type.startsWith('image/')
      const isLt2M = file.size / 1024 / 1024 < 2
      
      if (!isImage) {
        ElMessage.error('只能上传图片文件！')
        return false
      }
      if (!isLt2M) {
        ElMessage.error('图片大小不能超过 2MB！')
        return false
      }
      return true
    }
    
    const handleAddRole = () => {
      roleDialog.isEdit = false
      roleDialog.form = {
        name: '',
        description: '',
        permissions: []
      }
      roleDialog.visible = true
    }
    
    const handleEditRole = (row) => {
      roleDialog.isEdit = true
      roleDialog.form = { ...row }
      roleDialog.visible = true
    }
    
    const handleDeleteRole = async (row) => {
      try {
        await ElMessageBox.confirm('确定要删除该角色吗？', '提示', {
          type: 'warning'
        })
        // 调用删除API
        await request.delete(`/api/admin/roles/${row.id}`)
        ElMessage.success('删除成功')
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除失败：' + error.message)
        }
      }
    }
    
    const saveRole = () => {
      // 调用保存API
      roleDialog.visible = false
      ElMessage.success(roleDialog.isEdit ? '编辑成功' : '添加成功')
    }
    
    const testEmail = () => {
      ElMessage.success('测试邮件发送成功')
    }
    
    const testSms = () => {
      ElMessage.success('测试短信发送成功')
    }
    
    const handleAddStatus = () => {
      statusDialog.isEdit = false
      statusDialog.form = {
        name: '',
        value: ''
      }
      statusDialog.visible = true
    }
    
    const handleEditStatus = (row) => {
      statusDialog.isEdit = true
      statusDialog.form = { ...row }
      statusDialog.visible = true
    }
    
    const handleDeleteStatus = async (row) => {
      try {
        await ElMessageBox.confirm('确定要删除该状态吗？', '提示', {
          type: 'warning'
        })
        // 调用删除API
        await request.delete(`/api/admin/course-status/${row.value}`)
        ElMessage.success('删除成功')
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除失败：' + error.message)
        }
      }
    }
    
    const saveStatus = () => {
      // 调用保存API
      statusDialog.visible = false
      ElMessage.success(statusDialog.isEdit ? '编辑成功' : '添加成功')
    }
    
    const showStackTrace = (row) => {
      ElMessageBox.alert(row.stackTrace, '堆栈信息', {
        confirmButtonText: '确定'
      })
    }
    
    const resetSettings = () => {
      ElMessageBox.confirm('确定要重置所有设置吗？', '提示', {
        type: 'warning'
      }).then(() => {
        // 重置所有表单
        ElMessage.success('设置已重置')
      })
    }
    
    // 组件挂载时加载设置
    onMounted(() => {
      loadSettings()
    })
    
    return {
      activeTab,
      userActiveTab,
      notificationActiveTab,
      logActiveTab,
      basicForm,
      roles,
      roleDialog,
      registerForm,
      emailForm,
      smsForm,
      notificationEvents,
      storageForm,
      courseForm,
      statusDialog,
      operationLogs,
      accessLogs,
      errorLogs,
      permissionTree,
      handleLogoSuccess,
      beforeLogoUpload,
      handleAddRole,
      handleEditRole,
      handleDeleteRole,
      saveRole,
      testEmail,
      testSms,
      handleAddStatus,
      handleEditStatus,
      handleDeleteStatus,
      saveStatus,
      showStackTrace,
      saveSettings,
      resetSettings,
      settings,
      loadSettings,
      deleteSetting,
      testEmailConfig,
      testSmsConfig
    }
  }
}
</script>

<style scoped>
.settings-page {
  padding: 20px;
  min-width: 1200px;
  margin: 0 auto;
}

.content-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 40px);
  width: 100%;
  margin: 0 auto;
}

.content-header {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}

.content-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

/* 调整选项卡内容区域的内边距和最小高度 */
:deep(.el-tabs__content) {
  padding: 30px;
  min-height: 600px;
}

/* 调整表单容器的宽度 */
:deep(.el-form) {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

/* 调整表格容器的宽度 */
:deep(.el-table) {
  width: 100%;
  margin: 20px 0;
}

/* 调整输入框的宽度 */
:deep(.el-input),
:deep(.el-select) {
  width: 400px;
}

/* 调整文本域的宽度和高度 */
:deep(.el-textarea) {
  width: 400px;
}

:deep(.el-textarea__inner) {
  min-height: 100px;
}

/* 调整表格的外边距 */
.el-table {
  margin: 20px 0;
}

/* 调整对话框的宽度 */
:deep(.el-dialog) {
  min-width: 600px;
}

/* 调整树形控件的高度 */
:deep(.el-tree) {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}

/* 左侧标签页样式调整 */
.user-tabs :deep(.el-tabs--left) {
  height: 600px;
}

.user-tabs :deep(.el-tabs__content) {
  padding-left: 30px;
  height: 100%;
  overflow-y: auto;
}

/* 底部按钮区域样式 */
.border-top {
  padding: 20px 30px;
  background: #f8f9fa;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.avatar {
  width: 200px;
  height: 50px;
  display: block;
}

.user-tabs :deep(.el-tabs__content) {
  padding-left: 20px;
}
</style>