<template>
  <div class="dashboard">
    <!-- 统计卡片 -->
    <div class="stats-cards">
      <el-card class="stat-card">
        <div class="stat-content">
          <div class="stat-icon bg-primary">
            <i class="el-icon-reading"></i>
          </div>
          <div class="stat-info">
            <div class="stat-value">{{ stats.totalCourses }}</div>
            <div class="stat-label">总课程数</div>
          </div>
        </div>
      </el-card>
      
      <el-card class="stat-card">
        <div class="stat-content">
          <div class="stat-icon bg-success">
            <i class="el-icon-user"></i>
          </div>
          <div class="stat-info">
            <div class="stat-value">{{ stats.totalStudents }}</div>
            <div class="stat-label">总学员数</div>
          </div>
        </div>
      </el-card>
      
      <el-card class="stat-card">
        <div class="stat-content">
          <div class="stat-icon bg-warning">
            <i class="el-icon-s-custom"></i>
          </div>
          <div class="stat-info">
            <div class="stat-value">{{ stats.totalTeachers }}</div>
            <div class="stat-label">总讲师数</div>
          </div>
        </div>
      </el-card>
      
      <el-card class="stat-card">
        <div class="stat-content">
          <div class="stat-icon bg-danger">
            <i class="el-icon-money"></i>
          </div>
          <div class="stat-info">
            <div class="stat-value">¥{{ stats.monthlyIncome }}</div>
            <div class="stat-label">本月收入</div>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 最近数据列表 -->
    <div class="recent-data">
      <!-- 最近课程 -->
      <el-card class="recent-card">
        <template #header>
          <div class="card-header">
            <span>最近课程</span>
            <el-button text @click="$router.push('/admin/courses')">查看全部</el-button>
          </div>
        </template>
        <el-table :data="recentCourses" style="width: 100%" size="small">
          <el-table-column prop="name" label="课程名称" min-width="200">
            <template #default="{ row }">
              <div class="course-info">
                <el-image 
                  :src="row.coverUrl" 
                  fit="cover"
                  class="course-cover"
                  :preview-src-list="[row.coverUrl]"
                />
                <span>{{ row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="teacher" label="讲师" width="120" />
          <el-table-column prop="status" label="状态" width="100">
            <template #default="{ row }">
              <el-tag :type="getStatusClass(row.status)" size="small">
                {{ getStatusText(row.status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="120" />
        </el-table>
      </el-card>

      <!-- 最近学员 -->
      <el-card class="recent-card">
        <template #header>
          <div class="card-header">
            <span>最近学员</span>
            <el-button text @click="$router.push('/admin/students')">查看全部</el-button>
          </div>
        </template>
        <el-table :data="recentStudents" style="width: 100%" size="small">
          <el-table-column prop="name" label="用户名" min-width="150" />
          <el-table-column prop="email" label="邮箱" min-width="200" />
          <el-table-column prop="registerDate" label="注册时间" width="120" />
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '@/utils/axios'
import { ElMessage } from 'element-plus'

export default {
  name: 'Dashboard',
  setup() {
    const stats = ref({
      totalCourses: 0,
      totalStudents: 0,
      totalTeachers: 0,
      monthlyIncome: 0
    })

    const recentCourses = ref([])
    const recentStudents = ref([])

    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('/api/admin/stats/dashboard')
        const data = response.data
        
        // 更新统计数据
        stats.value = {
          totalCourses: data.totalCourses || 0,
          totalStudents: data.totalStudents || 0,
          totalTeachers: data.totalTeachers || 0,
          monthlyIncome: 0 // 暂时不显示月收入
        }
        
        // 更新最近课程列表
        recentCourses.value = data.recentCourses.map(course => ({
          id: course.id,
          name: course.name,
          coverUrl: course.coverImageUrl || '/img/default-course.jpg',
          status: course.status,
          createTime: formatDate(course.createTime || course.updateTime)
        }))
        
        // 更新最近学员列表
        recentStudents.value = data.recentStudents.map(student => ({
          id: student.id,
          name: student.name || student.email,
          email: student.email,
          registerDate: formatDate(student.createTime || student.updateTime)
        }))
      } catch (error) {
        console.error('获取仪表盘数据失败:', error)
        ElMessage.error('获取仪表盘数据失败')
      }
    }

    // 格式化日期
    const formatDate = (dateStr) => {
      if (!dateStr) return ''
      const date = new Date(dateStr)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    }

    // 获取课程状态样式
    const getStatusClass = (status) => {
      const map = {
        PUBLISHED: 'success',
        PENDING: 'warning',
        DRAFT: 'info',
        OFFLINE: 'danger'
      }
      return map[status] || 'info'
    }

    // 获取课程状态文本
    const getStatusText = (status) => {
      const map = {
        PUBLISHED: '已发布',
        PENDING: '待审核',
        DRAFT: '草稿',
        OFFLINE: '已下线'
      }
      return map[status] || '未知'
    }

    onMounted(() => {
      fetchDashboardData()
    })

    return {
      stats,
      recentCourses,
      recentStudents,
      getStatusText,
      getStatusClass
    }
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.stats-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  height: 100%;
}

.stat-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.stat-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.stat-info {
  flex: 1;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
}

.stat-label {
  color: #666;
  font-size: 14px;
}

.recent-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.recent-card {
  height: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-cover {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.bg-primary {
  background-color: #409eff;
}

.bg-success {
  background-color: #67c23a;
}

.bg-warning {
  background-color: #e6a23c;
}

.bg-danger {
  background-color: #f56c6c;
}

.bg-secondary {
  background-color: #909399;
}
</style> 